<template lang="pug">
    div(class="sz-HeaderJointInfo")
        div(class="sz-HeaderJointInfo-details")
            div(class="sz-HeaderJointInfo-details-joint") {{$t(`riskTypes.${selectedRisk}`)}}
            div(
                v-if="!isAssessmentEditor",
                class="sz-HeaderJointInfo-details-time") {{ currentTime }}
            div(
                v-if="!isAssessmentEditor",
                class="sz-HeaderJointInfo-details-angle") {{ currentAngle }}
            div(
                v-if="isPosture",
                class="sz-HeaderJointInfo-details-angle") {{ currentMVCRatio }}
        div(v-if="showExtraModuleDetails")
            ModuleExtraJointInfo(:jointData="jointData")
        div(
            v-if="!isAssessmentEditor && !isPosture",
            class="sz-HeaderJointInfo-roys")
            div(class="sz-HeaderJointInfo-roys-title") {{ tableHeaderName }}
            div(class="sz-HeaderJointInfo-roys-details")
                div(
                    v-for="roy in royButtons",
                    :key="roy.name")
                    div(
                        v-if="!useThresholds",
                        v-tooltip="roy.message",
                        class="sz-HeaderJointInfo-roys-roySelect",
                        :class="`sz-HeaderJointInfo-roys-roySelect-${roy.name} sz-HeaderJointInfo-roys-roySelect-${roy.name}-` + (roy.selected ? 'selected': 'unselected')")
                        div(class="sz-HeaderJointInfo-roys-roySelect-negCount") {{ roy.count }}
                    div(
                        v-else,
                        class="sz-HeaderJointInfo-roys-roySelect",
                        :class="`sz-HeaderJointInfo-roys-roySelect-${roy.name} sz-HeaderJointInfo-roys-roySelect-${roy.name}-` + (roy.selected ? 'selected': 'unselected')",
                        v-tooltip="roy.message")
                        div(
                            v-if="hasPositiveThreshold",
                            class="sz-HeaderJointInfo-roys-roySelect-posCount") {{ roy.count.posCount }}
                        div(
                            v-if="hasNegativeThreshold",
                            class="sz-HeaderJointInfo-roys-roySelect-negCount") {{ roy.count.negCount }}
        div(
            v-if="isAssessmentEditor",
            class="sz-HeaderJointInfo-editor")
            div(class="sz-HeaderJointInfo-editor-angle") {{ editorAnglesStartEnd.start }}
            div(class="sz-HeaderJointInfo-editor-angle") {{ editorAnglesStartEnd.end }}
</template>

<script>
import constants from 'helpers/constants'
import { tooltip } from 'directives/tooltip'
import { mapGetters } from 'vuex'
import { bSearch, secondsToMinutes, celsiusToFahrenheit, fahrenheitToCelsius } from 'helpers/utilities'
import motionAssessmentUtils from 'mixins/motionAssessment'
import EventBus from 'src/eventBus'
import ModuleExtraJointInfo from 'components/Assessment/ModuleExtraJointInfo'

export default {
    name: 'HeaderJointInfo',

    directives: {
        tooltip,
    },

    components: {
        ModuleExtraJointInfo,
    },

    mixins: [
        motionAssessmentUtils,
    ],

    props: {
        selectedRisk: {
            required: true,
            type: String,
        },

        assessmentTimezoneOffset: {
            required: true,
            type: Number,
        },

        assessmentTimezone: {
            required: true,
            type: String,
        },

        jointData: {
            required: true,
            type: Object,
        },

        graphData: {
            type: Array,
            required: true,
        },

        assessmentStart: {
            required: true,
            type: Number,
        },

        assessmentEnd: {
            required: true,
            type: Number,
        },
    },

    data () {
        return {
            royTooltipBody: [],
            royTooltipTitle: [],
            currentTime: 0,
            currentAngle: 0,
            currentMVCRatio: 0,
            editorAngles: new Array(2).fill('-'),
        }
    },

    computed: {
        ...mapGetters([
            'selectedModule',
        ]),

        royButtons () {
            let buttons = constants.JOINT_INFO_EVENT_COUNT.map(eventName => {
                return {
                    name: eventName,
                    count: this.getCount(eventName),
                    message: this.tooltipMessage(eventName, this.getCount(eventName)),
                }
            })

            // remove green for motion
            if (this.useThresholds) {
                buttons.shift()
            }
            return buttons
        },

        tableHeaderName () {
            return this.$t(`motionAssessment.eventCountTableHeaders.${constants.JOINT_INFO_EVENT_HEADERS[this.selectedModule]}`)
        },

        isModuleThermal () {
            return this.selectedModule === constants.ASSESSMENT_PAGE.THERMAL
        },

        useThresholds () {
            return this.selectedModule === constants.ASSESSMENT_PAGE.MOTION
        },

        getYellowCount () {
            if (!this.useThresholds) {
                return (this.isModuleThermal)
                ? secondsToMinutes(this.jointData.timeSpent.yellowDuration)
                : secondsToMinutes(this.jointData.yellowDuration)
            }

            return {
                posCount: this.hasPositiveThreshold ? this.jointData[constants.EVENT_TYPES.YELLOW_POSITIVE] : false,
                negCount: this.hasNegativeThreshold ? this.jointData[constants.EVENT_TYPES.YELLOW_NEGATIVE] : false,
            }
        },

        getOrangeCount () {
            if (!this.useThresholds) {
                return (this.isModuleThermal)
                    ? secondsToMinutes(this.jointData.timeSpent.orangeDuration)
                    : secondsToMinutes(this.jointData.orangeDuration)
            }

            return {
                posCount: this.hasPositiveThreshold ? this.jointData[constants.EVENT_TYPES.ORANGE_POSITIVE] : false,
                negCount: this.hasNegativeThreshold ? this.jointData[constants.EVENT_TYPES.ORANGE_NEGATIVE] : false,
            }
        },

        getRedCount () {
            if (!this.useThresholds) {
                return (this.isModuleThermal)
                    ? secondsToMinutes(this.jointData.timeSpent.redDuration)
                    : secondsToMinutes(this.jointData.redDuration)
            }

            return {
                posCount: this.hasPositiveThreshold ? this.jointData[constants.EVENT_TYPES.RED_POSITIVE] : false,
                negCount: this.hasNegativeThreshold ? this.jointData[constants.EVENT_TYPES.RED_NEGATIVE] : false,
            }
        },

        getGreenCount () {
            if (!this.useThresholds) {
                return (this.isModuleThermal)
                    ? secondsToMinutes(this.jointData.timeSpent.greenDuration)
                    : secondsToMinutes(this.jointData.greenDuration)
            }
            return ''
        },


        isAssessmentEditor () {
            return this.selectedModule === constants.ASSESSMENT_PAGE.EDITOR
        },

        isPosture () {
            return this.selectedModule === constants.ASSESSMENT_PAGE.POSTURE
        },

        editorAnglesStartEnd () {
            return {
                start: this.editorAngles[constants.EDITOR.START],
                end: this.editorAngles[constants.EDITOR.END],
            }
        },

        showExtraModuleDetails () {
            return this.isModuleThermal || this.selectedRisk === constants.ASSESSMENT_PAGE.VIBRATION
        },
    },

    watch: {
        selectedModule: function (val) {
            this.updateAngles()
        },

        graphData: function (val) {
            this.updateAngles()
        },
    },

    mounted () {
        this.updateAngles()

        EventBus.$on('UPDATE_ANIMATION_START_TIME', (timestamp) => {
            this.setCurrentTime(timestamp)
        })

        EventBus.$on('UPDATE_ANNOTATION_POSITION', (timestamp) => {
            this.setCurrentTime(timestamp)
        })

        EventBus.$on('UPDATE_CURRENT_ANGLE', (angle) => {
            this.setCurrentAngle(angle)
        })

        EventBus.$on('UPDATE_CURRENT_MVC_RATIO', (ratio) => {
            this.setCurrentMVCRatio(ratio)
        })

        EventBus.$on('UPDATE_CURRENT_ANGLE_EDITOR', (params) => {
            this.setCurrentAngle(params.angle, params.type)
        })

        EventBus.$on('SET_THERMAL_SCALE', (scale) => {
            this.convertTempScale(scale)
        })
    },

    beforeDestroy() {
        EventBus.$off('UPDATE_ANIMATION_START_TIME')

        EventBus.$off('UPDATE_ANNOTATION_POSITION')

        EventBus.$off('UPDATE_CURRENT_ANGLE')

        EventBus.$off('UPDATE_CURRENT_MVC_RATIO')

        EventBus.$off('UPDATE_CURRENT_ANGLE_EDITOR')

        EventBus.$off('SET_THERMAL_SCALE')
    },

    methods: {
        updateAngles () {
            if (this.isAssessmentEditor) {
                this.setCurrentAngle(this.getAngleFromTimestamp(this.assessmentStart),constants.EDITOR.START)
                this.setCurrentAngle(this.getAngleFromTimestamp(this.assessmentEnd),constants.EDITOR.END)
            }
        },

        getAngleFromTimestamp (pointTimestamp) {
            let searchedIndex = bSearch(
                this.graphData, (point) => {
                    return point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP] < pointTimestamp
                }
            )
            return this.graphData[searchedIndex] ? this.graphData[searchedIndex][constants.ASSESSMENT_GRAPH_INDICES.PRIMARY_VALUE] : 0
        },


        setCurrentTime (timestamp) {
            this.currentTime = this.timestampFormatted(this.normalizeTimezoneFromChart(timestamp))
        },

        formatAngle(angle) {
            if (this.selectedModule === constants.RISK_MODULES.THERMAL) {
                return `${angle.val} °${angle.scale}`
            }

            if (this.selectedModule === constants.RISK_MODULES.VIBRATION) {
                return angle
            }

            return `${angle}°`
        },

        setCurrentAngle (angle, type=null) {
            if (type === null) {
                this.currentAngle = this.formatAngle(angle)
            } else {
                this.$set(this.editorAngles, type, this.formatAngle(angle))
            }
        },

        setCurrentMVCRatio(ratio) {
            this.currentMVCRatio = ratio > 0 ? ratio.toFixed(2) : ratio
        },

        convertTempScale(scale) {
            if (scale === constants.THERMAL_TEMPERATURE_TYPES.SCALE.FAHRENHEIT) {
                this.currentAngle = this.formatAngle({
                  val: celsiusToFahrenheit(parseFloat(this.currentAngle)).toFixed(2),
                  scale: constants.TEMPERATURE_SYMBOLS[scale],
                })
            }
            if (scale === constants.THERMAL_TEMPERATURE_TYPES.SCALE.CELSIUS) {
                this.currentAngle = this.formatAngle({
                  val: fahrenheitToCelsius(parseFloat(this.currentAngle)).toFixed(2),
                  scale: constants.TEMPERATURE_SYMBOLS[scale],
                })
            }
        },

        tooltipMessage (name, count) {
            return {
                tooltipBodySettings: {
                    view: {
                        color: 'dark',
                        textAlign: 'center',
                        padding: 'extra-padding',
                    },
                    title: this.tooltipTitle(name),
                    list: this.tooltipBody(count),
                },

                body: {
                    placement: 'bottom-right',
                    offset: this.tooltipOffset(),
                },
                allowHover: true,
            }
        },

        tooltipOffset () {
            return (this.useThresholds) ? '100, -110' :  '0, -80'
        },

        tooltipTitle (name) {
            if (this.useThresholds) {
                if (name !== constants.EVENT_TYPES.GREEN) {
                    return `${this.$t(`motionAssessment.eventTypes.${name}`)}
                        ${this.$t(`motionAssessment.eventCountTableHeaders.eventCount`)}`
                }
            } else {
                return this.$t(`${this.selectedModule}Assessment.headerJointInfoMessage.${name}`)
            }
        },

        tooltipBody (count) {
            if (!this.useThresholds) return undefined

            let posCount = count.posCount
            let negCount = count.negCount
            let negThreshold = this.negativeThreshold
            let posThreshold = this.positiveThreshold

            let royTooltipBody = []


            if (posCount || posCount === 0) {
                royTooltipBody.push(
                `${posCount} ${this.$t(`motionAssessment.detailedEventCountHeaders.above`)} ${posThreshold}°`)
            }
            if (negCount || negCount === 0) {
                this.royTooltipBody.push(
                `${negCount} ${this.$t(`motionAssessment.detailedEventCountHeaders.below`)} ${negThreshold}°`)
            }
            return royTooltipBody
        },

        getCount (color) {
            switch (color) {
                case constants.EVENT_TYPES.RED: return this.getRedCount
                case constants.EVENT_TYPES.ORANGE: return this.getOrangeCount
                case constants.EVENT_TYPES.YELLOW: return this.getYellowCount
                case constants.EVENT_TYPES.GREEN: return this.getGreenCount
                default: break
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    @import "~design";

    .sz-HeaderJointInfo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & > * {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-editor {
            @extend %font-heading;
            font-size: 0.75rem;
            margin: 0 auto;

            &-angle {
                background-color: $color-lifebooster-light-green;
                padding: 0.5rem 1.5rem;
                margin: 0.5rem;
                max-width: 4rem;
            }
        }

        &-details {
            height: 2.375rem;
            text-align: center;
            padding: 0.625rem 0.5625rem;
            @extend %font-heading;
            font-size: 12px;
            margin-top: 0.2rem;

            &-joint {
                background-color: $color-lifebooster-light-green;
                min-width: 7.5rem;
                padding: 1.2rem 0.5625rem;
            }

            &-time,
            &-angle {
                background-color: $color-lifebooster-dark-green;
                width: 4.9rem;
                padding: 1.2rem 0.1425rem;
            }
        }

        &-roys {
            flex-direction: column;
            &-title {
                margin-bottom: 0.25rem;
                font-size: 12px;
                text-transform: uppercase;
            }

            &-details {
                display: flex;
            }

            &-roySelect {
                flex-direction: column;
                @extend %font-content-unselectable;
                text-transform: uppercase;
                font-size: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 0.1875rem;
                border-radius: 2px;
                width: 2.6rem;
                height: 2.375rem;

                &-redRiskCount {
                    border: 2px solid $color-risk-red;
                    margin-right: 0.625rem;
                    &-selected {
                        border-radius: 2px;
                        background-color: $color-risk-red;
                        color: $color-body-bg;
                    }
                }

                &-orangeRiskCount {
                    border: 2px solid $color-risk-orange;
                    margin-right: 0.625rem;
                    &-selected {
                        border-radius: 2px;
                        background-color: $color-risk-orange;
                        color: $color-body-bg;
                    }
                }

                &-yellowRiskCount {
                    border: 2px solid $color-risk-yellow;
                    margin-right: 0.625rem;
                    &-selected {
                        border-radius: 2px;
                        background-color: $color-risk-yellow;
                        color: $color-body-bg;
                    }
                }

                &-greenRiskCount {
                    border: 2px solid $color-risk-green;
                    margin-right: 0.625rem;
                    &-selected {
                        border-radius: 2px;
                        background-color: $color-risk-green;
                        color: $color-body-bg;
                    }
                }
            }
        }
    }
</style>
