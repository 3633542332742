<template lang="pug">
    div(class="sz-OffBodyPeriodTable")
        div(class="sz-OffBodyPeriodTable-optionsBar")
            div(class="sz-OffBodyPeriodTable-optionsBar-edit") {{ $t('motionAssessment.editor.repair.offBodyPeriodTable.title') }}
        div(class="sz-OffBodyPeriodTable-wrapper")
            table(class="sz-OffBodyPeriodTable-table sticky-header")
                thead
                    tr(class="sz-OffBodyPeriodTable-headers")
                        th(
                            v-for="header of headers",
                            @click="setCurrentSort(header)",
                            :class="`sz-OffBodyPeriodTable-headers-header-${header}`")
                            div(class="sz-OffBodyPeriodTable-headers-header-content")
                                span {{ $t(`motionAssessment.editor.repair.offBodyPeriodTable.headers.${header}`) }}
                tbody
                    tr(
                        v-if="!isPeriodListPopulated",
                        class="sz-OffBodyPeriodTable-empty")
                        td(
                            :colspan="headers.length",
                            class="sz-OffBodyPeriodTable-empty-cell")
                            span(class="sz-OffBodyPeriodTable-empty-cell-loading") {{ $t(`motionAssessment.editor.repair.offBodyPeriodTable.empty`) }}
                    tr(
                        v-if="isPeriodListPopulated",
                        v-for="period of periods",
                        :key="period.idx",
                        class="sz-OffBodyPeriodTable-row")
                        td(
                            v-for="header of headers",
                            :key="header",
                            :id="`sz-OffBodyPeriodTable-row-item-${header}-${period.idx}`"
                            :class="`sz-OffBodyPeriodTable-row-item ${header}`") {{ period[header] ?  period[header] : null }}
                        td(
                            @click.stop="removePeriod(period)",
                            class="sz-OffBodyPeriodTable-row-clear")
                            SvgIcon(
                                icon="close",
                                width=12,
                                height=12)
</template>

<script>
    import constants from 'helpers/constants'
    import EventBus from 'src/eventBus'
    import SvgIcon from 'components/Shared/SvgIcon'

    export default {
        name: 'OffBodyPeriodTable',

        components: {
            SvgIcon,
        },

        props: {
            periods: {
                type: Array,
                required: true,
            },
        },

        computed: {
            headers() {
                return constants.OFF_BODY_PERIOD_TABLE_HEADERS
            },

            isPeriodListPopulated() {
                return this.periods.length > 0
            },
        },

        methods: {
            removePeriod(period) {
                EventBus.$emit('PERIOD_REMOVED', period)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";

    table, th, tr, td {
        border: none;
    }

    th {
        cursor: default;
        color: $color-table-row-hover-text;
    }

    td, th {
        padding: 0.5rem;
        font-size: 13px;
    }

    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }

    tbody {
        display: table-row-group;
        overflow: auto;
    }

    td {
        overflow: hidden;
        text-overflow: ellipsis;

    }

    table {
        display: table;
        table-layout: fixed;
        border-collapse: collapse;
        font-size: 11px;
        flex-grow: 1;
        background-color: $color-box-background;
        color: $color-unselected-text;

        &.sticky-header th {
            position: sticky;
            top: 0;
            background-color: $color-box-background;
        }
    }

    .sz-OffBodyPeriodTable {
        display: flex;
        flex-direction: column;
        width: 100%;

        &-optionsBar {
            display: flex;
            background-color: $color-header-bar-backround;
            align-items: center;
            padding: 0.5rem 0.5rem 0.5rem 0.75rem;
            @extend %font-heading;

            &-edit {
                display: flex;
                align-items: center;
            }
        }

        &-wrapper {
            width: inherit;
            height: 100%;
            overflow: scroll;
            @include scrollbar-widget;
            background-color: $color-box-background;
        }

        &-headers {
            &-header {
                &-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: left;
                }

                &-position {
                    padding-left: 1rem;
                    width: 4rem;
                }

                &-startTime {
                    width: 5.5rem;
                }

                &-endTime {
                    width: 5.5rem;
                }

                &-flipped {
                    width: 2rem;
                }
            }
        }

        &-row {
            &-item {
                text-align: left;

                &.position {
                    padding-left: 1rem;
                    width: 4rem;
                }

                &.startTime {
                    width: 5.5rem;
                }

                &.endTime {
                    width: 5.5rem;
                }

                &.flipped {
                    width: 2rem;
                }
            }

            &-clear {
                &:hover {
                    background: $color-white;
                    color: $color-black;
                }

                cursor: pointer;
                color: $color-risk-red;
                font-size: 12px;
                padding: 0.6rem 0.3rem 0.25rem 0.3rem;
                max-width: 1rem;
                white-space: nowrap;
                border-radius: 3px;
            }
        }
    }
</style>
