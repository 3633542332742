import constants from 'helpers/constants'

export const generalSettings = {
    chart: {
        type: 'stock',
        background: constants.COLORS.ASSESSMENT.ASSESSMENT_PANEL,
        width: '100%',
        height: '100%',
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingRight: '5px',
        paddingLeft: '50px',
        tooltip: {
            enabled: false,
        },
        scroller: {
            enabled: true,
            xAxis: {
                enabled: false,
            },
            selectedFill: constants.COLORS.ASSESSMENT.SCROLLER,
            fill: constants.COLORS.ASSESSMENT.ASSESSMENT_PANEL,
            outlineStroke: constants.COLORS.ASSESSMENT.ASSESSMENT_PANEL,
        },
        splitters: {
            enabled: false,
        },
        crosshair: {
            enabled: true,
            yStroke: null,
            xLabel: {
                format: `{%tickValue}{dateTimeFormat:hh:mm:ss a}`,
            },
            interactivity: {
                hoverMode: 'by-x',
            },
        },
        scales: [
            {
                type: 'dateTime',
            },
            {
                type: 'linear',
            },
        ],
        yScale: {
            type: 'linear',
        },
        xScale: {
            type: 'linear',
            mode: 'continuous',
            minimum: '',
            maximum: '',
            ticks: {
                enabled: true,
                length: 6,
            },
        },
        textMarker: {
            fontWeight: 'bold',
            fontColor: constants.COLORS.WHITE,
            fontFamily: 'Source Sans Pro',
            xMarker: {
                rotation: 0,
                fontSize: "10px",
                hAlign: "center",
                anchor: "center",
                offsetY: "143",
                background: {
                    enabled: true,
                    fill: constants.COLORS.ASSESSMENT.ASSESSMENT_PANEL,
                    opacity: 1,
                },
            },
            zoomMarker: {
                rotation: -90,
                offsetX: 39,
                offsetY: 160,
                anchor: "right-center",
                align: "left",
                label: constants.MOTION_ASSESSMENT_GRAPH_LABELS.ZOOM,
            },

        },
        annotation: {
            allowEdit: false,
            strokeColor: constants.COLORS.ASSESSMENT.ANNOTATION,
            strokeThickness: 2,
            strokeThicknessBold: 3.5,
            selectedAnnotationColor: '#32CD32',
            rotationAnnotationColor: '#FFE814',
        },
        dataArea: {
            enabled: true,
            fill: constants.COLORS.ASSESSMENT.CHART_BACKGROUND,
        },
        plots: {
            anglePlot: {
                height: '70%',
                postureHeight: '50%',
                title: {
                    enabled: true,
                    text: '\n',
                    fontSize: 24,
                },
                legend: {
                    enabled: false,
                    itemsFormat: "{%seriesName}",
                    titleFormat: "Select Event Type:",
                },
                xScale: {
                    fill: constants.COLORS.ASSESSMENT.TICKS,
                },
                xAxes: [{
                    labels: {
                        fontColor: constants.COLORS.WHITE,
                        format: '{%value}{dateTimeFormat:hh:mm:ss}',
                    },
                }],
                yAxes: [{
                    enabled: true,
                    labels: {
                        fontColor: constants.COLORS.WHITE,
                    },
                    title: {
                        label: constants.MOTION_ASSESSMENT_GRAPH_LABELS.JOINT_ANGLE,
                        fontSize: 10,
                        fontWeight: '600',
                        fontFamily: 'Source Sans Pro',
                        fontColor: constants.COLORS.ASSESSMENT.YAXIS_COLOR,
                    },
                }],
                series: {
                    color: constants.COLORS.ASSESSMENT.ANGLE_LINE,
                    thickness: 1.5,
                },
            },
            riskPlot: {
                height: '18%',
                crosshair: {
                    enabled: false,
                },
                yAxis: {
                    labels: {
                        enabled: false,
                    },
                    ticks: {
                        enabled: false,
                    },
                    title: constants.MOTION_ASSESSMENT_GRAPH_LABELS.RISKY_EVENTS,
                },
                xAxis: {
                    enabled: false,
                },
                yScale: {
                    minimum: 0,
                    maximum: 10,
                },
            },
            ratioPlot: {
                height: '23%',
                crosshair: {
                    enabled: false,
                },
                yAxis: {
                    labels: {
                        enabled: true,
                    },
                    ticks: {
                        enabled: false,
                    },
                    title: constants.POSTURE_ASSESSMENT_GRAPH_LABELS.MVC_RATIO,
                },
                xAxis: {
                    enabled: false,
                },
                yScale: {
                    minimum: 0,
                },
            },
        },
        listen: {
            type: 'hover',

        },
        series: {
            bodyAngle: {
                name: 'Body Angle',
            },
            yellowRiskEvent: {
                name: 'Yellow Events',
            },
            orangeRiskEvent: {
                name: 'Orange Events',
            },
            redRiskEvent: {
                name: 'Red Events',
            },
            positiveThreshold: {
                name: 'Positive Threshold',
            },
            negativeThreshold: {
                name: 'Negative Threshold',
            },
        },
    },
}

export const riskSettings = {
    thickness: 7.5,
    greyRiskEvent: {
        normal: {
            fill: {
                color: '#444444',
            },
            stroke: {
                color: '#444444',
            },
        },
        zIndex: 14,
    },
    greenRiskEvent: {
        normal: {
            fill: {
                color: constants.RISK_COLOR_MAP[constants.RISK_SCORES.GREEN],
            },
            stroke: {
                color: constants.RISK_COLOR_MAP[constants.RISK_SCORES.GREEN],
            },
        },
        zIndex: 13,
    },
    yellowRiskEvent: {
        normal: {
            fill: {
                color: constants.RISK_COLOR_MAP[constants.RISK_SCORES.YELLOW],
            },
            stroke: {
                color: constants.RISK_COLOR_MAP[constants.RISK_SCORES.YELLOW],
            },
        },
        zIndex: 12,
    },
    orangeRiskEvent: {
        normal: {
            fill: {
                color: constants.RISK_COLOR_MAP[constants.RISK_SCORES.ORANGE],
            },
            stroke: {
                color: constants.RISK_COLOR_MAP[constants.RISK_SCORES.ORANGE],
            },
        },
        zIndex: 11,
    },
    redRiskEvent: {
        normal: {
            fill: {
                color: constants.RISK_COLOR_MAP[constants.RISK_SCORES.RED],
            },
            stroke: {
                color: constants.RISK_COLOR_MAP[constants.RISK_SCORES.RED],
            },
        },
        zIndex: 10,
    },
}

export const thresholdSettings = {
    connectMissingPoints: true,
    markers: {
        enabled: false,
    },
    scaleRangeMode: 'consider',
    hovered: {
        markers: {
            enabled: false,
        },
    },
    selected: {
        markers: {
            enabled: false,
        },
    },
    normal: {
        stroke: {
            color: constants.RISK_COLOR_MAP[constants.RISK_SCORES.YELLOW],
            thickness: 2,
        },
    },
    thermalColors: {
        red: constants.RISK_COLOR_MAP[constants.RISK_SCORES.RED],
        yellow: constants.RISK_COLOR_MAP[constants.RISK_SCORES.YELLOW],
        orange: constants.RISK_COLOR_MAP[constants.RISK_SCORES.ORANGE],
    },
    thermalThickness: {
        thickness: 1.5,
    },
}

export const vibrationSettings = {
    plot: {
        yAxis: {
            title: constants.VIBRATION_ASSESSMENT_GRAPH_LABEL,
        },
        thickness: 3,
    },
}

export const thermalSettings = {
    plots: {
        tempPlot: {
            yAxis: {
                titles: {
                    fahrenheit: constants.THERMAL_ASSESSMENT_GRAPH_LABELS.TEMPERATURE_F,
                    celsius: constants.THERMAL_ASSESSMENT_GRAPH_LABELS.TEMPERATURE_C,
                },
            },
            thickness: 3,
        },
        riskPlot: {
            yAxis: {
                title: constants.THERMAL_ASSESSMENT_GRAPH_LABELS.RISK_DISTRIBUTION,
            },
        },
    },
}

export function assessmentSettings(colour) {
    return {
        seriesType: 'line',
        connectMissingPoints: true,
        data: [],
        markers: {
            enabled: false,
        },
        hovered: {
            markers: {
                enabled: false,
            },
        },
        selected: {
            markers: {
                enabled: false,
            },
        },
        normal: {
            stroke: {
                color: colour,
                thickness: 2,
            },
        },
    }
}
