<template lang="pug">
   div(class="sz-EditorOptions")
       div(class="sz-EditorOptions-buttons")
           div(
               @click="openEdit",
               class="sz-EditorOptions-buttons-button",
               :class="`sz-EditorOptions-buttons-button` + (isEdit ? '-selected' : 'notSelected')")
               SvgIcon(
                   icon="edit",
                   width="1rem",
                   height="1rem",
                   class="sz-EditorOptions-buttons-button-icon")
               span(class="sz-EditorOptions-buttons-button-text") {{ $t('motionAssessment.editor.options.edit') }}
           div(
               @click="openRepair",
               class="sz-EditorOptions-buttons-button",
               :class="`sz-EditorOptions-buttons-button` + (isRepair ? '-selected' : 'notSelected')")
               SvgIcon(
                   icon="repair",
                   width="1rem",
                   height="1rem",
                   class="sz-EditorOptions-buttons-button-icon")
               span(class="sz-EditorOptions-buttons-button-text") {{ $t('motionAssessment.editor.options.repair') }}
</template>

<script>
    import SvgIcon from 'components/Shared/SvgIcon'
    import constants from 'helpers/constants'

    export default {
        name: 'EditorOptions',

        components: {
            SvgIcon,
        },

        data () {
            return {
                selectedOption: constants.EDITOR_OPTIONS.EDIT,
            }
        },

        computed: {
            isEdit() {
                return this.selectedOption === constants.EDITOR_OPTIONS.EDIT
            },

            isRepair() {
                return this.selectedOption === constants.EDITOR_OPTIONS.REPAIR
            },
        },

        beforeDestroy() {
            this.$emit('updateEditorOption', '')
        },

        methods: {
            openEdit() {
                this.selectedOption = constants.EDITOR_OPTIONS.EDIT
                this.updateEditorOption()
            },

            openRepair() {
                this.selectedOption = constants.EDITOR_OPTIONS.REPAIR
                this.updateEditorOption()
            },

            updateEditorOption() {
                this.$emit('updateEditorOption', this.selectedOption)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";

    .sz-EditorOptions {
        display: flex;
        flex-direction: column;
        padding: 0 3rem;
        color: $color-text;

        &-buttons {
            border-radius: 3px;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            &-divider {
                margin-left: 1.8rem;
                margin-right: 0.5rem;
                border-left: 1.5px solid grey;
                opacity: 0.5;
                height: 2rem;
            }

            &-button {
                @extend %button-layout;
                @extend %font-heading;
                padding: 0.5rem 1rem;
                font-size: 12px;
                background-color: transparent;
                border: none;
                color: white;
                display: flex;
                align-items: center;

                &-icon {
                    margin-top: 0.2rem;
                    fill: white;

                    &-download {
                        transform: rotate(180deg);
                    }
                }

                &-text {
                    margin-left: 0.5rem;
                }

                &:hover {
                    background-color: $color-lifebooster-light-green;
                }

                &-selected {
                    background-color: $color-lifebooster-light-green;
                }
            }
        }
    }
</style>
