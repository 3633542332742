<template lang="pug">
    div(class="sz-EditorAssessmentRepair")
        div(class="sz-EditorAssessmentRepair-definition")
            div(class="sz-EditorAssessmentRepair-definition-title") {{ $t('motionAssessment.editor.repair.define') }}
            div(class="sz-EditorAssessmentRepair-definition-sections")
                div(class="sz-EditorAssessmentRepair-definition-sections-left")
                    SensorSelector(
                        @updateSelectedSensor="updateSelectedSensor",
                        class="sz-EditorAssessmentRepair-definition-sections-left-item")
                    SensorFlipOrShiftSelector(
                        :selectedSensor="selectedSensor",
                        @updateIsFlippedOrShifted="updateIsFlippedOrShifted",
                        class="sz-EditorAssessmentRepair-definition-sections-left-item")
                div(class="sz-EditorAssessmentRepair-definition-sections-right")
                    div(class="sz-EditorAssessmentRepair-definition-sections-right-title") {{ $t('motionAssessment.editor.repair.selectTime') }}
                        div(v-tooltip="timeFrameTooltip")
                            SvgIcon(
                                icon="info",
                                width="1rem",
                                height="1rem",
                                class="sz-EditorAssessmentRepair-definition-sections-right-title-icon")
                    div(class="sz-EditorAssessmentRepair-definition-sections-right-graph")
                            OffBodyTimeSelector(
                                v-if="!loadingGraph",
                                :graph="parsedGraphData",
                                :loadingGraph="loadingGraph",
                                :assessmentTimezoneOffset="assessmentTimezoneOffset",
                                :assessmentStart="localStartTime",
                                :assessmentEnd="localEndTime",
                                :jointKeys="jointKeys",
                                :currentCustomer="currentCustomer",
                                :currentCompany="currentCompany",
                                :workerId="workerId",
                                :captureIdx="captureIdx",
                                :metadataID="assessmentIdx",
                                :assessmentIdx="assessmentIdx",
                                :assessmentTimezone="assessmentTimezone",
                                :selectedRiskType="riskType",
                                :selectedPeriods="selectedSensorOffBodyPeriods",
                                :selectedRotations="selectedSensorRotations",
                                @updateStartTimestamp="updateStartTimestamp",
                                @updateEndTimestamp="updateEndTimestamp")
                            LoadingSpinner(
                                v-else,
                                color="light")
                    div(class="sz-EditorAssessmentRepair-definition-sections-right-times")
                        div(class="sz-EditorAssessmentRepair-definition-sections-right-times-header") {{ $t('motionAssessment.editor.repair.periodStart') }}
                        div(class="sz-EditorAssessmentRepair-definition-sections-right-times-time") {{ selectedStartTimestamp }}
                        div(class="sz-EditorAssessmentRepair-definition-sections-right-times-header") {{ $t('motionAssessment.editor.repair.periodEnd') }}
                        div(class="sz-EditorAssessmentRepair-definition-sections-right-times-time") {{ selectedEndTimestamp }}
            div(class="sz-EditorAssessmentRepair-definition-footer")
                div(class="sz-EditorAssessmentRepair-definition-footer-buttons")
                    div(
                        @click.stop="resetSettings",
                        class="sz-EditorAssessmentRepair-definition-footer-buttons-reset") {{ $t('motionAssessment.editor.repair.reset') }}
                    div(
                        @click.stop="addPeriod",
                        class="sz-EditorAssessmentRepair-definition-footer-buttons-save",
                        :class="`sz-EditorAssessmentRepair-definition-footer-buttons-save-` + (isSettingsValid ? 'valid' : 'invalid')") {{ $t('motionAssessment.editor.repair.add') }}
        div(class="sz-EditorAssessmentRepair-rightPanel")
            div(class="sz-EditorAssessmentRepair-rightPanel-assessmentID")
                CopyableText(
                  :text="assessmentID")
            div(class="sz-EditorAssessmentRepair-rightPanel-thxToggle")
                FormSectionToggle(
                        :title="$t(`motionAssessment.editor.repair.thxOverride`)",
                        :defaultChecked="defaultThxOverride",
                        :options="thxOverrideOptions",
                        @changed="updateThxOverride")
            OffBodyPeriodTable(
              :periods="periods",
              class="sz-EditorAssessmentRepair-rightPanel-table")
            div(class="sz-EditorAssessmentRepair-rightPanel-buttons")
                div(
                    @click.stop="saveChanges",
                    class="sz-EditorAssessmentRepair-rightPanel-buttons-save") {{ $t('motionAssessment.editor.repair.repairAssessment') }}
</template>

<script>
import SensorSelector from 'components/Assessment/Editor/EditorAssessmentRepair/SensorSelector'
import SensorFlipOrShiftSelector from 'components/Assessment/Editor/EditorAssessmentRepair/SensorFlipOrShiftSelector'
import OffBodyTimeSelector from 'components/Assessment/Editor/EditorAssessmentRepair/OffBodyTimeSelector'
import OffBodyPeriodTable from 'components/Assessment/Editor/EditorAssessmentRepair/OffBodyPeriodTable'
import LoadingSpinner from 'components/Shared/LoadingSpinner'
import SvgIcon from 'components/Shared/SvgIcon'
import CopyableText from 'components/Shared/CopyableText'
import FormSectionToggle from 'components/Shared/FormSectionToggle'

import { tooltip } from 'directives/tooltip'
import motionAssessmentUtils from 'mixins/motionAssessment'
import constants from 'helpers/constants'
import EventBus from 'src/eventBus'
import { mapGetters } from 'vuex'
import constructModalSettings from 'mixins/modalSettings'
import formValidator from 'mixins/formValidator'

export default {
  name: 'EditorAssessmentRepair',

  components: {
    SensorSelector,
    SensorFlipOrShiftSelector,
    OffBodyTimeSelector,
    LoadingSpinner,
    SvgIcon,
    OffBodyPeriodTable,
    CopyableText,
    FormSectionToggle,
  },

  directives: {
    tooltip,
  },

  mixins: [constructModalSettings, motionAssessmentUtils, formValidator],

  props: {
    assessmentTimezoneOffset: {
      required: true,
      type: Number,
    },

    assessmentTimezone: {
      required: true,
      type: String,
    },

    jointKeys: {
      required: true,
      type: Object,
    },

    assessmentStart: {
      required: true,
      type: Number,
    },

    assessmentEnd: {
      required: true,
      type: Number,
    },

    currentCustomer: {
      required: true,
      type: String,
    },

    currentCompany: {
      required: true,
      type: String,
    },

    workerId: {
      required: true,
      type: String,
    },

    captureIdx: {
      required: true,
      type: String,
    },

    assessmentIdx: {
      required: true,
      type: String,
    },

    defaultNeutralizationPeriods: {
      required: false,
      type: Array,
      default: () => [],
    },

    defaultSensorRotations: {
      required: false,
      type: Array,
      default: () => [],
    },

    defaultSensorShifts: {
      required: false,
      type: Array,
      default: () => [],
    },

    defaultThxOverride: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      loadingGraph: false,
      selectedSensor: constants.DEVICE_STATUS_CHARACTERISTICS.RFA,
      isFlippedOrShifted: null,
      startTimestamp: null,
      endTimestamp: null,
      graphData: [],
      periods: [],
      rotations: [],
      shifts: [],
      shouldOverrideThx: false,
    }
  },

  computed: {
    ...mapGetters(['selectedModule', 'selectedAssessment']),

    riskType() {
      return constants.SENSOR_RISK_TYPE_MAP[this.selectedSensor]
    },

    parsedGraphData() {
      let graph = this.graphData.map((point) => {
        const localTimestamp = this.convertPosixToLocalTime(
          this.assessmentTimezoneOffset,
          point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP]
        )
        point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP] = localTimestamp
        return point
      })

      return graph
    },

    localStartTime() {
      return this.convertPosixToLocalTime(this.assessmentTimezoneOffset, this.assessmentStart)
    },

    localEndTime() {
      return this.convertPosixToLocalTime(this.assessmentTimezoneOffset, this.assessmentEnd)
    },

    selectedStartTimestamp() {
      return this.startTimestamp
        ? this.timestampFormatted(this.normalizeTimezoneFromChart(this.startTimestamp))
        : ''
    },

    selectedEndTimestamp() {
      return this.endTimestamp
        ? this.timestampFormatted(this.normalizeTimezoneFromChart(this.endTimestamp))
        : ''
    },

    isSettingsValid() {
      return !!(
        this.selectedSensor &&
        this.isFlippedOrShifted !== null &&
        this.startTimestamp &&
        this.endTimestamp
      )
    },

    timeFrameTooltip() {
      return {
        tooltipBodySettings: {
          view: {
            color: 'dark',
            textAlign: 'left',
            padding: 'extra-padding',
            width: 'parameters-width',
          },
          title: this.$t('motionAssessment.editor.repair.selectTimeTooltip'),
        },

        body: {
          placement: 'bottom-left',
          offset: '120, 5',
        },
        allowHover: true,
      }
    },

    selectedSensorOffBodyPeriods() {
      return this.periods.filter((period) => period.position === this.selectedSensor)
    },

    selectedSensorRotations() {
      return this.rotations.filter(
        (rotation) =>
          rotation.position === this.selectedSensor &&
          rotation.angle !== constants.DEFAULT_SENSOR_ROTATIONS.NEUTRAL
      )
    },

    assessmentID() {
      if (!this.assessmentIdx) return ''

      return `${constants.MODULE_ASSESSMENT_ID_MAP[this.selectedModule]}-${this.assessmentIdx}`
    },

    newNeutralizationPeriods() {
      return this.periods.map((period) => {
        return [
          this.normalizeTimezoneFromChart(period.start),
          this.normalizeTimezoneFromChart(period.end),
        ]
      })
    },

    newRotations() {
      return this.rotations.map((rotation) => {
        return [
          rotation.position,
          this.normalizeTimezoneFromChart(rotation.timestamp),
          rotation.angle,
        ]
      })
    },

    newShifts() {
      return this.shifts.map((shift) => {
        return [shift.position, this.normalizeTimezoneFromChart(shift.timestamp)]
      })
    },

    thxOverrideOptions() {
      return [this.$t(`motionAssessment.editor.no`), this.$t(`motionAssessment.editor.yes`)]
    },
  },

  watch: {
    selectedSensor: function () {
      this.startTimestamp = null
      this.endTimestamp = null
    },
  },

  mounted() {
    this.getGraphData()

    // Highlights the graph with repaired periods that already exist on the assessment
    this.applyDefaultRotations(this.defaultSensorRotations)
    this.applyDefaultShifts(this.defaultSensorShifts)
    this.applyDefaultNeutralizations(this.defaultNeutralizationPeriods)

    // TODO: An edge case that is not handled is when the end timestamps are exactly the same
    // for two overlapping periods on the same sensor, this will be remedied in a future feature request
    // where periods CANNOT overlap
    EventBus.$on('PERIOD_REMOVED', (removedPeriod) => {
      this.periods = this.periods.filter((period) => period.idx !== removedPeriod.idx)

      // A neutralization period may be accompanied by a subsequent repair, this needs to be removed as well
      switch (removedPeriod.repairOption) {
        case constants.REPAIR_OPTIONS.ROTATION:
          this.rotations = this.rotations.filter(
            (rotation) => rotation.timestamp !== removedPeriod.end
          )
          break
        case constants.REPAIR_OPTIONS.SHIFT:
          this.shifts = this.shifts.filter((shift) => shift.timestamp !== removedPeriod.end)
          break
        default:
          console.log(`Unexpected default case: ${removedPeriod.repairOption}`)
          break
      }
    })
  },

  beforeDestroy() {
    EventBus.$emit('CLEAR_RISK_TYPES')
    EventBus.$off('PERIOD_REMOVED')
  },

  methods: {
    applyDefaultRotations(defaultRotations) {
      defaultRotations.map((rotation) => {
        this.rotations.push({
          idx: this.rotations.length,
          position: rotation[0],
          timestamp: this.normalizeTimezoneForChart(rotation[1]),
          angle: rotation[2],
        })
      })
    },

    applyDefaultShifts(defaultShifts) {
      defaultShifts.map((shift) => {
        this.shifts.push({
          idx: this.shifts.length,
          position: shift[0],
          timestamp: this.normalizeTimezoneForChart(shift[1]),
        })
      })
    },

    applyDefaultNeutralizations(defaultNeutralizations) {
      defaultNeutralizations.map((neutralize) => {
        var flipped = constants.BOOLEAN_MAPPER.false.toUpperCase()
        var position = constants.DEVICE_STATUS_CHARACTERISTICS.RFA
        var repairOption = undefined

        // A neutralization period can be followed by a rotation or a shift
        // In order to show previously applied repairs in the table and on the graph,
        // we need associate a rotation or a shift with these periods
        let pairedRotation = this.rotations.find(
          (rotation) => rotation.timestamp === this.normalizeTimezoneForChart(neutralize[1])
        )
        let pairedShift = this.shifts.find(
          (shift) => shift.timestamp === this.normalizeTimezoneForChart(neutralize[1])
        )
        if (pairedRotation) {
          flipped =
            pairedRotation.angle === constants.DEFAULT_SENSOR_ROTATIONS.FLIP
              ? constants.BOOLEAN_MAPPER.true.toUpperCase()
              : constants.BOOLEAN_MAPPER.false.toUpperCase()
          position = pairedRotation.position
          repairOption = constants.REPAIR_OPTIONS.ROTATION
        } else if (pairedShift) {
          flipped = constants.BOOLEAN_MAPPER.true.toUpperCase()
          position = constants.DEVICE_STATUS_CHARACTERISTICS.PEL
          repairOption = constants.REPAIR_OPTIONS.SHIFT
        }

        this.periods.push({
          idx: this.periods.length,
          position: position,
          start: this.normalizeTimezoneForChart(neutralize[0]),
          end: this.normalizeTimezoneForChart(neutralize[1]),
          startTime: this.timestampFormatted(neutralize[0]),
          endTime: this.timestampFormatted(neutralize[1]),
          flipped: flipped,
          repairOption: repairOption,
        })
      })
    },

    updateSelectedSensor(sensor) {
      this.selectedSensor = sensor
      this.getGraphData()
    },

    updateIsFlippedOrShifted(isFlippedOrShifted) {
      this.isFlippedOrShifted = isFlippedOrShifted
    },

    updateStartTimestamp(timestamp) {
      this.startTimestamp = timestamp
    },

    updateEndTimestamp(timestamp) {
      this.endTimestamp = timestamp
    },

    async getGraphData() {
      this.loadingGraph = true
      this.graphData = []

      try {
        this.graphData = await this.$store.dispatch('getAssessmentGraphData', {
          currentCustomer: this.currentCustomer,
          currentCompany: this.currentCompany,
          workerId: this.workerId,
          captureIdx: this.captureIdx,
          metadataID: this.assessmentIdx,
          assessmentIdx: this.assessmentIdx,
          motion: this.riskType,
          cursorTime: this.assessmentStart,
          startTime: this.assessmentStart,
          endTime: this.assessmentEnd,
        })
      } catch (err) {
        console.log(err)
      }

      this.loadingGraph = false
    },

    updateThxOverride(setting) {
      this.shouldOverrideThx = setting
    },

    addPeriod() {
      if (this.isSettingsValid) {
        var repairOption = undefined

        switch (this.selectedSensor) {
          case constants.DEVICE_STATUS_CHARACTERISTICS.PEL:
            // The pelvis sensor cannot be rotated. We only support shifting for the pelvis sensor
            if (this.isFlippedOrShifted) {
              this.shifts.push({
                idx: this.shifts.length,
                position: this.selectedSensor,
                timestamp: this.endTimestamp,
              })

              repairOption = constants.REPAIR_OPTIONS.SHIFT
            }

            break
          default:
            // Add a rotation event after every neutralization period that is not applied to the pelvis
            // and specify if there was a flip or not
            // If there is a rotation, this function is memoryless so we need to specifically set the rotation
            // to either 0 or 180 every time
            // If a flip has occurred, the angle is set to 180
            // If a flip has not occurred, the angle is set to 0
            this.rotations.push({
              idx: this.rotations.length,
              position: this.selectedSensor,
              timestamp: this.endTimestamp,
              angle:
                this.isFlippedOrShifted === true
                  ? constants.DEFAULT_SENSOR_ROTATIONS.FLIP
                  : constants.DEFAULT_SENSOR_ROTATIONS.NEUTRAL,
            })

            repairOption = constants.REPAIR_OPTIONS.ROTATION

            break
        }

        this.periods.push({
          idx: this.periods.length,
          position: this.selectedSensor,
          start: this.startTimestamp,
          end: this.endTimestamp,
          startTime: this.selectedStartTimestamp,
          endTime: this.selectedEndTimestamp,
          flipped: constants.BOOLEAN_MAPPER[this.isFlippedOrShifted].toUpperCase(),
          repairOption: repairOption,
        })

        this.resetSettings()
      }
    },

    resetSettings() {
      this.isFlippedOrShifted = null
      EventBus.$emit('RESET_ORIENTATION')

      this.startTimestamp = null
      this.endTimestamp = null
    },

    async saveChanges() {
      let isProcessing = await this.isAssessmentProcessing()
      if (isProcessing) {
        this.failModal(
          this.$t(`motionAssessment.editor.reassessmentInProgress`),
          this.$t(`somethingWentWrong`),
          () => (this.showConfirmModal = true)
        )
      } else {
        this.$store.dispatch('makeReAssessment', {
          currentCustomer: this.currentCustomer,
          currentCompany: this.currentCompany,
          workerId: this.workerId,
          captureIdx: this.captureIdx,
          assessmentIdx: this.assessmentIdx,
          details: {
            neutralizationPeriods: this.newNeutralizationPeriods,
            rotations: this.newRotations,
            shifts: this.newShifts,
            thoraxForwardOverride: this.shouldOverrideThx,
          },
        })

        EventBus.$emit('UPDATE_ASSESSMENT')
      }
    },

    async isAssessmentProcessing() {
      return (await this.$store.dispatch('getAssessmentProgress', this.captureIdx)).inProgress
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';

.sz-EditorAssessmentRepair {
  height: 100%;
  width: 100%;
  padding: 0 0 0 3.8rem;
  display: flex;
  flex-direction: row;

  &-definition {
    width: 75vw;
    height: 100%;
    background-color: $color-tooltip-dark;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: scroll;
    @include scrollbar-widget;

    &-title {
      @extend %font-heading;
      padding: 1rem 0 1rem 1rem;
    }

    &-sections {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      height: 100%;
      width: 100%;
      padding-bottom: 1rem;

      &-left {
        padding-left: 1rem;
        min-width: 8vw;

        &-item {
          padding-bottom: 1rem;
        }
      }

      &-right {
        padding-left: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;

        &-title {
          @extend %font-heading;
          padding-left: 2.2rem;
          padding-bottom: 0.5rem;
          display: flex;
          flex-direction: row;
          align-items: center;

          &-icon {
            padding: 0.3rem 0 0 0.5rem;
          }
        }

        &-graph {
          width: 100%;
          height: 100%;
        }

        &-times {
          display: flex;
          flex-direction: row;
          align-self: flex-start;

          &-header {
            @extend %font-heading;
            padding-left: 2.3rem;
          }

          &-time {
            @extend %font-topbar-heading-unselectable;
            padding-left: 0.25rem;
            min-width: 5rem;
          }
        }
      }
    }

    &-footer {
      align-self: center;

      &-buttons {
        background-color: $color-tooltip-dark;
        display: flex;
        flex-direction: row;
        align-items: center;
        @extend %font-topbar-heading;
        padding-bottom: 1rem;

        &-reset {
          color: $color-white;
          margin: 0 1rem;
          cursor: pointer;
          align-items: center;
          font-size: 16px;
        }

        &-save {
          font-size: 16px;
          @extend %button-layout;
          padding: 0.5rem 1rem;
          border-radius: 0;

          &-invalid {
            background-color: $color-button-disabled-bg;
            color: $color-unselected-text;
            cursor: not-allowed;
          }

          &-valid {
            background-color: $color-lifebooster-medium-green;

            &:hover {
              background-color: $color-lifebooster-light-green;
            }
          }
        }
      }
    }
  }

  &-rightPanel {
    padding-left: 1rem;
    width: 25vw;
    height: 100%;
    background-color: $color-body-bg;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    overflow-y: scroll;
    @include scrollbar-widget;

    &-table {
      height: 100%;
    }

    &-assessmentID {
      align-self: flex-start;
      width: 100%;
      padding-bottom: 1rem;
      font-size: 14.25px;
    }

    &-thxToggle {
      display: flex;
      align-items: center;
      margin: 0.5rem 0;
    }

    &-buttons {
      width: 100%;
      background-color: transparent;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 0.5rem;
      @extend %font-topbar-heading;

      &-save {
        width: inherit;
        font-size: 16px;
        padding: 0.5rem 1rem;
        border: transparent;
        border-radius: 0;
        cursor: pointer;
        background-color: $color-lifebooster-medium-green;

        &:hover {
          background-color: $color-lifebooster-light-green;
        }
      }
    }
  }
}

.fade {
  &-enter-active,
  &-enter-to,
  &-leave-active {
    transition: opacity 0.2s;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
