<template lang="pug">
    div(class="sz-MotionAssessmentInfo")
      table()
          div(class="sz-MotionAssessmentInfo-headers")
              div(class="sz-MotionAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.riskTypes`)}` }}
          div(
            v-for="row of currentlySelectedRisks",
            class="sz-MotionAssessmentInfo-row")
                div(class="sz-MotionAssessmentInfo-cell") {{ row }}
      table()
          div(class="sz-MotionAssessmentInfo-headers")
              div(class="sz-MotionAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.currentA8`)}` }}
          div(
            v-for="angle of currentAngles",
            class="sz-MotionAssessmentInfo-row")
                div(
                    class="sz-MotionAssessmentInfo-cell",
                    v-bind:class= "isNaN(angle) ? 'sz-MotionAssessmentInfo-cell-empty' : 'sz-MotionAssessmentInfo-cell'") {{ angle }}
      table()
          div(class="sz-MotionAssessmentInfo-headers")
              <th colspan="4"> {{ `${this.$t(`motionAssessment.assessmentInfo.timeSpentInRisk`)}` }} </th>
          div(
            v-for="row of riskTypePayloads",
            class="sz-MotionAssessmentInfo-row")
                div(class="sz-MotionAssessmentInfo-cell-greenRiskCount") {{ row.greenDuration }}
                div(class="sz-MotionAssessmentInfo-cell-yellowRiskCount") {{ row.yellowDuration }}
                div(class="sz-MotionAssessmentInfo-cell-orangeRiskCount") {{ row.orangeDuration }}
                div(class="sz-MotionAssessmentInfo-cell-redRiskCount") {{ row.redDuration }}
      table()
          div(class="sz-MotionAssessmentInfo-headers")
              div(class="sz-MotionAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.averageA8`)}` }}
          div(
            v-for="row of riskTypePayloads",
            class="sz-MotionAssessmentInfo-row")
                div(class="sz-MotionAssessmentInfo-cell") {{ row.a8 }}
</template>

<script>
    import { tooltip } from 'directives/tooltip'
    import EventBus from 'src/eventBus'
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'

    export default {
        name: "MotionAssessmentInfo",

        directives: {
            tooltip,
        },

        components: {
        },

        props: {
            selectedRisks: {
                type: Array,
                required: true,
            },

            selectedAssessment: {
                type: Object,
                required: true,
            },

            humidity: {
                type: Number,
                required: true,
            },
        },

        data () {
            return {
                currentAngles: null,
            }
        },

        computed: {
            ...mapGetters([
                'selectedModule',
            ]),

            currentlySelectedRisks() {
                return this.selectedRisks.map(risk => {
                    return this.$t(`riskTypes.${risk}`)
                })
            },

            riskTypePayloads() {
                return this.selectedRisks.map(risk => {
                    let payload = this.selectedAssessment.RiskTypes.find(r => r.name === risk).payload
                    return {
                        greenDuration: this.formatDuration(payload.greenDuration),
                        yellowDuration: this.formatDuration(payload.yellowDuration),
                        orangeDuration: this.formatDuration(payload.orangeDuration),
                        redDuration: this.formatDuration(payload.redDuration),
                        a8: payload.A8.toFixed(4),
                    }
                })
            },

            headerTooltip() {
              return {
                  tooltipBodySettings: {
                      view: {
                          color: 'dark',
                          textAlign: 'center',
                          padding: 'extra-padding',
                      },
                      title: 'Positive Threshold',
                  },
                  body: {
                      placement: 'bottom-right',
                      offset: '0, -30',
                  },
                  allowHover: true,
              }
            },
        },

        mounted() {
            EventBus.$on('UPDATE_CURRENT_ANGLES', (angles) => {
                this.currentAngles = angles
            })
        },

        beforeDestroy() {
            EventBus.$off('UPDATE_CURRENT_ANGLES')
        },

        methods: {
            formatDuration(duration) {
                return new Date(duration * 1000).toISOString().substr(11, 8).replace(':', 'h').replace(':', 'm') + 's'
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";

    th {
        @extend %font-heading;
        padding: 0.25rem;
        font-size: 0.75rem;
        text-align: center;
        vertical-align: middle;
        border: 0.3rem solid $color-table-dark-background;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    table {
        width: 100%;
        table-layout: auto;
        border-collapse: collapse;
    }

    .sz-MotionAssessmentInfo {
        display: flex;
        flex-direction: row;
        padding: 0.25rem 0.75rem 0.75rem 0.75rem;

        &-headers {
            background-color: $color-table-dark-background;
            display: table-header-group;
            margin-bottom: 0.3rem;
        }

        &-header {
            @extend %font-heading;
            padding: 0.25rem;
            font-size: 0.75rem;
            display: table-cell;
            text-align: center;
            vertical-align: middle;
            border: 0.3rem solid $color-table-dark-background;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &-row {
            display: table-row-group;
            padding: 0.5rem;
            background-color: $color-lifebooster-dark-green;
        }

        &-cell {
            border: 0.3rem solid $color-table-dark-background;
            padding: 0.5rem;
            display: table-cell;
            font-size: 0.8rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &-empty {
                color: $color-lifebooster-dark-green;
            }

            &-redRiskCount {
                border: 0.3rem solid $color-table-dark-background;
                background-color: $color-body-bg;
                display: table-cell;
                padding: 0.5rem;
                font-size: 0.8rem;
                outline: 0.2rem solid $color-risk-red;
                outline-offset: -0.35rem;
            }

            &-orangeRiskCount {
                border: 0.3rem solid $color-table-dark-background;
                background-color: $color-body-bg;
                display: table-cell;
                padding: 0.5rem;
                font-size: 0.8rem;
                outline: 0.2rem solid $color-risk-orange;
                outline-offset: -0.35rem;
            }

            &-yellowRiskCount {
                border: 0.3rem solid $color-table-dark-background;
                background-color: $color-body-bg;
                display: table-cell;
                padding: 0.5rem;
                font-size: 0.8rem;
                outline: 0.2rem solid $color-risk-yellow;
                outline-offset: -0.35rem;
            }

            &-greenRiskCount {
                border: 0.3rem solid $color-table-dark-background;
                background-color: $color-body-bg;
                display: table-cell;
                padding: 0.5rem;
                font-size: 0.8rem;
                outline: 0.2rem solid $color-risk-green;
                outline-offset: -0.35rem;
            }
        }

        &-label {
            @extend %font-heading;
            font-size: 0.75rem;
            display: table-cell;
            border: 0.3rem solid $color-table-dark-background;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
</style>
