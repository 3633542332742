<template lang="pug">
    div(
        v-click-away="close",
        class="sz-DropdownAssessments")
        div(class="sz-DropdownAssessments-header")
        div(class="sz-DropdownAssessments-options")
            div(
                class="sz-DropdownAssessments-options-option",
                :class="{currentOption: formattedDate.capture ===  captureIdx}"
                v-for="formattedDate in formattedDates",
                :key="formattedDate.capture",
                @click.stop="clickedNewAssessment(formattedDate)") {{ formattedDate.date }}
</template>

<script>
import { ClickAway } from 'directives/clickAway'
import constants from 'helpers/constants'
import moment from 'moment-timezone'
import { cloneDeep } from 'lodash'

export default {
    name: "DropdownAssessments",

    directives: {
        ClickAway,
    },

    props: {
        allAssessmentDates: {
            required: true,
            type: Array,
        },
        assessmentTimezone: {
            required: true,
            type: String,
        },
        captureIdx: {
            required: true,
            type: [String, Number],
        },
    },

    computed: {
        formattedDates () {
            let sortDates = cloneDeep(this.allAssessmentDates).sort((a, b) => a.startTimePosix - b.startTimePosix)

            return sortDates.map((date) => {
                date.date = this.formatDate(date.startTimePosix)
                return date
            })
        },
    },

    methods: {
        formatDate(date) {
            return moment.tz((date * constants.UNIX_MILLISECONDS), this.assessmentTimezone).format("MMM D, YYYY hh:mm:ss A")
        },

        clickedNewAssessment(assessmentDate) {
            this.$emit('updateSelectedAssessment', assessmentDate.capture, assessmentDate.assessment, assessmentDate.startTimePosix)
            this.$store.dispatch('updateSelectedAssessmentTimestamp', assessmentDate.startTimePosix)
        },

        close () {
            this.$emit('closeDropdown')
        },
    },

}
</script>

<style lang="scss" scoped>
@import '~design';

.sz-DropdownAssessments {
    @include scrollbar-widget;
    position: absolute;
    left: 33rem;
    top: 3.8rem;
    width: 9rem;
    max-height: 6rem;
    padding: 0.4rem;
    z-index: $layer-dropdown-z-index;
    background-color: $color-dropdown-background;
    font-size: 0.75rem;
    overflow: scroll;
    cursor: pointer;
    z-index: 5;

    &-options {
        &-option {
            padding: 0.3rem 0;
            margin: 0.2rem 0;
            &:hover {
                background-color: $color-table-row-hover;
                color: white;
            }
            &.currentOption {
                background-color: $color-table-row-hover;
            }
        }
    }
}

</style>
