<template lang="pug">
    div(class="sz-SensorFlipOrShiftSelector")
        div(class="sz-SensorFlipOrShiftSelector-container")
            div(class="sz-SensorFlipOrShiftSelector-container-title") {{ title }}
                div(v-tooltip="titleTooltip")
                    SvgIcon(
                        icon="info",
                        width="1rem",
                        height="1rem",
                        class="sz-SensorFlipOrShiftSelector-container-title-icon")
            div(
              v-for="option in options",
              :key="option.key",
              @click.stop="selectOption(option.key)",
              class="sz-SensorFlipOrShiftSelector-container-options",
              :class="`sz-SensorFlipOrShiftSelector-container-options` + (isSelected(option.key) ? '-selected' : '')") {{ option.text }}
</template>

<script>
    import constants from 'helpers/constants'
    import SvgIcon from 'components/Shared/SvgIcon'
    import { tooltip } from 'directives/tooltip'
    import EventBus from 'src/eventBus'

    export default {
        name: 'SensorFlipOrShiftSelector',

        components: {
            SvgIcon,
        },

        directives: {
            tooltip,
        },

        props: {
            selectedSensor: {
                required: true,
                type: String,
            },
        },

        data () {
            return {
                selectedOption: null,
            }
        },

        computed: {
            options () {
                return [
                  { key: true, text: constants.BOOLEAN_MAPPER.true.toUpperCase() },
                  { key: false, text: constants.BOOLEAN_MAPPER.false.toUpperCase() },
                ]
            },

            title() {
                return this.selectedSensor === constants.DEVICE_STATUS_CHARACTERISTICS.PEL ?
                    this.$t('motionAssessment.editor.repair.sensorShifted') :
                    this.$t('motionAssessment.editor.repair.sensorFlipped')
            },

            title() {
                return this.selectedSensor === constants.DEVICE_STATUS_CHARACTERISTICS.PEL ?
                    this.$t('motionAssessment.editor.repair.sensorShifted') :
                    this.$t('motionAssessment.editor.repair.sensorFlipped')
            },

            titleTooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'left',
                            padding: 'extra-padding',
                            width: 'parameters-width',
                        },
                        title: this.selectedSensor === constants.DEVICE_STATUS_CHARACTERISTICS.PEL ?
                            this.$t('motionAssessment.editor.repair.sensorShiftedTooltip') :
                            this.$t('motionAssessment.editor.repair.sensorFlippedTooltip'),
                    },

                    body: {
                        placement: 'bottom-left',
                        offset: '120, 5',
                    },
                    allowHover: true,
                }
            },
        },

        mounted () {
            EventBus.$on('RESET_ORIENTATION', () => {
                this.selectedOption = null
            })
        },

        beforeDestroy () {
            EventBus.$off('RESET_ORIENTATION')
        },

        methods: {
            isSelected(option) {
                return this.selectedOption === option
            },

            selectOption(option) {
                this.selectedOption = option
                this.$emit('updateIsFlippedOrShifted', option)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";

    .sz-SensorFlipOrShiftSelector {
        &-container {
            display: flex;
            flex-direction: column;

            &-title {
                @extend %font-heading;
                display: flex;
                flex-direction: row;
                align-items: center;
                align-self: center;

                &-icon {
                    padding: 0.35rem 0 0 0.25rem;
                }
            }

            &-options {
                @extend %font-heading;
                font-weight: 100;
                font-size: 15px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1.5px solid $color-white;
                height: 2.2rem;
                margin: 0.5rem 0;
                color: $color-white;
                cursor: pointer;

                &-selected, &:hover {
                    background-color: $color-lifebooster-medium-green;
                    border: 1.5px solid $color-lifebooster-medium-green;
                }
            }
        }
    }
</style>
