<template lang="pug">
    div(class="sz-RightPanelVibration")
        div(class="sz-RightPanelVibration-box")
            div(class="sz-RightPanelVibration-header") {{ $t('vibrationAssessment.thresholdSource') }}:
            div(class="sz-RightPanelVibration-value") {{ thresholdSourceVal }}
        div(class="sz-RightPanelVibration-box")
            div(class="sz-RightPanelVibration-header") {{ $t('vibrationAssessment.freqRange') }}:
            div(class="sz-RightPanelVibration-value") {{ freqRangeVal }}

</template>

<script>
    import constants from 'helpers/constants'

    export default {
        name: 'RightPanelVibration',

        computed: {
            thresholdSourceVal () {
                return constants.VIBRATION_THRESHOLD_SOURCE
            },

            freqRangeVal () {
                return constants.VIBRATION_FREQ_RANGE
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";
    .sz-RightPanelVibration {
        &-box {
            display: flex;
            flex-direction: row;
            background-color: $color-header-bar-backround;
            margin-bottom: 0.5rem;
            padding: 0.5rem 0.7rem;
            justify-content: space-between;
        }

        &-header {
            @extend %font-topbar-heading-unselectable;
            font-size: 0.75rem;
            margin-right: 0.5rem;
        }

        &-value {
            font-size: 0.85rem;
        }
    }

</style>
