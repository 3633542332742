<template lang="pug">
    div(class="sz-EditorExtraInfoPanel")
        div(class="sz-EditorExtraInfoPanel-options")
            div(class="sz-EditorExtraInfoPanel-options-option")
                div(class="sz-EditorExtraInfoPanel-options-option-label") {{ $t('motionAssessment.editor.timezone') }}
                div(class="sz-EditorExtraInfoPanel-options-option-selectContainer")
                    FormSectionSingleSelectSearchable(
                        title="",
                        :options="timezoneOptions",
                        :defaultSelectedOption="assessmentTimezone",
                        width="fullWidth",
                        :isSorted="false"
                        @updateSelectedOption="updateSelectedTimezone",
                        class="sz-EditorExtraInfoPanel-options-option-select"
                    )
        div(class="sz-EditorExtraInfoPanel-button",
            :class="'sz-EditorExtraInfoPanel-button-' + isSaveButtonInactive",
            @click.stop="save()") {{ $t('motionAssessment.editor.reassess') }}
</template>

<script>
import FormSectionDropdownSingleSelect from 'components/Shared/FormSectionDropdownSingleSelect'
import FormSectionToggle from 'components/Shared/FormSectionToggle'
import moment from 'moment-timezone'
import EventBus from 'src/eventBus'
import constructModalSettings from 'mixins/modalSettings'
import formValidator from 'mixins/formValidator'
import constants from 'helpers/constants'
import { mapGetters } from 'vuex'
import FormSectionSingleSelectSearchable from 'components/Shared/FormSectionSingleSelectSearchable'
import { timezones } from 'helpers/timezones'

export default {
    name: 'EditorExtraInfoPanel',

    components: {
        FormSectionDropdownSingleSelect,
        FormSectionToggle,
        FormSectionSingleSelectSearchable,
    },

    mixins: [
        constructModalSettings,
        formValidator,
    ],

    props: {
        captureIdx: {
            type: [Number, String],
            required: true,
        },
        assessmentIdx: {
            type: String,
            required: true,
        },
        assessmentTimezone: {
            required: true,
            type: String,
        },
        assessmentStart: {
            required: true,
            type: Number,
        },
        assessmentEnd: {
            required: true,
            type: Number,
        },
    },

    data() {
        return {
            timezone: undefined,
            times: new Array(2),
            showConfirmModal: true,
        }
    },

    computed: {
        ...mapGetters([
            'currentCustomer',
            'currentCompany',
            'workerId',
        ]),

        timezoneOptions () {
            return timezones
        },

        settingsHaveChanged () {
            return this.assessmentChanged
        },

        newSettings () {
            return {
                timezone: this.timezone,
                startTime: this.times[constants.EDITOR.START],
                endTime: this.times[constants.EDITOR.END],
            }
        },

        isSaveButtonInactive () {
            return (!this.settingsHaveChanged) ? 'inactive' : 'active'
        },

        assessmentChanged () {
            return this.timezone !== this.assessmentTimezone ||
                moment(this.times[constants.EDITOR.START]).format('LTS') !== moment.tz((this.assessmentStart * constants.UNIX_MILLISECONDS), this.assessmentTimezone).format('LTS') ||
                moment(this.times[constants.EDITOR.END]).format('LTS') !== moment.tz((this.assessmentEnd * constants.UNIX_MILLISECONDS), this.assessmentTimezone).format('LTS')
        },
    },

    watch: {
        showConfirmModal: {
            handler: function () {
                if (!this.showConfirmModal) {
                    this.reAssessedClicked()
                }
            },
            deep:true,
        },
    },

    mounted() {
        EventBus.$on('UPDATE_ANNOTATION_POSITION_EDITOR', (params) => {
            this.setTime(params.timestamp, params.type)
        })

        EventBus.$on('USER_CONFIRMED', () => {
            this.showConfirmModal = false
        })

        EventBus.$on('USER_CANCELLED', () => {
            this.showConfirmModal = true
        })

        this.setTime(moment.tz((this.assessmentStart * constants.UNIX_MILLISECONDS), this.assessmentTimezone), constants.EDITOR.START)
        this.setTime(moment.tz((this.assessmentEnd * constants.UNIX_MILLISECONDS), this.assessmentTimezone), constants.EDITOR.END)
    },

    beforeDestroy() {
        EventBus.$off('UPDATE_ANNOTATION_POSITION_EDITOR')

        EventBus.$off('USER_CONFIRMED')

        EventBus.$off('USER_CANCELLED')
    },

    methods: {
        updateSelectedTimezone (newTimezone) {
            this.timezone = newTimezone
        },

        setTime (time, type) {
            this.$set(this.times, type,time)
        },

        async reAssessedClicked () {
            try {
                // Refuse the update if the assessment is currently being processed
                let isProcessing = await this.isAssessmentProcessing()
                if (isProcessing) {
                    this.failModal(
                        this.$t(`motionAssessment.editor.reassessmentInProgress`),
                        this.$t(`somethingWentWrong`),
                        () => this.showConfirmModal = true
                    )
                } else {
                    this.updateReassessment()
                    this.successModal(
                        this.$t(`motionAssessment.editor.successMessage`),
                        true,
                        () => EventBus.$emit('UPDATE_ASSESSMENT'))
                }
            } catch (error) {
                this.failModal(
                    this.$t("pleaseTryAgain"),
                    this.$t("somethingWentWrong"),
                    () => this.showConfirmModal = true
                )
                console.log('error changing timezone')
                console.log(error)
                throw new Error(error.message)
            }

        },

        async save() {
            if (!this.settingsHaveChanged) return

            if (this.showConfirmModal) {
                this.confirmModal(
                    this.$t('motionAssessment.editor.confirmMessage.title'),
                    this.$t('motionAssessment.editor.confirmMessage.message'),
                    this.newSettingsMessageList(),
                )
            }
        },

        async updateReassessment () {
            if (this.assessmentChanged) {
                await this.$store.dispatch(
                    'makeReAssessment',
                    {
                        currentCustomer: this.currentCustomer,
                        currentCompany: this.currentCompany,
                        workerId: this.workerId,
                        captureIdx: this.captureIdx,
                        assessmentIdx: this.assessmentIdx,
                        details: {
                            // If either of the timestamps weren't changed,
                            // it remains in a native Moment format so we need to convert it to a millisecond timestamp
                            start: parseInt(moment.tz(this.newSettings.startTime, this.assessmentTimezone).format('x')),
                            end: parseInt(moment.tz(this.newSettings.endTime, this.assessmentTimezone).format('x')),
                            timezone: this.newSettings.timezone,
                        },
                    }
                )
            }
        },

        newSettingsMessageList () {
            let list = []

            Object.keys(this.newSettings).map((option) => {
                if (this.newSettings[option] !== null && this.newSettings[option] !== undefined) {
                    this.setMessage(list, option)
                }
            })
            return list
        },

        setMessage (list, option) {
            let title = this.$t(`motionAssessment.editor.${option}`)
            let settingValue = this.newSettings[option]

            switch (option) {
                case constants.EDITOR.CHANGE_OPTIONS.START_TIME:
                case constants.EDITOR.CHANGE_OPTIONS.END_TIME:
                    list.push(`${ title }: ${ moment.tz(settingValue, this.assessmentTimezone).format('LTS') }`)
                    break

                default:
                    list.push(`${ title }: ${ settingValue }`)
                    break
            }
        },

        async isAssessmentProcessing () {
            return (await this.$store.dispatch('getAssessmentProgress', this.captureIdx)).inProgress
        },
    },
}
</script>

<style lang="scss" scoped>
    @import '~design';
    input:focus {outline:none;}


    .sz-EditorExtraInfoPanel {
        &-button {
            @extend %button-layout;
            @extend %font-heading;
            background-color: $color-lifebooster-light-green;
            padding: 0.5rem 1rem;
            margin-bottom: 1rem;
            border: 0;
            border-radius: 0;
            color: white;
            display: flex;
            justify-content: center;

            &-inactive {
                background-color: $color-unselected-text;
                cursor: not-allowed;
                user-select: none;
            }
        }
        &-options {
            margin-bottom: 1rem;
            &-option {
                display: flex;
                align-items: center;
                margin: 0.5rem 0;
                &-label {
                    @extend %font-topbar-heading;
                    font-size: 0.85rem;
                    width: 5rem;
                    text-align: left;

                    &-timezone {
                        width: 5.5rem;
                    }
                }

                &-number {
                    width: 4rem;
                    background-color: transparent;
                    color: white;
                    border: none;
                    border-bottom: 1px solid $color-lifebooster-medium-green;

                    &-disabled {
                        cursor: not-allowed;
                        color: $color-body-bg;
                    }
                }

                &-select {
                    font-size: 0.85rem;
                }

                &-selectContainer {
                    width: 100%;
                    margin-left: 1.5rem;
                }
            }
        }
    }

</style>
