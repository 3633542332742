<template lang="pug">
    div(class="sz-PanelRiskTypeSelector")
        div(
            class="sz-PanelRiskTypeSelector-slider",
            :class="{'sz-PanelRiskTypeSelector-slider-open': sliderOpen}")
            div(
                @click="toggleSideBar",
                class="sz-PanelRiskTypeSelector-slider-button")
                SvgIcon(
                    icon="arrow",
                    width="15",
                    height="15",
                    :class="{'open': sliderOpen}",
                    class="sz-PanelRiskTypeSelector-slider-button-arrow")
            div(
                v-if="loadingGraph",
                class="sz-PanelRiskTypeSelector-loading",
                :class="{'loading-open': !sliderOpen}")
                LoadingSpinner(color="light")
            div(v-if="!loadingAssessment")
                component(
                    :is="currentSelector",
                    :class="{'open': sliderOpen}",
                    :assessment="assessment",
                    :selectedRisks="selectedRisks",
                    :jointKeys="jointKeys",
                    @updateSelectedRiskTypes="selectRiskTypes")
</template>

<script>
    import constants from 'helpers/constants'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'
    import SvgIcon from 'components/Shared/SvgIcon'
    import { mapGetters } from 'vuex'
    import EventBus from 'src/eventBus'
    import MotionRiskTypeSelector from 'components/Assessment/MotionAssessment/MotionRiskTypeSelector'
    import PostureRiskTypeSelector from 'components/Assessment/PostureAssessment/PostureRiskTypeSelector'
    import ThermalRiskTypeSelector from 'components/Assessment/ThermalAssessment/ThermalRiskTypeSelector'
    import VibrationRiskTypeSelector from 'components/Assessment/VibrationAssessment/VibrationRiskTypeSelector'
    import modalSettings from 'mixins/modalSettings'
    import formValidator from 'mixins/formValidator'

    export default {
        name: 'PanelRiskTypeSelector',

        components: {
            LoadingSpinner,
            SvgIcon,
            MotionRiskTypeSelector,
            PostureRiskTypeSelector,
            ThermalRiskTypeSelector,
            VibrationRiskTypeSelector,
        },

        mixins: [
            modalSettings,
            formValidator,
        ],

        props: {
            assessment: {
                required: true,
                type: Object,
            },

            loadingAssessment: {
                required: true,
                type: Boolean,
            },

            loadingGraph: {
                required: true,
                type: Boolean,
            },

            selectedRisks: {
                required: true,
                type: Array,
            },

            jointKeys: {
                required: true,
                type: Object,
            },
        },

        data() {
            return {
                sliderOpen: false,
                disableChartInteraction: false,
            }
        },

        computed: {
            ...mapGetters([
                'selectedModule',
            ]),

            currentSelector () {
                if (!this.selectedModule in constants.ASSESSMENT_RISK_SELECTOR_COMPONENT_MAP) return
                return constants.ASSESSMENT_RISK_SELECTOR_COMPONENT_MAP[this.selectedModule]
            },
        },

        mounted() {
            EventBus.$on('SET_DISABLE_CHART_INTERACTION', (disabled) => {
                this.disableChartInteraction = disabled
            })
        },

        beforeDestroy() {
            EventBus.$off('SET_DISABLE_CHART_INTERACTION', () => {
                this.disableChartInteraction = false
            })
        },

        methods: {
            toggleSideBar () {
                this.sliderOpen = !this.sliderOpen
            },

            selectRiskTypes (riskType) {
                if (this.disableChartInteraction) {
                    return this.failModal(
                        this.$t(`motionAssessment.warnings.pauseAnimatorJoints`),
                        this.$t(`motionAssessment.warnings.notice`),
                        () => this.showConfirmation = true
                    )
                }

                if (this.selectedRisks.length < 3 || !!this.selectedRisks.find(risk => risk === riskType)) {
                    this.$emit('updateSelectedRiskTypes', riskType)
                }
            },
        },

    }
</script>

<style lang="scss" scoped>
    @import "~design";

    .sz-PanelRiskTypeSelector {
        position: absolute;
        height: 100vh;
        z-index: $layer-popover-z-index;

        &-slider {
            font-size: 13px;
            width: 14.25rem;
            height: 100%;
            background-color: $color-body-bg;
            border-right: 0.1px solid darken($color-white, 60%);
            box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.48);
            transition: width 0.3s;

            &-open {
                width: 0.1rem;
                transition: 0.5s;
            }

            &-button {
                width: 1rem;
                height: 1rem;
                border-radius: 0.5rem;
                background-color: $color-lifebooster-light-green;
                z-index: $layer-top-level;
                cursor: pointer;
                position: absolute;
                top: 50%;
                left: 100%;
                right: 0;
                bottom: 0;
                margin-left: -0.5rem;
                transition: left 0.5s;

                &-arrow {
                    transform: rotate(90deg);
                    padding-left: 0.08rem;
                    padding-top: 0.25rem;

                    &.open {
                        transform: rotate(-90deg);
                        padding-right: 1rem;
                        padding-top: 0.25rem;
                    }
                }
            }
        }

        &-loading {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            opacity: 0.4;
            filter: blur(1px);
            cursor: default;
            color: $color-white;
            background-color: lighten($color-body-bg, 10%);
            width: 100%;
            height: 100%;
            visibility: hidden;
            transition: visibility 0.2s;

            &.loading-open {
                visibility: visible;
            }
        }
    }
</style>
