<template lang="pug">
    div(class="sz-EditorAssessmentInfo")
        div(class="sz-EditorAssessmentInfo-sections")
            EditorTime(
                :captureStart="captureStart",
                :captureEnd="captureEnd",
                :assessmentStart="assessmentStart",
                :assessmentEnd="assessmentEnd",
                :assessmentTimezone="assessmentTimezone")
            div(class="sz-EditorAssessmentInfo-angleContainer")
                div(class="sz-EditorAssessmentInfo-angleContainer-angles") {{ startAngle }}
                div(class="sz-EditorAssessmentInfo-angleContainer-angles") {{ endAngle }}
</template>

<script>
    import { tooltip } from 'directives/tooltip'
    import EventBus from 'src/eventBus'
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'
    import EditorTime from 'src/components/Assessment/Editor/EditorTime'

    export default {
        name: "EditorAssessmentInfo",

        directives: {
            tooltip,
        },

        components: {
            EditorTime,
        },

        props: {
            selectedRisks: {
                type: Array,
                required: true,
            },

            selectedAssessment: {
                type: Object,
                required: true,
            },

            assessmentStart: {
                required: true,
                type: Number,
            },

            assessmentEnd: {
                required: true,
                type: Number,
            },

            assessmentTimezone: {
                required: true,
                type: String,
            },

            captureStart: {
                required: true,
                type: Number,
            },

            captureEnd: {
                required: true,
                type: Number,
            },
        },

        data () {
            return {
                startAngle: '0°',
                endAngle: '0°',
            }
        },

        computed: {
            ...mapGetters([
                'selectedModule',
            ]),

            currentlySelectedRisks() {
                return this.selectedRisks.map(risk => {
                    return this.$t(`riskTypes.${risk}`)
                })
            },
        },

        mounted() {
            EventBus.$on('UPDATE_CURRENT_ANGLE_EDITOR', (params) => {
                this.setAngle(params.angle, params.type)
            })
        },

        beforeDestroy() {
          EventBus.$off('UPDATE_CURRENT_ANGLE_EDITOR')
        },

        methods: {
            setAngle(angle, type) {
                switch(type) {
                    case constants.EDITOR.START:
                        this.startAngle = this.formatAngle(angle)
                        break
                    case constants.EDITOR.END:
                        this.endAngle = this.formatAngle(angle)
                        break
                }
            },

            formatAngle(angle) {
                return `${angle}°`
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";

    .sz-EditorAssessmentInfo {
        display: flex;
        flex-direction: row;
        padding: 0.75rem 0 0.75rem 0;
        justify-content: center;

        &-sections {
            display: flex;
            flex-direction: column;
        }

        &-angleContainer {
            display: flex;
            flex-direction: row;
            justify-content: center;

            &-angles {
                @extend %font-heading;
                background-color: $color-lifebooster-light-green;
                font-size: 13px;
                padding: 0.5rem 1.5rem;
                margin: 0.5rem;
                min-width: 2.5rem;
            }
        }
    }
</style>
