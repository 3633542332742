<template lang="pug">
    transition(name="fade")
        div(class="sz-Assessment")
            div(
                v-if="showAssessmentPopup",
                class="sz-Assessment-popup")
                div(v-if="refreshingAssessment")
                    LoadingSpinner(color="light")
                div(v-else)
                    div(class="sz-Assessment-popup-text") {{ $t('motionAssessment.editor.reassessmentInProgress') }}
                    div(
                        class="sz-Assessment-popup-button",
                        @click="refreshAssessment") {{ $t('motionAssessment.editor.refresh') }}
            div(
                v-if="!loadingAssessment && !error",
                class="sz-Assessment",
                :class="`sz-Assessment-${ isBlurPage }`")
                div(class="sz-Assessment-top")
                    div(class="sz-Assessment-top-slideout")
                        PanelRiskTypeSelector(
                            v-if="!isEditorOptionRepair",
                            :assessment="assessment",
                            :loadingAssessment="loadingAssessment",
                            :selectedRisks="currentlySelectedRisks",
                            :jointKeys="jointKeys",
                            :loadingGraph="loadingGraph",
                            @updateSelectedRiskTypes="setSelectedRisks")
                div(class="sz-Assessment-panels")
                    div(class="sz-Assessment-panels-graph")
                        div(v-if="isAssessmentEditor")
                            EditorOptions(
                              @updateEditorOption="updateEditorOption",
                              class="sz-Assessment-panels-editorOptions")
                        EditorAssessmentRepair(
                            v-if="isEditorOptionRepair",
                            :loadingGraph="loadingGraph",
                            :assessmentTimezoneOffset="assessmentTimezoneOffset",
                            :assessmentTimezone="assessmentTimezone"
                            :jointKeys="jointKeys",
                            :assessmentStart="assessmentStart",
                            :assessmentEnd="assessmentEnd",
                            :currentCustomer="currentCustomer",
                            :currentCompany="currentCompany",
                            :workerId="workerId",
                            :captureIdx="captureIdx",
                            :assessmentIdx="assessmentIdx",
                            :defaultNeutralizationPeriods="neutralizationPeriods",
                            :defaultSensorRotations="sensorRotations",
                            :defaultSensorShifts="sensorShifts",
                            :defaultThxOverride="thxOverride"
                            class="sz-Assessment-panels-repair")
                        AssessmentInfo(
                            v-if="!isEditorOptionRepair",
                            :loadingGraph="loadingGraph",
                            :selectedRisks="currentlySelectedRisks",
                            :selectedAssessment="assessment",
                            :captureStart="captureStart",
                            :captureEnd="captureEnd",
                            :assessmentStart="assessmentStart",
                            :assessmentEnd="assessmentEnd",
                            :assessmentTimezone="assessmentTimezone")
                        AssessmentChartsPanel(
                            v-if="!isEditorOptionRepair",
                            :loadingGraph="loadingGraph",
                            :graphData="graphData",
                            :selectedAssessment="assessment",
                            :selectedRisks="currentlySelectedRisks",
                            :assessmentTimezoneOffset="assessmentTimezoneOffset",
                            :assessmentTimezone="assessmentTimezone"
                            :jointKeys="jointKeys",
                            :assessmentStart="assessmentStart",
                            :assessmentEnd="assessmentEnd",
                            :currentCustomer="currentCustomer",
                            :currentCompany="currentCompany",
                            :workerId="workerId",
                            :captureIdx="captureIdx",
                            :metadataID="metadataID",
                            :assessmentIdx="assessmentIdx",
                            :motion="currentlySelectedRisk")
                    RightPanelModule(
                        v-if="!loadingGraph && !isEditorOptionRepair && assessmentIdx",
                        :assessmentStart="assessmentStart",
                        :assessmentEnd="assessmentEnd",
                        :loadingGraph="loadingGraph",
                        :assessmentTimezoneOffset="assessmentTimezoneOffset",
                        :assessmentTimezone="assessmentTimezone",
                        :assessmentIdx="assessmentIdx",
                        :metadataID="metadataID",
                        :captureIdx="captureIdx",
                        :indexKey="indexKey",
                        :quatKeys="quatKeys",
                        :jointKeys="jointKeys",
                        :loadingAssessment="refreshingAssessment",
                        :selectedRisks="currentlySelectedRisks",
                        :aggregationAllowed="aggregationAllowed",
                        @refreshGraph="refreshGraph"
                        class="sz-Assessment-characterPanel")
            div(
                v-if="showLoading",
                class="sz-Assessment-loading")
                LoadingSpinner(color="light")
            div(v-else-if="error",
                class="sz-Assessment-error")
                div(class="sz-Assessment-error-body")
                    img(
                        v-bind:src="logo",
                        class="sz-Assessment-error-body-image")
                    div(class="sz-Assessment-error-body-title") {{ $t(`motionAssessment.assessmentErrorTitle`) }}
                    div(class="sz-Assessment-error-body-message") {{ $t(`motionAssessment.assessmentError1`) }}
                    div(class="sz-Assessment-error-body-message") {{ $t(`motionAssessment.assessmentError2`) }}
</template>

<script>
import AssessmentChartsPanel from 'components/Assessment/AssessmentChartsPanel'
import RightPanelModule from 'components/Assessment/RightPanelModule'
import HeaderAssessmentInfo from 'components/Assessment/HeaderAssessmentInfo'
import HeaderJointInfo from 'components/Assessment/HeaderJointInfo'
import PanelRiskTypeSelector from 'components/Assessment/PanelRiskTypeSelector'
import constructModalSettings from 'mixins/modalSettings'
import AssessmentInfo from 'components/Assessment/AssessmentInfo'
import EditorOptions from 'components/Assessment/Editor/EditorOptions'
import EditorAssessmentRepair from 'components/Assessment/Editor/EditorAssessmentRepair'

import EventBus from 'src/eventBus'
import { mapGetters } from 'vuex'
import LoadingSpinner from '../Shared/LoadingSpinner'
import motionAssessmentUtils from 'mixins/motionAssessment'
import constants from 'helpers/constants'
import router from 'router/index'

export default {
  name: 'AssessmentDashboard',

  components: {
    LoadingSpinner,
    PanelRiskTypeSelector,
    AssessmentChartsPanel,
    RightPanelModule,
    HeaderAssessmentInfo,
    HeaderJointInfo,
    AssessmentInfo,
    EditorOptions,
    EditorAssessmentRepair,
  },

  mixins: [motionAssessmentUtils, constructModalSettings],

  props: {
    workerId: {
      type: String,
      required: true,
    },
    captureIdx: {
      type: [Number, String],
      required: true,
    },
    assessmentIdx: {
      type: [Number, String],
      required: true,
    },
    riskType: {
      type: String,
      required: true,
    },
    assessmentDate: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      loadingAssessment: true,
      loadingGraph: true,
      metadataID: '',
      assessmentStart: 0,
      assessmentEnd: 0,
      captureStart: 0,
      captureEnd: 0,
      assessmentTimezone: '',
      assessmentTimezoneOffset: 0,
      allAssessmentDates: [],
      currentlySelectedRisk: '',
      currentlySelectedRisks: [],
      assessment: {},
      graphData: {},
      assessmentDone: false,
      errorStatus: false,
      period: null,
      jointKeys: {},
      quatKeys: {},
      indexKey: null,
      assessmentProgress: null,
      refreshingAssessment: null,
      aggregationAllowed: true,
      neutralizationPeriods: [],
      sensorRotations: [],
      shifts: [],
      thxOverride: false,
      useDynamicHumidity: false,
      logo: require('../../assets/images/LifeBoosterDarkLogo.png'),
      editorOption: '',
    }
  },

  errorCaptured: function (err, component, details) {
    this.error = true
  },

  computed: {
    ...mapGetters(['currentCustomer', 'currentCompany', 'selectedModule', 'workerCard']),

    workerName() {
      return !this.workerCard.firstName && !this.workerCard.lastName
        ? this.$t('homePage.worker')
        : `${this.workerCard.firstName || ''}  ${this.workerCard.lastName || ''}`
    },

    currentRiskData() {
      return this.assessment.RiskTypes.find((risk) => risk.name === this.currentlySelectedRisk)
    },

    error: {
      set(bool) {
        this.errorStatus = bool
      },

      get() {
        return this.errorStatus
      },
    },

    currentJointKey() {
      if (
        this.selectedModule === constants.RISK_MODULES.THERMAL ||
        this.selectedModule === constants.RISK_MODULES.VIBRATION ||
        this.selectedModule === constants.RISK_MODULES.POSTURE
      )
        return 0
      return this.jointKeys[this.currentlySelectedRisk]
    },

    isAssessmentEditor() {
      return this.selectedModule === constants.ASSESSMENT_PAGE.EDITOR
    },

    isBlurPage() {
      return this.assessmentProgress && this.isAssessmentEditor ? 'blur' : 'noBlur'
    },

    showAssessmentPopup() {
      return (
        !this.loadingAssessment && !this.error && this.assessmentProgress && this.isAssessmentEditor
      )
    },

    showLoading() {
      return this.loadingAssessment && !this.error
    },

    getModuleForRequest() {
      return this.isAssessmentEditor ? constants.ASSESSMENT_PAGE.MOTION : this.selectedModule
    },

    requestStartTime() {
      return this.isAssessmentEditor ? this.captureStart : this.assessmentStart
    },

    requestEndTime() {
      return this.isAssessmentEditor ? this.captureEnd : this.assessmentEnd
    },

    humidity() {
      let selectedAssessment = this.allAssessmentDates.find(
        (assessmentDate) => assessmentDate.startTimePosix === this.assessmentStart
      )

      return selectedAssessment ? selectedAssessment.humidity : 0
    },

    isEditorOptionEdit() {
      return this.editorOption === constants.EDITOR_OPTIONS.EDIT
    },

    isEditorOptionRepair() {
      return this.editorOption === constants.EDITOR_OPTIONS.REPAIR
    },
  },

  watch: {
    $route(to, from) {
      if (to.params !== from.params) {
        this.setSelectedRisks(
          this.riskType,
          to.params.assessmentDate !== from.params.assessmentDate
        )
        this.setRoute(this.riskType)
      }
    },

    selectedModule: function (val) {
      if (this.isAssessmentEditor) {
        this.checkAssessmentProgress()
      }
      this.loadingAssessment = true
      this.loadingGraph = true
      this.loadData()
    },
  },

  mounted() {
    if (this.selectedAssessment === undefined) {
      this.$store.dispatch('updateAssessments')
    }

    EventBus.$on('UPDATE_ASSESSMENT', () => {
      this.reassessmentInProgress()
      this.getMotionAssessment()
    })

    if (this.$route.name === constants.PAGES.ASSESSMENT.MODULE) {
      this.$store.dispatch('updateSelectedModule', this.$route.params.assessmentType)
    }

    if (this.$route.name === constants.PAGES.ASSESSMENT.EDITOR) {
      this.$store.dispatch('updateCurrentCustomerAndCompany', {
        customerID: this.$route.params.customerID,
        companyID: this.$route.params.companyID,
      })
      this.$store.dispatch('updateSelectedModule', constants.PAGES.ASSESSMENT.EDITOR)
    }

    this.setSelectedRisks(this.riskType)
    this.setRoute(this.riskType)
    this.loadData()

    if (this.isAssessmentEditor) {
      this.checkAssessmentProgress()
    }

    EventBus.$on('CLEAR_RISK_TYPES', () => {
      this.currentlySelectedRisks = []
    })

    EventBus.$on('UPDATE_SELECTED_ASSESSMENT', (captureID, assessmentID, date) => {
      this.loadingAssessment = true
      this.setSelectedAssessment(captureID, assessmentID, date)
    })

    // Clear graph data when switching modules or assessment dates before the new graph data loads
    EventBus.$on('CLEAR_GRAPH_DATA', () => {
      this.graphData = {}
    })
  },

  updated() {
    EventBus.$emit('ASSESSMENT_TIMEZONE', this.assessmentTimezone)

    EventBus.$emit('ASSESSMENT_DATE', this.assessmentDate)

    EventBus.$emit('ASSESSMENT_START', this.assessmentStart)

    EventBus.$emit('ASSESSMENT_END', this.assessmentEnd)

    EventBus.$emit('ALL_ASSESSMENT_DATES', this.allAssessmentDates)

    EventBus.$emit('CAPTURE_IDX', this.captureIdx)
  },

  beforeDestroy() {
    this.graphData = {}
    this.assessment = {}

    EventBus.$off('UPDATE_ASSESSMENT')

    EventBus.$off('CLEAR_RISK_TYPES')

    EventBus.$off('UPDATE_SELECTED_ASSESSMENT')
  },

  methods: {
    async loadData() {
      try {
        this.error = false
        this.refreshingAssessment = true
        this.loadingGraph = true
        await this.getMotionAssessment()
        await this.getGraphData()
        this.loadingGraph = false
        this.refreshingAssessment = false
      } catch (error) {
        this.error = true
      }
    },

    refreshGraph() {
      this.loadData()
    },

    setRoute(risk) {
      if (risk !== this.currentlySelectedRisk) {
        this.currentlySelectedRisk = risk
        // watcher in assessment panel should pick up this value and trigger a graph reload
        let currentRoute = router.currentRoute
        if (router.currentRoute.params.riskType !== risk) {
          const newRoute = {
            ...currentRoute,
            params: {
              riskType: risk,
            },
          }
          router.replace(newRoute)
        }
      }
    },

    setSelectedRisks(risk, changedAssessmentDate = false) {
      // Need to clear selected risks if we're on the Editor page because it doesn't support multi joint
      if (this.$route.name === constants.PAGES.ASSESSMENT.EDITOR) {
        this.currentlySelectedRisks = []
      }
      if (!!this.currentlySelectedRisks.find((r) => r === risk)) {
        this.currentlySelectedRisks = this.currentlySelectedRisks.filter((r) => r !== risk)
      } else {
        this.currentlySelectedRisks.push(risk)
      }
      if (!changedAssessmentDate) {
        this.getGraphData()
      }
    },

    async setSelectedAssessment(selectedCaptureIdx, selectedAssessmentIdx, selectedDate) {
      if (selectedCaptureIdx != this.captureIdx) {
        let routeName =
          this.selectedModule === constants.ASSESSMENT_PAGE.EDITOR
            ? constants.PAGES.ASSESSMENT.EDITOR
            : constants.PAGES.ASSESSMENT.MODULE

        this.$router.push({
          name: routeName,
          params: {
            workerId: this.workerId,
            captureIdx: selectedCaptureIdx,
            assessmentIdx: selectedAssessmentIdx,
            riskType: this.currentlySelectedRisk,
            assessmentDate: selectedDate,
            assessmentType: this.selectedModule,
          },
        })

        await this.checkAssessmentProgress()
        await this.loadData()
      }
    },

    async getMotionAssessment() {
      console.log('getMotionAssessment called with current Company: ' + this.currentCompany)
      try {
        this.loadingAssessment = true
        await Promise.all([this.getCaptureStartEndTimes(), this.getManifest(), this.getRepairs()])

        // Getting the assessment needs to be performed last
        // because the properties of the capture start and end times, manifest and repair metadata
        // are needed to load the assessment correctly
        await this.getAssessment()
      } catch (error) {
        // Do nothing, the error is logged and handled in the API services
      } finally {
        this.loadingAssessment = false
      }
    },

    async getAssessment() {
      try {
        this.assessment = await this.$store.dispatch('getAssessment', {
          currentCustomer: this.currentCustomer,
          currentCompany: this.currentCompany,
          workerId: this.workerId,
          captureIdx: this.captureIdx,
          assessmentIdx: this.assessmentIdx,
          module: constants.RISK_MODULE_API_NAME_MAP[this.getModuleForRequest],
        })
      } catch (error) {
        console.log('error loading assessment')
        console.log(error)
        throw new Error(error.message)
      }

      this.$store.dispatch('updateSelectedAssessmentTimestamp', this.assessmentStart)
      this.assessmentDone = true
    },

    async getManifest() {
      try {
        const manifest = await this.$store.dispatch('getAssessmentManifest', {
          currentCustomer: this.currentCustomer,
          currentCompany: this.currentCompany,
          workerId: this.workerId,
          captureIdx: this.captureIdx,
          assessmentIdx: this.assessmentIdx,
          module: constants.RISK_MODULE_API_NAME_MAP[this.selectedModule],
        })
        this.metadataID = manifest.metadataID
        this.assessmentStart = manifest.start
        this.assessmentEnd = manifest.end
        this.assessmentTimezone = manifest.timezone
        this.period = manifest.period
        this.assessmentTimezoneOffset = this.calculateTimezoneOffset(
          this.assessmentStart,
          this.assessmentTimezone
        )
        this.quatKeys = manifest.quatKeys
        this.jointKeys = manifest.jointKeys
        this.indexKey = manifest.indexKey
        this.aggregationAllowed =
          manifest.aggregationAllowed !== undefined ? manifest.aggregationAllowed : true
        this.useDynamicHumidity =
          manifest.useDynamicHumidity !== undefined ? manifest.useDynamicHumidity : false
      } catch (error) {
        console.log('error loading assessment manifest')
        console.log(error)
        throw new Error(error.message)
      }
    },

    async getRepairs() {
      try {
        const capture = await this.$store.dispatch('getCapture', {
          currentCustomer: this.currentCustomer,
          currentCompany: this.currentCompany,
          workerId: this.workerId,
          captureIdx: this.captureIdx,
        })

        this.neutralizationPeriods = capture.neutralize || []
        this.sensorRotations = capture.rotations || []
        this.sensorShifts = capture.shifts || []
        this.thxOverride = capture.thoraxForwardOverride || false
      } catch (error) {
        console.log(`Error loading repair info: ${error}`)
      }
    },

    async getGraphData() {
      this.loadingGraph = true
      if (this.assessmentDone) {
        this.graphData = {}
        await Promise.all(
          this.currentlySelectedRisks.map(async (risk) => {
            this.graphData[risk] = []
            try {
              this.graphData[risk] = await this.$store.dispatch('getAssessmentGraphData', {
                currentCustomer: this.currentCustomer,
                currentCompany: this.currentCompany,
                workerId: this.workerId,
                captureIdx: this.captureIdx,
                metadataID: this.metadataID,
                assessmentIdx: this.assessmentIdx,
                motion: risk,
                cursorTime: this.requestStartTime,
                startTime: this.requestStartTime,
                endTime: this.requestEndTime,
              })
            } catch (error) {
              console.log('error loading graph data')
              console.log(error)
              throw new Error(error.message)
            }
          })
        )
      }
      this.loadingGraph = false
    },

    async getCaptureStartEndTimes() {
      try {
        let capture = await this.$store.dispatch('getCapture', {
          currentCustomer: this.currentCustomer,
          currentCompany: this.currentCompany,
          workerId: this.workerId,
          captureIdx: this.captureIdx,
        })

        let captureStartSeconds = capture.actualStart ? capture.actualStart : capture.start
        let captureEndSeconds = capture.end

        this.captureStart = parseFloat(
          (captureStartSeconds * constants.MILLISECONDS_TO_SECONDS).toFixed(2)
        )
        this.captureEnd = parseFloat(
          (captureEndSeconds * constants.MILLISECONDS_TO_SECONDS).toFixed(2)
        )
      } catch (error) {
        console.log('error loading Assessment Dates')
        console.log(error)
        throw new Error(error.message)
      }
    },

    reassessmentInProgress() {
      return (this.assessmentProgress = true)
    },

    async checkAssessmentProgress() {
      try {
        this.assessmentProgress = await this.$store.dispatch(
          'getAssessmentProgress',
          this.captureIdx
        )
      } catch (error) {
        console.log('error loading Assessment Dates')
        console.log(error)
        throw new Error(error.message)
      }
    },

    async refreshAssessment() {
      this.refreshingAssessment = true
      await this.checkAssessmentProgress()
      if (!this.assessmentProgress) {
        await Promise.all([
          this.getManifest(), // refresh assessment start and end times
          this.getRepairs(),
        ])
      }
      this.refreshingAssessment = false
    },

    updateEditorOption(option) {
      this.editorOption = option
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';
.sz-Assessment {
  &-error {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 25vh;

    &-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      &-image {
        max-width: 5rem;
        padding-bottom: 0.5rem;
      }

      &-title {
        @extend %font-content;
        color: $color-white;
        padding-top: 0.5rem;
        padding-bottom: 1.5rem;
        font-size: 32px;
        font-weight: 1000;
      }

      &-message {
        @extend %font-content;
        color: $color-white;
        font-size: 25px;
        font-weight: normal;
        white-space: pre-wrap;
      }
    }
  }

  display: flex;
  flex-direction: column;
  min-height: 39.5rem;
  height: 100%;
  position: relative;
  overflow: hidden;

  &-popup {
    background-color: $color-dropdown-background;
    white-space: pre-wrap;
    position: absolute;
    z-index: 1;
    padding: 1rem;
    height: 5.5rem;
    min-width: 16rem;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    left: 38%;
    top: 38%;

    &-button {
      margin-top: 1rem;
      @extend %button-layout;
      @extend %font-topbar-heading;
      background-color: $color-lifebooster-light-green;
      padding: 0.5rem 1rem;
      border-radius: 0;
    }
  }

  &-blur {
    filter: blur(0.25rem) brightness(50%);
    pointer-events: none;
    user-select: none;
  }

  &-loading {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 34px;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    filter: blur(1px);
  }

  &-top {
    display: flex;
    flex-direction: column;

    &-slideout {
      position: relative;
    }
  }

  &-panels {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    justify-content: space-between;

    &-graph {
      display: flex;
      flex-direction: column;
      flex-grow: 2;
      padding: 0.75rem;
      height: 100%;
      max-width: 80vw;
    }

    &-repair {
      height: 90%;
      width: 94vw;
    }

    &-editorOptions {
      padding: 0 0 0.5rem 3.6rem;
    }
  }

  &-characterPanel {
    width: 17rem;
  }

  &-chartsPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-loading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 34px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      opacity: 0.5;
      filter: blur(1px);
      width: 50%;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
