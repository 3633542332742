<template lang="pug">
    div(class="sz-AssessmentDialogBox")
        div(class="sz-AssessmentDialogBox-container")
            div(class="sz-AssessmentDialogBox-title")
                span(class="sz-AssessmentDialogBox-title-text") {{ $t(`motionAssessment.assessmentLog`) }}
            div(class="sz-AssessmentDialogBox-box")
                div(
                    v-for="message of messages"
                    class="sz-AssessmentDialogBox-box-item")
                    span(class="sz-AssessmentDialogBox-box-item-text") {{ message }}
</template>

<script>
    import EventBus from 'src/eventBus'
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'

    export default {
        name: "AssessmentDialogBox",

        props: {
            loadingGraph: {
                required: true,
                type: Boolean,
            },

            selectedRisks: {
                required: true,
                type: Array,
            },
        },

        data () {
            return {
                messages: [],
            }
        },

        computed: {
            ...mapGetters([
                'selectedModule',
            ]),
        },

        mounted () {
            EventBus.$on('UPDATE_DIALOG_BOX', (pointDialogs) => {
                this.setMessages(pointDialogs)
            })
        },

        beforeDestroy () {
            EventBus.$off('UPDATE_DIALOG_BOX')
        },

        methods: {
            setMessages (pointDialogs) {
                this.messages = []
                for (let i = 0; i < this.selectedRisks.length; i++) {
                    if (pointDialogs[i].length > 0) {
                        this.messages.push(this.$t(`riskTypes.${this.selectedRisks[i]}`) + ':')
                        this.messages.push(...this.parseEventDescriptions(pointDialogs[i]))
                    }
                }
            },

            parseEventDescriptions (description) {
                let messages = []
                for (let code of description) {
                    let sections = code.split("-")
                    if (sections.length > 0) {
                        let message
                        switch (this.selectedModule) {
                            case constants.RISK_MODULES.MOTION:
                                message = this.parseMotion(sections)
                                messages.push(message)
                                break
                            case constants.RISK_MODULES.POSTURE:
                                message = this.parsePosture(sections)
                                messages.push(message.timeAboveThreshString, message.dutyCycleString, message.MVCLString, message.MVCOString)
                                break
                            case constants.RISK_MODULES.VIBRATION:
                                message = this.parseVibration(sections)
                                messages.push(message.yellow,message.orange,message.red,message.standard)
                                break
                            case constants.RISK_MODULES.THERMAL:
                                message = this.parseThermal(sections)
                                messages.push(message.activityIndexStrings, message.workRestPerStrings, message.standardStrings)
                                break
                            default:
                                break
                        }

                    }
                }
                return messages
            },

            parseMotion (sections) {
                const eventConditions = sections[constants.EVENT_DESCRIPTION_INDICES.MOTION.CONDITION]
                const riskLevel = sections[constants.EVENT_DESCRIPTION_INDICES.MOTION.RISK_LEVEL]
                const count = sections[constants.EVENT_DESCRIPTION_INDICES.MOTION.COUNT]
                const standard = sections[constants.EVENT_DESCRIPTION_INDICES.MOTION.STANDARD_CODE]

                const decoded = this.decodeMotionEvent(eventConditions, riskLevel, standard)
                return `${decoded.eventConditionsString} ${decoded.riskLevelString} ${decoded.standardString}  #${count}`
            },

            parsePosture (sections) {
                const timeAboveThresh = sections[constants.EVENT_DESCRIPTION_INDICES.POSTURE.TIME_ABOVE_THRESHOLD]
                const dutyCycle = sections[constants.EVENT_DESCRIPTION_INDICES.POSTURE.DUTY_CYCLE]
                const MVCL = sections[constants.EVENT_DESCRIPTION_INDICES.POSTURE.MVCL]
                const MVCO = sections[constants.EVENT_DESCRIPTION_INDICES.POSTURE.MVCO]

                const decoded = this.decodePostureEvent(timeAboveThresh, dutyCycle, MVCL, MVCO)
                return {
                    timeAboveThreshString: decoded.timeAboveThreshString,
                    dutyCycleString: decoded.dutyCycleString,
                    MVCLString: decoded.MVCLString,
                    MVCOString: decoded.MVCOString,
                }
            },

            parseThermal (sections) {
                const activityIndex = sections[constants.EVENT_DESCRIPTION_INDICES.THERMAL.ACTIVITY]
                const workRestPer = sections[constants.EVENT_DESCRIPTION_INDICES.THERMAL.WORK_REST_PER]
                const standard = sections[constants.EVENT_DESCRIPTION_INDICES.THERMAL.STANDARD_CODE]

                const decoded = this.decodeThermalEvent(activityIndex, workRestPer, standard)
                return {
                    activityIndexStrings: decoded.activityIndexStrings,
                    workRestPerStrings: decoded.workRestPerStrings,
                    standardStrings: decoded.standardStrings,
                }
            },

            parseVibration (sections) {
                let yellowCount = ''
                let orangeCount = ''
                let redCount = ''
                let standard = ''

                if (this.$i18n) {
                    yellowCount = this.$t(`vibrationAssessment.riskCount.yellow`,
                        {count: sections[constants.EVENT_DESCRIPTION_INDICES.VIBRATION.YELLOW_COUNT] || 0 })
                    orangeCount = this.$t(`vibrationAssessment.riskCount.orange`,
                        {count: sections[constants.EVENT_DESCRIPTION_INDICES.VIBRATION.ORANGE_COUNT] || 0 })
                    redCount = this.$t(`vibrationAssessment.riskCount.red`,
                        {count: sections[constants.EVENT_DESCRIPTION_INDICES.VIBRATION.RED_COUNT] || 0 })
                    standard = this.$t(`vibrationAssessment.standard.${sections[constants.EVENT_DESCRIPTION_INDICES.VIBRATION.STANDARD_CODE]}`)
                }

                return {
                    yellow: yellowCount,
                    orange: orangeCount,
                    red: redCount,
                    standard: standard,
                }

            },

            decodeThermalEvent (activityIndex, workRestPer, standard) {
                let decodedStrings = {
                    activityIndexStrings: '',
                    workRestPerStrings: '',
                    standardStrings: '',
                }
                if (this.$i18n) {
                    decodedStrings.standardStrings = this.$t(`thermalAssessment.standard.${standard}`)
                    decodedStrings.activityIndexStrings = `${this.$t(`thermalAssessment.dialogBoxHeaders.workload`)}: ${this.$t(`thermalAssessment.activityIndex.${activityIndex}`)}`
                    decodedStrings.workRestPerStrings = `${this.$t(`thermalAssessment.dialogBoxHeaders.workRestPer`)}: ${this.$t(`thermalAssessment.workRestPerIndex.${workRestPer}`)}`
                }

                return decodedStrings
            },

            decodeMotionEvent (eventConditions, riskLevel, standard) {
                let decodedStrings = {
                    eventConditionsString: '',
                    riskLevelString: '',
                    standardString: '',
                }
                if (this.$i18n) {
                    // for some reason i18n is sometimes undefined when called from mounted
                    // and I couldn't figure out why. Sorry if this causes any issues down
                    // the line :( blame me for it haha
                    decodedStrings.eventConditionsString = this.$t(`motionAssessment.eventConditions.${eventConditions}`)
                    decodedStrings.riskLevelString = this.$t(`motionAssessment.riskColor.${riskLevel}`)
                    decodedStrings.standardString = this.$t(`motionAssessment.riskEventCode.${standard}`)
                }

                return decodedStrings
            },

            decodePostureEvent (timeAboveThresh, dutyCycle, MVCL, MVCO) {
                let decodedStrings = {
                    timeAboveThreshString: '',
                    dutyCycleString: '',
                    MVCLString: '',
                    MVCOString: '',
                }
                if (this.$i18n) {
                    decodedStrings.timeAboveThreshString = `${this.$t(`postureAssessment.timeAboveThresh`)} = ${timeAboveThresh} ${this.$t(`postureAssessment.seconds`)}`
                    decodedStrings.dutyCycleString = `${this.$t(`postureAssessment.dutyCycle`)} = ${dutyCycle}%`
                    decodedStrings.MVCLString = `${this.$t(`postureAssessment.MVCL`)} = ${MVCL}`
                    decodedStrings.MVCOString = `${this.$t(`postureAssessment.MVCO`)} = ${MVCO}`
                }
                return decodedStrings
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-AssessmentDialogBox {
        display: flex;
        margin: 0.5rem 0;

        &-container {
            background-color: $color-header-bar-backround;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0.5rem 0.7rem;
            width: 100%;
        }

        &-title {
            @extend %font-topbar-heading-unselectable;
            font-size: 12px;
            text-align: left
        }

        &-box {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            max-height: 100%;
            min-height: 0;
            overflow-y: auto;
            @include scrollbar-widget;

            &-item {
                display: flex;
                @extend %font-heading;
                font-weight: 400;
                font-size: 11px;
                cursor: default;
                padding: 0.3rem 0;
                width: 100%;

                &:hover {
                    background-color: $color-lifebooster-dark-green;
                }

                &-text {
                    text-align: left;
                    padding: 0.25rem;
                    white-space: pre-wrap;
                }
            }
        }
    }

</style>
