<template lang="pug">
    div(class="sz-CharacterViewerController")
        div(class="sz-CharacterViewerController-controls")
            div(
                @click.stop="clickBackButton",
                class="sz-CharacterViewerController-backButton")
                SvgIcon(
                    icon="rewind",
                    width="15",
                    height="15",
                    class="sz-CharacterViewerController-backButton-icon")
            div(
                @click.stop="clickPlayPauseButton",
                class="sz-CharacterViewerController-playPauseButton",
                v-if="showPlayPauseButton")
                div(
                    v-if="isPlaying",
                    class="sz-CharacterViewerController-playPauseButton-iconWrapper-pause")
                    SvgIcon(
                        icon="pause",
                        width="12",
                        height="12",
                        key="pause",
                        class="sz-CharacterViewerController-playPauseButton-icon")
                div(
                    v-else,
                    class="sz-CharacterViewerController-playPauseButton-iconWrapper-play")
                    SvgIcon(
                        icon="play",
                        width="23",
                        height="23",
                        key="play",
                        class="sz-CharacterViewerController-playPauseButton-icon")

            div(
                @click.stop="clickForwardButton",
                class="sz-CharacterViewerController-forwardButton")
                SvgIcon(
                    icon="fastforward",
                    width="15",
                    height="15",
                    class="sz-CharacterViewerController-forwardButton-icon")
</template>

<script>
import SvgIcon from 'components/Shared/SvgIcon'
import EventBus from 'src/eventBus'
import constants from 'helpers/constants'
import AssessmentService from 'services/assessment.service'
import constructModalSettings from 'mixins/modalSettings'
import formValidator from 'mixins/formValidator'

import MainLoop from 'mainloop.js'
import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'CharacterViewerController',

  components: {
    SvgIcon,
  },

  mixins: [constructModalSettings, formValidator],

  props: {
    assessmentStart: {
      required: true,
      type: Number,
    },

    assessmentEnd: {
      required: true,
      type: Number,
    },

    loadingGraph: {
      required: true,
      type: Boolean,
    },

    assessmentTimezoneOffset: {
      required: true,
      type: Number,
    },

    captureIdx: {
      type: [Number, String],
      required: true,
    },

    metadataID: {
      type: String,
      required: false,
    },

    assessmentIdx: {
      type: [Number, String],
      required: true,
    },

    indexKey: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      isPlaying: false,
      quatBufferArray: [],
      animationStartTimeStamp: 0,
      isBuffering: false,
      isRequestingData: false,
      characterViewerInUse: true,
      showConfirmModal: true,
    }
  },

  computed: {
    ...mapGetters(['workerId', 'currentCompany', 'currentCustomer', 'selectedModule']),

    assessmentStartInMilliseconds() {
      return this.assessmentStart * constants.UNIX_MILLISECONDS
    },

    assessmentEndInMilliseconds() {
      return this.assessmentEnd * constants.UNIX_MILLISECONDS
    },

    indexOfTimestampInBufferArray() {
      return this.quatBufferArray.findIndex((timestamp) => {
        if (timestamp && timestamp !== undefined) {
          return (
            timestamp[constants.ASSESSMENT_STREAM_INDICES.TIMESTAMP] *
              constants.UNIX_MILLISECONDS ===
            this.animationStartTimeStamp
          )
        }
      })
    },

    showPlayPauseButton() {
      return (
        this.selectedModule === constants.RISK_MODULES.MOTION ||
        this.selectedModule === constants.RISK_MODULES.POSTURE
      )
    },
  },

  mounted() {
    MainLoop.setSimulationTimestep(constants.CHARACTER_VIEWER_TIMESTEP)
      .setUpdate(this.updateCurrentTimestamp)
      .setDraw(this.drawCharacterViewer)

    this.animationStartTimeStamp = this.assessmentStartInMilliseconds

    EventBus.$on('UPDATE_ANIMATION_START_TIME', (startTime) => {
      this.animationStartTimeStamp = this.normalizeTimezoneFromChart(startTime)
    })

    EventBus.$on('USER_CONFIRMED', () => {
      this.showConfirmModal = false
      this.$emit('refreshGraph')
    })

    EventBus.$on('USER_CANCELLED', () => {
      this.showConfirmModal = true
    })
  },

  beforeDestroy() {
    EventBus.$off('UPDATE_ANIMATION_START_TIME')

    EventBus.$off('USER_CONFIRMED')

    EventBus.$off('USER_CANCELLED')
  },

  methods: {
    async clickPlayPauseButton() {
      if (this.characterViewerInUse) {
        if (!this.loadingGraph) {
          if (!this.isPlaying) {
            await this.startAnimation()
          } else {
            this.stopAnimation()
          }
        }
      }
    },

    async startAnimation() {
      if (this.animationStartTimeStamp >= this.assessmentEndInMilliseconds) {
        this.setCursorToStart()
      }
      EventBus.$emit('SET_DISABLE_CHART_INTERACTION', true)
      let success = true
      if (this.indexOfTimestampInBufferArray < 0) {
        // if the selected start timestamp is not inside the buffer array, discard the array
        // and create/populate a new one
        success = await this.createNewBufferArray()
        if (!success) {
          EventBus.$emit('SET_DISABLE_CHART_INTERACTION', false)
          this.openRefreshModal()
          return
        }
      }
      // set up the quat buffer array so that the selected start timestamp is at the correct
      // read index
      this.setUpQuatBufferArray()

      if (this.quatBufferArray.length < constants.MINIMUM_ANIMATION_ARRAY_LENGTH) {
        success = await this.populateArrayBuffer(constants.MINIMUM_ANIMATION_ARRAY_LENGTH)
      }

      if (!success) {
        EventBus.$emit('SET_DISABLE_CHART_INTERACTION', false)
        this.openRefreshModal()
        return
      }
      this.isPlaying = true
      await this.populateArrayBuffer(constants.MINIMUM_ANIMATION_ARRAY_LENGTH)
      MainLoop.start()
    },

    stopAnimation() {
      EventBus.$emit('SET_DISABLE_CHART_INTERACTION', false)
      MainLoop.stop()
      this.isPlaying = false
    },

    setCursorToStart() {
      EventBus.$emit('SET_DISABLE_CHART_INTERACTION', false)
      EventBus.$emit('UPDATE_ANNOTATION_POSITION', this.assessmentStart)
    },

    openRefreshModal() {
      if (this.showConfirmModal) {
        this.confirmModal(
          this.$t('motionAssessment.refreshPromptTitle'),
          this.$t('motionAssessment.refreshPromptMessage')
        )
      }
    },

    clickForwardButton() {
      if (!this.loadingGraph && !this.isPlaying) {
        EventBus.$emit('SET_ANNOTATION_TO_NEXT_EVENT')
      }
    },

    clickBackButton() {
      if (!this.loadingGraph && !this.isPlaying) {
        EventBus.$emit('SET_ANNOTATION_TO_PREVIOUS_EVENT')
      }
    },

    // Dev Note: This function behaves very similarly to populateArrayBuffer, but it uses a different value for the start time
    // This could be re-factored to be a single function but was not done because it is more worthwhile to do in the new UI
    async createNewBufferArray() {
      this.quatBufferArray = []
      this.isBuffering = true
      // the starting timestamp is equal to the selected timestamp minus the minimum array length
      // if the assessment covers that time, the start of the assessment if not.
      let startTimeStamp =
        this.animationStartTimeStamp - this.assessmentStartInMilliseconds <
        constants.MINIMUM_ANIMATION_ARRAY_LENGTH
          ? this.assessmentStartInMilliseconds
          : this.animationStartTimeStamp - constants.MINIMUM_ANIMATION_ARRAY_LENGTH
      // the end timestamp is the starting animation timestamp plus one chunk size
      let endTimeStamp = startTimeStamp + constants.CHUNK_SIZE

      // while the quat buffer array length is less than the minimum array length, add more chunks
      // to the array.
      if (endTimeStamp > this.assessmentEndInMilliseconds) {
        endTimeStamp = this.assessmentEndInMilliseconds
      }

      while (
        this.quatBufferArray.length < constants.MINIMUM_ANIMATION_ARRAY_LENGTH &&
        endTimeStamp <= this.assessmentEndInMilliseconds &&
        startTimeStamp < endTimeStamp
      ) {
        let chunk = await this.requestTimestampChunk(startTimeStamp, endTimeStamp)
        if (chunk.length === 0) {
          this.isBuffering = false
          return false
        }
        this.addChunkToQuatBufferArray(chunk)
        startTimeStamp += constants.CHUNK_SIZE
        endTimeStamp += constants.CHUNK_SIZE
      }
      this.isBuffering = false
      return true
    },

    setUpQuatBufferArray() {
      const differenceBetweenStartAndReadIndices =
        constants.ANIMATION_READ_INDEX - this.indexOfTimestampInBufferArray

      if (differenceBetweenStartAndReadIndices > 0) {
        const placeHolders = new Array(differenceBetweenStartAndReadIndices).fill(null)
        this.quatBufferArray.unshift(...placeHolders)
      }

      if (differenceBetweenStartAndReadIndices <= 0) {
        this.quatBufferArray = this.quatBufferArray.slice(differenceBetweenStartAndReadIndices * -1)
      }
    },

    addChunkToQuatBufferArray(chunk) {
      let lastTimestampInQuatBufferArray = this.assessmentStartInMilliseconds
      if (this.quatBufferArray.length > 0) {
        lastTimestampInQuatBufferArray =
          this.quatBufferArray[this.quatBufferArray.length - 1][
            constants.ASSESSMENT_STREAM_INDICES.TIMESTAMP
          ] * constants.UNIX_MILLISECONDS
      }
      // this constant covers boundary cases when the largest timestamp in the quatBufferArray is
      // not in the incoming chunk array and when it is the last element in the incoming chunk array.
      // If it is not in the chunk array, then indexOf() returns  -1, and since the slice method adds 1 to the
      // index, the slice would be from index 0 to the length of the chunk. If it is the last index in the
      // chunk array, then the slice will be from (chunk.length - 1) + 1 to chunk.length, and therefore
      // slice() will return an empty array and append that to the buffer array.
      const indexOfLastTimestampInChunkArray = chunk.findIndex((timestamp) => {
        return (
          timestamp[constants.ASSESSMENT_STREAM_INDICES.TIMESTAMP] * constants.UNIX_MILLISECONDS ===
          lastTimestampInQuatBufferArray
        )
      })
      this.quatBufferArray.push(...chunk.slice(indexOfLastTimestampInChunkArray + 1, chunk.length))
    },

    /**
            Requests and adds quats to the quat buffer array until the array is filled to the requested length
            If we cannot reach that requested length, then we exit this function early
             */
    async populateArrayBuffer(length) {
      this.isBuffering = true

      while (this.quatBufferArray.length < length) {
        let startTimeStamp =
          this.quatBufferArray[this.quatBufferArray.length - 1][
            constants.ASSESSMENT_STREAM_INDICES.TIMESTAMP
          ] * constants.UNIX_MILLISECONDS
        let endTimeStamp = startTimeStamp + constants.CHUNK_SIZE

        // Once we're at the end of the assessment, there is no more data to request
        // so we finish without reaching the requested length
        if (endTimeStamp > this.assessmentEndInMilliseconds) {
          endTimeStamp = this.assessmentEndInMilliseconds
          await this.requestAndAddChunkToQuatBufferArray(startTimeStamp, endTimeStamp)
          break
        }

        await this.requestAndAddChunkToQuatBufferArray(startTimeStamp, endTimeStamp)
      }

      this.isBuffering = false
      return true
    },

    async requestTimestampChunk(start, end) {
      this.isRequestingData = true
      let startTimeStamp = start / constants.UNIX_MILLISECONDS
      let endTimeStamp = end / constants.UNIX_MILLISECONDS
      let cursorTime = this.animationStartTimeStamp / constants.UNIX_MILLISECONDS
      let chunk = await AssessmentService.getStreamedAssessmentData(
        this.currentCustomer,
        this.currentCompany,
        this.workerId,
        this.captureIdx,
        this.assessmentIdx,
        startTimeStamp,
        endTimeStamp,
        constants.RISK_MODULE_API_NAME_MAP[this.selectedModule]
      )
      this.isRequestingData = false
      return chunk
    },

    async requestAndAddChunkToQuatBufferArray(start, end) {
      if (!this.isRequestingData) {
        let chunk = await this.requestTimestampChunk(start, end)
        this.addChunkToQuatBufferArray(chunk)
      }
    },

    normalizeTimezoneFromChart(timestamp) {
      let timestampInSeconds = timestamp / constants.UNIX_MILLISECONDS
      let timezoneInSeconds = this.assessmentTimezoneOffset * constants.SECONDS_IN_HOUR
      let posixMinusTimezone = timestampInSeconds - timezoneInSeconds
      return posixMinusTimezone * constants.UNIX_MILLISECONDS
    },

    convertTimestampToChartFormat(timestamp) {
      let timezoneInSeconds = this.assessmentTimezoneOffset * constants.SECONDS_IN_HOUR
      let posixMinusTimezone = timestamp + timezoneInSeconds
      return posixMinusTimezone * constants.UNIX_MILLISECONDS
    },

    /**
     * Updates the current timestamp that is meant to be emitted to the character viewer by
     * drawCharacterViewer.
     *
     * @param {Number} delta
     *   The amount of time since the last time the character viewer was updated, in milliseconds.
     */
    async updateCurrentTimestamp(delta) {
      // remove the first element of the array
      this.quatBufferArray.shift()

      // To allow for smooth playback, we should top up the quat buffer array since we
      // are constantly removing the first element of the array as clean-up
      if (
        this.quatBufferArray.length < constants.MINIMUM_ANIMATION_ARRAY_LENGTH &&
        !this.isBuffering
      ) {
        await this.populateArrayBuffer(constants.MINIMUM_ANIMATION_ARRAY_LENGTH)
      }
    },

    /**
     * Renders the character viewer with the timestamp data set by updateCurrentTimestamp.
     *
     * @param {Number} interpolationPercentage
     *   The amount of time since the last time the character viewer was updated, in seconds.
     */
    async drawCharacterViewer(interpolationPercentage) {
      let quatIndex =
        this.selectedModule === constants.ASSESSMENT_PAGE.POSTURE
          ? constants.ASSESSMENT_GRAPH_INDICES.POSTURE.QUAT_VALUES
          : constants.ASSESSMENT_GRAPH_INDICES.QUAT_VALUES
      let riskIndex =
        this.selectedModule === constants.ASSESSMENT_PAGE.POSTURE
          ? constants.ASSESSMENT_GRAPH_INDICES.POSTURE.RISK_TYPE_VALUES
          : constants.ASSESSMENT_GRAPH_INDICES.RISK_TYPE_VALUES

      let readIndex = constants.ANIMATION_READ_INDEX + 1

      let currentPoint = this.quatBufferArray[readIndex]
      let lastPoint = this.quatBufferArray[this.quatBufferArray.length - 1]

      if (
        !currentPoint ||
        !lastPoint ||
        currentPoint[constants.ASSESSMENT_STREAM_INDICES.TIMESTAMP] >=
          lastPoint[constants.ASSESSMENT_STREAM_INDICES.TIMESTAMP]
      ) {
        this.stopAnimation()

        // The assessment end may not line up exactly with the timestamp of the last piece of data
        // If they're within half a second, it's fair to assume that you are at the end of the assessment
        // and do not want to resume play
        // Dev Note: Ideally, we would like to stop right at the very end, but this should be a consideration for V2
        let distanceToEnd =
          this.assessmentEnd - currentPoint[constants.ASSESSMENT_STREAM_INDICES.TIMESTAMP]
        if (distanceToEnd >= 0.5) {
          // If the distance is greater than 0.5, the reason we cannot draw character viewer is due to some corruption
          // in the quat buffer array, calling start animation will purge the array and re-populate it
          // hopefully correcting the issue
          await this.startAnimation()
        }
      } else {
        let point = new Array(5)

        point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP] =
          this.quatBufferArray[readIndex][constants.ASSESSMENT_STREAM_INDICES.TIMESTAMP]
        point[quatIndex] =
          this.quatBufferArray[readIndex][constants.ASSESSMENT_STREAM_INDICES.QUAT_VALUES]
        point[riskIndex] =
          this.quatBufferArray[readIndex][constants.ASSESSMENT_STREAM_INDICES.RISK_TYPE_VALUES]

        // Dev Note: Firing this event when point is undefined potentially crashes the page
        // This is currently the behaviour, but it should be fixed in V2
        EventBus.$emit('UPDATE_CHARACTER_VIEWER', point)
        EventBus.$emit(
          'UPDATE_ANNOTATION_POSITION',
          this.convertTimestampToChartFormat(point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP])
        )

        this.animationStartTimeStamp =
          point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP] * constants.UNIX_MILLISECONDS
        // each draw needs to:
        // 1. emit the current timestamp data to the character viewer (current timestamp is arraybuffer[readindex])
        // 2. emit the current timestamp to the graph so that the crosshair will move accordingly
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~design';
.sz-CharacterViewerController {
  width: 100%;
  height: 3.83rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-controls {
    padding-top: 0.5rem;
    height: 1.5rem;
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: $color-unfocused-grey;
  }

  &-backButton {
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1.5rem;
    cursor: pointer;

    &:hover {
      background-color: $color-lifebooster-dark-green;
    }

    &-icon {
      margin-right: 0.2rem;
      margin-top: 0.2rem;
    }
  }

  &-playPauseButton {
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1.5rem;
    cursor: pointer;

    &:hover {
      color: $color-white;
      background-color: $color-lifebooster-dark-green;
    }

    &-icon {
      margin-top: 0.2rem;
    }
  }

  &-forwardButton {
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 1.5rem;
    cursor: pointer;

    &:hover {
      background-color: $color-lifebooster-dark-green;
    }

    &-icon {
      margin-left: 0.2rem;
      margin-top: 0.2rem;
    }
  }
}
</style>
