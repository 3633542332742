<template lang="pug">
    div(class="sz-MotionRiskTypeSelector")
        div(class="sz-MotionRiskTypeSelector-table")
            div(
                class="sz-MotionRiskTypeSelector-table-rows")
                div(
                    class= "sz-MotionRiskTypeSelector-table-rows-row",
                    v-for="header in rows",
                    :key="header.name")
                    div(class="sz-MotionRiskTypeSelector-table-rows-row-header") {{ header.name}}
                    div(
                        class= "sz-MotionRiskTypeSelector-table-rows-row",
                        :class="['sz-MotionRiskTypeSelector-table-rows-row-joint', {'selected': isSelected(jointRow.key)}]",
                        @click="selectRiskType(jointRow.key)",
                        v-for="jointRow in header.data",
                        :key="jointRow.name")
                        div(class="sz-MotionRiskTypeSelector-table-rows-row-joint-name") {{ jointRow.name }}
                        div(class="sz-MotionRiskTypeSelector-table-riskCount",
                            v-if="!isAssessmentEditor")
                            div(class="sz-MotionRiskTypeSelector-table-rows-row-joint-count") {{ jointRow.yellowRiskCount }}
                            div(class="sz-MotionRiskTypeSelector-table-rows-row-joint-count") {{ jointRow.orangeRiskCount }}
                            div(class="sz-MotionRiskTypeSelector-table-rows-row-joint-count") {{ jointRow.redRiskCount }}
                            div(class="sz-MotionRiskTypeSelector-table-rows-row-joint-count-latestRisk") {{ $t('motionAssessment.8hr') }}
                            div(class="sz-MotionRiskTypeSelector-table-rows-row-colors")
                                div(
                                    class= "sz-MotionRiskTypeSelector-table-rows-row-colors-risk motion"
                                    :class="`sz-MotionRiskTypeSelector-table-rows-row-colors-risk-${jointRow.roy} motion`")
                                div(class= "sz-MotionRiskTypeSelector-table-rows-row-colors-risk yellowRiskCount")
                                div(class= "sz-MotionRiskTypeSelector-table-rows-row-colors-risk orangeRiskCount")
                                div(class= "sz-MotionRiskTypeSelector-table-rows-row-colors-risk redRiskCount")
                                div(
                                    class= "sz-MotionRiskTypeSelector-table-rows-row-colors-risk latestRisk",
                                    :class="`sz-MotionRiskTypeSelector-table-rows-row-colors-risk-${jointRow.normalizedRisk}`")
</template>

<script>
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'
    import EventBus from 'src/eventBus'

    export default {
        name: 'MotionRiskTypeSelector',

        props: {
            assessment: {
                required: true,
                type: Object,
            },

            selectedRisks: {
                required: true,
                type: Array,
            },

            jointKeys: {
                required: true,
                type: Object,
            },
        },

        data () {
            return {
                cursorRisk: [],
            }
        },

        computed: {
            ...mapGetters([
                'selectedModule',
            ]),

            jointHeaders () {
                return Object.keys(constants.ERGO_RISK_JOINTS)
            },

            jointMotions () {
                return Object.keys(constants.MOTION_EVENT_COUNT_HEADERS.BACK)
            },

            rows () {
                if (!this.loadingAssessment) {
                    let rows = []
                    this.jointHeaders.map(joint => {
                        let jointRowsData = []
                        let motions = Object.values(constants['MOTION_EVENT_COUNT_HEADERS'][joint])
                        motions.map(motion => {
                            let motionDetails = {
                                name: this.$t(`riskTypesShortened.${motion}`),
                                key: motion,
                                normalizedRisk: this.generateRoy(motion),
                                roy: this.generateCursorRisk(motion),
                            }

                            if (motion !== constants.ERGO_RISK_TYPES.PELVIS_FLEX) {
                                motionDetails.yellowRiskCount = this.getRiskCount(
                                    constants.EVENT_TYPES.YELLOW,
                                    motion
                                )
                                motionDetails.orangeRiskCount = this.getRiskCount(
                                    constants.EVENT_TYPES.ORANGE,
                                    motion
                                )
                                motionDetails.redRiskCount =  this.getRiskCount(
                                    constants.EVENT_TYPES.RED,
                                    motion
                                )
                            }

                            jointRowsData.push(motionDetails)
                        })

                        let jointRows = {
                            name: this.$t(
                                `riskAreas.motion.joints.${constants['ERGO_RISK_JOINTS'][joint]}`
                            ),
                            data: jointRowsData,
                        }
                        rows.push(jointRows)
                    })

                    return rows
                }
                return null
            },

            isAssessmentEditor() {
                return this.selectedModule === constants.ASSESSMENT_PAGE.EDITOR
            },
        },

        mounted () {
            EventBus.$on('UPDATE_RISK_SELECTOR', this.updateRisk)
        },

        beforeDestroy () {
            if (!this.isAssessmentEditor) {
                EventBus.$emit('CLEAR_RISK_TYPES')
            }

            EventBus.$off('UPDATE_RISK_SELECTOR')
        },

        methods: {
            getRiskCount (eventType, jointMotion) {
                return (
                    this.assessment.RiskTypes.find(
                        risk => risk.name === jointMotion
                    )[eventType] || 0
                )
            },

            updateRisk (risk) {
                this.cursorRisk = risk
            },

            generateCursorRisk (jointMotion) {
                let jointKey = this.jointKeys[jointMotion]
                return this.cursorRisk[jointKey]
            },

            generateRoy (motion) {
                let assessmentRisk = this.assessment.RiskTypes.find(
                    risk => risk.name === motion
                )

                if (assessmentRisk === undefined) return undefined

                if (assessmentRisk.R || assessmentRisk.r) return constants.RISK_SCORES.RED
                if (assessmentRisk.o) return constants.RISK_SCORES.ORANGE
                if (assessmentRisk.y) return constants.RISK_SCORES.YELLOW
                return constants.RISK_SCORES.GREEN
            },

            selectRiskType (riskType) {
                this.$emit('updateSelectedRiskTypes', riskType)
            },

            isSelected(riskType) {
                return !!this.selectedRisks.find(r => r === riskType)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";
    .sz-MotionRiskTypeSelector {
        @include assessment-risk-panel;
    }
</style>
