<template lang="pug">
    div(class="sz-HeaderAssessmentInfo")
        div(class="sz-HeaderAssessmentInfo-section")
            div(class="sz-HeaderAssessmentInfo-section-leftGroup")
                div(class="sz-HeaderAssessmentInfo-section-leftGroup-date")
                    div(class="sz-HeaderAssessmentInfo-section-header") {{ currentCaptureDate.header }}
                    div(class="sz-HeaderAssessmentInfo-section-detail") {{ currentCaptureDate.detail  }}
                        div(
                            v-tooltip="timezoneTooltip",
                            id="timezone",
                            class="sz-HeaderAssessmentInfo-section-leftGroup-timezone")
                                SvgIcon(
                                    icon="globe",
                                    width="0.6rem",
                                    height="0.6rem",
                                    class="sz-HeaderAssessmentInfo-section-leftGroup-icons-globe")
                div(class="sz-HeaderAssessmentInfo-section-leftGroup-icons")
                    div(
                        class="sz-HeaderAssessmentInfo-section-leftGroup-icons-calendar",
                        @click.stop="toggleDateDropdown")
                        SvgIcon(
                            icon="calendar",
                            width="1rem",
                            height="1rem",
                            class="sz-HeaderAssessmentInfo-section-leftGroup-icons-calendar-icon")
                    div(
                        v-if="!isAssessmentEditor",
                        class="sz-HeaderAssessmentInfo-section-leftGroup-icons-download")
                        SvgIcon(
                            icon="download",
                            width="1rem",
                            height="1rem",
                            class="sz-HeaderAssessmentInfo-section-leftGroup-icons-download-icon")
                    div(v-if="showDatesDropdown && !loadingAssessment && !loadingGraph")
                        DropdownAssessments(
                            :allAssessmentDates="allAssessmentDates",
                            :assessmentTimezone="assessmentTimezone",
                            :captureIdx="captureIdx",
                            @closeDropdown="toggleDateDropdown",
                            @updateSelectedAssessment="updateSelectedAssessment")
            div(
                v-if="this.isAssessmentEditor",
                class="sz-HeaderAssessmentInfo-section-item-dcaStartEnd")
                EditorTime(
                    :captureStart="captureStart",
                    :captureEnd="captureEnd",
                    :assessmentStart="assessmentStart",
                    :assessmentEnd="assessmentEnd",
                    :assessmentTimezone="assessmentTimezone")
            div(
                v-else-if="this.isPosture",
                class="sz-HeaderAssessmentInfo-section-item-dcaStartEnd")
                    div(class="sz-HeaderAssessmentInfo-section-header") {{ dcaStartEndHeader }}
                    div(class="sz-HeaderAssessmentInfo-section-detail") {{ dcaStartEnd }}
            div(
                v-else,
                :class="`sz-HeaderAssessmentInfo-section-item-` + header.name",
                v-for="header in headers",
                :key="header.headerName")
                div(class="sz-HeaderAssessmentInfo-section-header") {{ header.headerName }}
                div(class="sz-HeaderAssessmentInfo-section-detail") {{ header.headerDetail }}
</template>

<script>
    import constants from 'helpers/constants'
    import SvgIcon from 'src/components/Shared/SvgIcon'
    import moment from 'moment-timezone'
    import { tooltip } from 'directives/tooltip'
    import { mapGetters } from 'vuex'
    import { ClickAway } from 'directives/clickAway'
    import motionAssessmentUtils from 'mixins/motionAssessment'
    import DropdownAssessments from './DropdownAssessments'
    import EditorTime from 'src/components/Assessment/Editor/EditorTime'

    export default {
        name: "HeaderAssessmentInfo",

        components: {
            SvgIcon,
            DropdownAssessments,
            EditorTime,
        },

        directives: {
            tooltip,
            ClickAway,
        },

        mixins: [
            motionAssessmentUtils,
        ],

        props: {
            loadingAssessment: {
                required: true,
                type: Boolean,
            },
            loadingGraph: {
                required: true,
                type: Boolean,
            },
            jointData: {
                required: true,
                type: Object,
            },
            assessmentStart: {
                required: true,
                type: Number,
            },
            assessmentEnd: {
                required: true,
                type: Number,
            },

            captureStart: {
                required: true,
                type: Number,
            },

            captureEnd: {
                required: true,
                type: Number,
            },

            assessmentDate: {
                required: true,
                type: [String, Number],
            },
            assessmentTimezone: {
                required: true,
                type: String,
            },
            captureIdx: {
                required: true,
                type: [String, Number],
            },
            allAssessmentDates: {
                required: true,
                type: Array,
            },
            humidity: {
                required: true,
                type: Number,
            },
        },

        data () {
            return {
                // instead of referring to moment-timezone globally, make one local instance of it
                // and add duration.format() plugin once. This instance is watched because
                // webpack or vue or something clears the plugin on hot reload, so it needs to be
                // re-instantiated every time it is null. This is not done in data because data can't
                // refer to 'this' and it's not computed because computed values can't have side effects
                moment: null,
                showDatesDropdown: false,
            }
        },

        computed: {
            ...mapGetters([
                'selectedModule',
            ]),

            headers () {
                return Object.values(constants.MOTION_ASSESSMENT_DETAILED_EVENT_COUNT_HEADERS).map((header) => {
                    let headerName = this.$t(`motionAssessment.detailedEventCountHeaders.${header}`)
                    let headerDetail
                    switch (true) {
                        case header === constants.MOTION_ASSESSMENT_DETAILED_EVENT_COUNT_HEADERS.DCA_START_END:
                            headerDetail  = this.dcaStartEnd
                            break
                        case header === constants.MOTION_ASSESSMENT_DETAILED_EVENT_COUNT_HEADERS.DURATION_WITHIN:
                            headerDetail = this.durationWithin
                            break
                        case header === constants.MOTION_ASSESSMENT_DETAILED_EVENT_COUNT_HEADERS.DURATION_ABOVE_POS:
                            if (this.hasPositiveThreshold) {
                                headerName = `${headerName} ${this.positiveThreshold}°`
                                headerDetail = this.positiveDurationFormatted
                            }
                            break
                        case header === constants.MOTION_ASSESSMENT_DETAILED_EVENT_COUNT_HEADERS.DURATION_ABOVE_NEG:
                            if (this.hasNegativeThreshold) {
                                headerName = `${headerName} ${this.negativeThreshold}°`
                                headerDetail = this.negativeDurationFormatted
                            }
                            break
                        case header === constants.MOTION_ASSESSMENT_DETAILED_EVENT_COUNT_HEADERS.HUMIDITY:
                            if (this.selectedModule === constants.RISK_MODULES.THERMAL) {
                                headerDetail = this.humidity
                            }
                            break
                        case header === constants.MOTION_ASSESSMENT_DETAILED_EVENT_COUNT_HEADERS.A8:
                            if (this.selectedModule === constants.RISK_MODULES.VIBRATION) {
                                headerDetail = parseFloat(this.jointData.A8.toFixed(4))
                            }
                            break
                        default:
                            headerDetail = undefined
                    }
                    return {
                        headerName,
                        headerDetail,
                    }
                }).filter(header => header.headerDetail !== undefined)
            },

            dcaStartEndHeader () {
                return this.$t(`motionAssessment.detailedEventCountHeaders.${constants.MOTION_ASSESSMENT_DETAILED_EVENT_COUNT_HEADERS.DCA_START_END}`)
            },

            dcaStartEnd () {
                return `${moment.tz((this.assessmentStart * constants.UNIX_MILLISECONDS), this.assessmentTimezone).format('LTS')} -
                ${moment.tz((this.assessmentEnd * constants.UNIX_MILLISECONDS), this.assessmentTimezone).format('LTS')}`
            },

            durationWithin () {
                if (this.selectedModule === constants.RISK_MODULES.THERMAL ||
                    this.selectedModule === constants.RISK_MODULES.VIBRATION) {
                        return undefined
                    }
                if (this.moment) {
                    return this.generateDurationWithin(this.jointData)
                }
                return 0
            },

            positiveAboveDuration () {
                if (this.moment) {
                    return this.generateDurationExceeded(this.jointData, constants.ERGO_THRESHOLD_TYPES.POSITIVE)
                }
                return 0
            },

            negativeAboveDuration () {
                if (this.moment) {
                    return this.generateDurationExceeded(this.jointData, constants.ERGO_THRESHOLD_TYPES.NEGATIVE)
                }
                return 0
            },


            positiveDurationFormatted () {
                if (this.moment) {
                    return this.moment
                        .duration(this.positiveAboveDuration, 'seconds')
                        .format('h:mm:ss')
                }
                return 0
            },

            negativeDurationFormatted () {
                if (this.moment) {
                    return this.moment
                        .duration(this.negativeAboveDuration, 'seconds')
                        .format('h:mm:ss')
                }
                return 0
            },

            currentCaptureDate () {
                return {
                    header: this.$t(`motionAssessment.detailedEventCountHeaders.date`),
                    detail: moment.tz((this.assessmentDate * constants.UNIX_MILLISECONDS), this.assessmentTimezone).format("MMM D, YYYY"),
                }
            },

            timezoneTooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'left',
                            padding: 'extra-padding',
                        },
                        title: this.assessmentTimezone,
                    },

                    body: {
                        placement: 'bottom-left',
                        offset: '120, 10',
                    },
                    allowHover: true,
                }
            },

            eventTypes () {
                return [
                    constants.EVENT_TYPES.YELLOW,
                    constants.EVENT_TYPES.ORANGE,
                    constants.EVENT_TYPES.RED,
                ]
            },

            isAssessmentEditor () {
                return this.selectedModule === constants.ASSESSMENT_PAGE.EDITOR
            },

            isPosture () {
                return this.selectedModule === constants.ASSESSMENT_PAGE.POSTURE
            },
        },

        watch: {
            moment () {
                if (!this.moment) {
                    this.addFormatToMomentDuration()
                }
            },
        },

        mounted () {
            this.addFormatToMomentDuration()
        },

        methods: {
            generateDurationExceeded (riskInfo, thresholdType) {
                let duration = 0
                if (riskInfo) {
                    if (thresholdType === constants.ERGO_THRESHOLD_TYPES.POSITIVE) {
                        if (riskInfo.positiveDuration) {
                            return riskInfo.positiveDuration
                        }
                    } else {
                        if (riskInfo.negativeDuration) {
                            return riskInfo.negativeDuration
                        }
                    }
                }
                return duration
            },

            generateDurationWithin (riskInfo) {
                let seconds = riskInfo.withinDuration
                return this.moment.duration(seconds, 'seconds').format('h:mm:ss')
            },

            addFormatToMomentDuration () {
                let moment = require('moment-timezone')
                let momentDurationFormatSetup = require("moment-duration-format")
                momentDurationFormatSetup(moment)
                this.moment = moment
            },

            toggleDateDropdown () {
                this.showDatesDropdown = !this.showDatesDropdown
            },

            updateSelectedAssessment (captureID, assessmentID, date) {
                this.showDatesDropdown = false
                this.$emit('updateSelectedAssessment', captureID, assessmentID, date)
            },
        },
    }

</script>

<style lang="scss" scoped>
    @import "~design";
    .sz-HeaderAssessmentInfo {
        @extend %info-bar;
        &-section {
            display: flex;
            width: 100%;
            padding: 1rem 2rem;
            flex-direction: row;
            justify-content: space-between;

            &-leftGroup {
                display: flex;
                &-date {
                    text-align: start;
                    display: flex;
                    flex-direction: column;
                }

                &-icons {
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    padding: 2rem 1rem;
                    color: $color-base-grey;

                    &-calendar,
                    &-download {
                        margin-right: 1rem;
                    }

                    &-globe {
                        margin-left: 0.2rem;
                    }
                }
            }

            &-header {
                @extend %info-bar-heading;
            }

            &-item{
                &-dcaStartEnd {
                    margin-right: auto;
                    margin-left: auto;
                    padding-right: 14rem;
                }
            }

            &-detail {
                @extend %font-heading;
                display: flex;
                align-items: baseline;
            }
        }
    }
</style>
