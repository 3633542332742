<template lang="pug">
    div(class="sz-ThermalRiskTypeSelector")
        div(class="sz-ThermalRiskTypeSelector-table")
            div(class="sz-ThermalRiskTypeSelector-table-rows")
                div(
                    class= "sz-ThermalRiskTypeSelector-table-rows-row",
                    :class="['sz-ThermalRiskTypeSelector-table-rows-row-joint', {'selected': isSelected(jointRow.key)}]",
                    @click="selectRiskType(jointRow.key)")
                    div(class="sz-ThermalRiskTypeSelector-table-rows-row-joint-name") {{ jointRow.name }}
                    div(class="sz-ThermalRiskTypeSelector-table-riskCount")
                        div(class="sz-ThermalRiskTypeSelector-table-rows-row-joint-count") {{ jointRow.yellowRiskCount }}
                        div(class="sz-ThermalRiskTypeSelector-table-rows-row-joint-count") {{ jointRow.orangeRiskCount }}
                        div(class="sz-ThermalRiskTypeSelector-table-rows-row-joint-count") {{ jointRow.redRiskCount }}
                        div(class="sz-ThermalRiskTypeSelector-table-rows-row-joint-count-latestRisk") {{ $t('motionAssessment.8hr') }}
                        div(class="sz-ThermalRiskTypeSelector-table-rows-row-colors")
                            div(
                                class= "sz-ThermalRiskTypeSelector-table-rows-row-colors-risk motion"
                                :class="`sz-ThermalRiskTypeSelector-table-rows-row-colors-risk-${jointRow.roy} motion`")
                            div(class= "sz-ThermalRiskTypeSelector-table-rows-row-colors-risk yellowRiskCount")
                            div(class= "sz-ThermalRiskTypeSelector-table-rows-row-colors-risk orangeRiskCount")
                            div(class= "sz-ThermalRiskTypeSelector-table-rows-row-colors-risk redRiskCount")
                            div(
                                class= "sz-ThermalRiskTypeSelector-table-rows-row-colors-risk latestRisk",
                                :class="`sz-ThermalRiskTypeSelector-table-rows-row-colors-risk-${jointRow.normalizedRisk}`")
</template>

<script>
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'
    import EventBus from 'src/eventBus'

    export default {
        name: 'ThermalRiskTypeSelector',

        props: {
            assessment: {
                required: true,
                type: Object,
            },

            selectedRisks: {
                required: true,
                type: Array,
            },
        },

        data () {
            return {
                cursorRisk: [],
            }
        },

        computed: {
            ...mapGetters([
                'selectedModule',
            ]),

            jointRow () {
                if (!this.loadingAssessment) {
                    let risk = this.assessment.RiskTypes[0]
                    return {
                        name: this.$t(`riskTypesShortened.${risk.name}`),
                        key: risk.name,
                        normalizedRisk: this.generateRoy(),
                        roy: this.cursorRisk[0],
                        yellowRiskCount: risk.yellowRiskCount || 0,
                        orangeRiskCount: risk.orangeRiskCount || 0,
                        redRiskCount: risk.redRiskCount || 0,
                    }
                }
                return null
            },
        },

        mounted () {
            EventBus.$on('UPDATE_RISK_SELECTOR', this.updateRisk)
        },

        beforeDestroy () {
            EventBus.$emit('CLEAR_RISK_TYPES')
            EventBus.$off('UPDATE_RISK_SELECTOR')
        },

        methods: {
            selectRiskType (riskType) {
                this.$emit('updateSelectedRiskTypes', riskType)
            },

            updateRisk (risk) {
                this.cursorRisk = risk
            },

            generateRoy () {
                let assessmentRisk = this.assessment.RiskTypes[0]

                if (assessmentRisk.R || assessmentRisk.r) return constants.RISK_SCORES.RED
                if (assessmentRisk.o) return constants.RISK_SCORES.ORANGE
                if (assessmentRisk.y) return constants.RISK_SCORES.YELLOW
                return constants.RISK_SCORES.GREEN
            },

            isSelected(riskType) {
                return !!this.selectedRisks.find(r => r === riskType)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";
    .sz-ThermalRiskTypeSelector {
        @include assessment-risk-panel;
    }
</style>
