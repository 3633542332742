<template lang="pug">
    div(class="sz-EditorTime")
        div(
            :class="`sz-EditorTime-section-item-` + headers.name")
            div(class="sz-EditorTime-section-header") {{ headers.headerName }}
            div(class="sz-EditorTime-section-detail")
                div(class="sz-EditorTime-section-detail-edit",
                    :class="{'sz-EditorTime-section-detail-edit-active': isEditTime}",
                    v-click-away="closeEditMode")
                    div(class="sz-EditorTime-section-detail",
                        :class="'sz-EditorTime-section-detail-edit-time-' + (isTimeInputTypeSelected(start) ? 'active' : 'inactive') ") {{ dcaStartEndHeaderFormat.start }}
                        div(v-if="isEditTime",
                            @click.stop="toggleTimeInput(start)")
                            SvgIcon(
                                icon="clock",
                                width="0.8rem",
                                height="0.8rem",
                                class="sz-EditorTime-section-detail-edit-icon-clock",
                                :class="{'sz-EditorTime-section-detail-edit-icon-clock-active': isTimeInputTypeSelected(start)}")
                    div() {{ '\u00A0' }} - {{ '\u00A0' }}
                    div(class="sz-EditorTime-section-detail",
                        :class="'sz-EditorTime-section-detail-edit-time-' + (isTimeInputTypeSelected(end) ? 'active' : 'inactive') ") {{ dcaStartEndHeaderFormat.end }}
                        div(v-if="isEditTime",
                            @click.stop="toggleTimeInput(end)")
                            SvgIcon(
                                icon="clock",
                                width="0.8rem",
                                height="0.8rem",
                                class="sz-EditorTime-section-detail-edit-icon-clock",
                                :class="{'sz-EditorTime-section-detail-edit-icon-clock-active': isTimeInputTypeSelected(end)}")
                TimeInput(v-if="openTimeInput"
                    @closeTimeInput="closeTimeInput",
                    @selectedInput="selectedTimeInput",
                    :defaultTime="getEditorStartEndTime",
                    :minTime="minSelectedTime",
                    :maxTime="maxSelectedTime",
                    class="sz-EditorTime-section-detail-edit-input",
                    :class="{'sz-EditorTime-section-detail-edit-input-endTime': isEditingEndTime }")
                div(class="sz-EditorTime-section-detail",
                    @click.stop="toggleEditMode")
                    SvgIcon(
                        icon="edit",
                        width="1rem",
                        height="1rem",
                        class="sz-EditorTime-section-detail-edit-icon",
                        :class="{'sz-EditorTime-section-detail-edit-icon-active': isEditTime}")
                div(class="sz-EditorTime-section-detail",
                    v-show="isTimeChanged"
                    @click.stop="resetTime",
                    v-tooltip="resetTooltip")
                    SvgIcon(
                        icon="refresh",
                        width="1rem",
                        height="1rem",
                        class="sz-EditorTime-section-detail-edit-icon-reset")
</template>

<script>
    import constants from 'helpers/constants'
    import SvgIcon from 'src/components/Shared/SvgIcon'
    import moment from 'moment-timezone'
    import { mapGetters } from 'vuex'
    import { ClickAway } from 'directives/clickAway'
    import { tooltip } from 'directives/tooltip'
    import TimeInput from 'src/components/Shared/TimeInput'
    import EventBus from 'src/eventBus'
    import motionAssessmentUtils from 'mixins/motionAssessment'

    export default {
        name: "EditorTime",

        components: {
            SvgIcon,
            TimeInput,
        },

        directives: {
            ClickAway,
            tooltip,
        },

        mixins: [
            motionAssessmentUtils,
        ],

        props: {
            assessmentStart: {
                required: true,
                type: Number,
            },

            assessmentEnd: {
                required: true,
                type: Number,
            },

            assessmentTimezone: {
                required: true,
                type: String,
            },

            captureStart: {
                required: true,
                type: Number,
            },

            captureEnd: {
                required: true,
                type: Number,
            },
        },

        data () {
            return {
                openTimeInput: false,
                timeInputType: null,
                editTimeMode: false,
                time: {},
            }
        },

        computed: {
            headers () {
                return {
                    headerName: this.$t(`motionAssessment.detailedEventCountHeaders.dca start/end`) ,
                    name: 'dcaStartEnd',
                }
            },

            startEndMoment () {
                return {
                    start: moment.tz((this.time.start), this.assessmentTimezone),
                    end: moment.tz((this.time.end), this.assessmentTimezone),
                }
            },

            originalTime () {
                return {
                    start: moment.tz((this.assessmentStart * constants.UNIX_MILLISECONDS), this.assessmentTimezone),
                    end: moment.tz((this.assessmentEnd * constants.UNIX_MILLISECONDS), this.assessmentTimezone),
                }
            },

            dcaStartEndHeaderFormat () {
                return {
                    start: this.startEndMoment.start.format(constants.MOMENT_TIME_FORMAT.TIME),
                    end: this.startEndMoment.end.format(constants.MOMENT_TIME_FORMAT.TIME),
                }
            },

            isEditTime () {
                return this.editTimeMode === true
            },

            end () {
                return constants.EDITOR.END
            },

            start () {
                return constants.EDITOR.START
            },

            getEditorStartEndTime () {
                return (this.isEditingEndTime )
                    ? moment(this.startEndMoment.end).format(constants.MOMENT_TIME_FORMAT.DATE_TIME)
                    : moment(this.startEndMoment.start).format(constants.MOMENT_TIME_FORMAT.DATE_TIME)
            },

            isEditingEndTime () {
                return this.timeInputType === constants.EDITOR.END
            },

            minSelectedTime () {
                return (this.isEditingEndTime)
                    ? moment(this.startEndMoment.start).format(constants.MOMENT_TIME_FORMAT.DATE_TIME)
                    : moment.tz((this.captureStart * constants.UNIX_MILLISECONDS), this.assessmentTimezone).format(constants.MOMENT_TIME_FORMAT.DATE_TIME)
            },

            maxSelectedTime () {
                return (this.isEditingEndTime)
                    ? moment.tz((this.captureEnd * constants.UNIX_MILLISECONDS), this.assessmentTimezone).format(constants.MOMENT_TIME_FORMAT.DATE_TIME)
                    : moment(this.startEndMoment.end).format(constants.MOMENT_TIME_FORMAT.DATE_TIME)
            },

            isTimeChanged () {
                return this.isEditTime &&
                    (this.dcaStartEndHeaderFormat.start !== this.originalTime.start.format(constants.MOMENT_TIME_FORMAT.TIME) ||
                    this.dcaStartEndHeaderFormat.end !== this.originalTime.end.format(constants.MOMENT_TIME_FORMAT.TIME))
            },

            resetTooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'left',
                            padding: 'extra-padding',
                        },
                        title: this.$t('motionAssessment.editor.resetChanges'),
                    },

                    body: {
                        placement: 'bottom',
                    },
                    allowHover: true,
                }
            },
        },

        mounted() {
            this.time = {
                start: this.originalTime.start,
                end: this.originalTime.end,
            }

            EventBus.$on('UPDATE_ANNOTATION_POSITION_EDITOR', (params) => {
                this.setTime(params.timestamp, params.type)
            })
        },

        beforeDestroy() {
            EventBus.$off('UPDATE_ANNOTATION_POSITION_EDITOR')
        },

        methods: {
            isTimeInputTypeSelected (type) {
                return type === this.timeInputType || !this.editTimeMode
            },

            toggleTimeInput (type) {
                if (type === this.timeInputType) {
                    this.closeTimeInput ()
                } else {
                    this.openTimeInput = true
                    this.timeInputType = type
                }
            },

            closeTimeInput () {
                this.openTimeInput = false
                this.timeInputType = null
            },

            toggleEditMode () {
                this.editTimeMode = !this.editTimeMode
                if (!this.editTimeMode) this.openTimeInput = false
            },

            closeEditMode () {
                this.closeTimeInput()
                this.editTimeMode = false
            },

            setTime (time, type) {
                (type) ? this.time.end = time : this.time.start = time
            },

            resetTime () {
                this.timeInputType = this.start
                this.selectedTimeInput(this.assessmentStart, true)
                this.timeInputType = this.end
                this.selectedTimeInput (this.assessmentEnd, true)
                this.timeInputType = null
            },

            selectedTimeInput (timeInput, isInputUnix=false) {
                // Graph needs unix timestamp in ms but received input is a date string, therefore you need to convert before emitting.
                const time = (isInputUnix)
                    ? timeInput * constants.UNIX_MILLISECONDS
                    : moment.tz(timeInput, constants.MOMENT_TIME_FORMAT.DATE_TIME, this.assessmentTimezone).unix() * constants.UNIX_MILLISECONDS

                this.setTime(time, this.timeInputType)
                EventBus.$emit('SET_ANNOTATION_POSITION_EDITOR', {
                    timestamp: time,
                    type: this.timeInputType,
                })
            },

        },
    }

</script>

<style lang="scss" scoped>
    @import "~design";
    .sz-EditorTime {
        &-section {
            display: flex;
            width: 100%;
            padding: 1rem 2rem;
            flex-direction: row;
            justify-content: center;

            &-header {
                @extend %info-bar-heading;
            }

            &-detail {
                @extend %font-heading;
                display: flex;
                align-items: baseline;
                color: $color-white;

                &-edit {
                    display: flex;
                    margin-left: 1.2rem;
                    margin-top: 0.125rem;
                    padding: 0 0.25rem;

                    &-input {
                        position: absolute;
                        top: 4.10rem;
                        left: 34.25rem;
                        &-endTime {
                            left: 40.25rem;
                        }
                    }

                    &-time {
                        &-active {
                            color: $color-white;
                        }

                        &-inactive {
                            color: $color-unselected-text;
                        }
                    }
                    &-icon {
                        margin: 0 0.5rem;
                        color: $color-unselected-text;
                        cursor: pointer;

                        &-active {
                            color: $color-white;
                        }

                        &-clock {
                            margin-left: 0.5rem;
                            margin-bottom: 0.25rem;
                            color: $color-unselected-text;
                            cursor: pointer;

                            &-active {
                                color: $color-white;
                            }
                        }

                        &-reset {
                            color: $color-unselected-text;
                            cursor: pointer;

                            &:hover {
                                color: $color-white;
                            }
                        }
                    }

                    &-active {
                        border: 1px solid #4195fc;
                        box-shadow: 0px 0px 4px #4195fc;
                    }
                }
            }
        }
    }
</style>
