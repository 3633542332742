<template lang="pug">
    transition(name="fade")
        div(class="sz-Chart",
            v-click-away="unselectAnnotation",)
</template>

<script>
    import Anychart from 'anychart'
    import { generalSettings,
        thresholdSettings,
        riskSettings } from 'helpers/assessmentGraph'
    import { bSearch } from 'helpers/utilities'
    import { ClickAway } from 'directives/clickAway'
    import EventBus from 'src/eventBus'
    import motionAssessmentUtils from 'mixins/motionAssessment'
    import constants from 'helpers/constants'

    export default {
        name: 'EditorChartGroup',

        directives: {
            ClickAway,
        },

        mixins: [
            motionAssessmentUtils,
        ],

        props: {
            annotationPosition: {
                required: false,
                type: Number,
                default: undefined,
            },

            zoomStart: {
                required: false,
                type: Number,
                default: undefined,
            },

            zoomEnd: {
                required: false,
                type: Number,
                default: undefined,
            },

            graph: {
                required: true,
                type: Array,
            },

            loadingGraph: {
                required: true,
                type: Boolean,
            },

            assessmentTimezoneOffset: {
                required: true,
                type: Number,
            },

            assessmentStart: {
                required: true,
                type: Number,
            },

            assessmentEnd: {
                required: true,
                type: Number,
            },

            selectedRisks: {
                required: true,
                type: Array,
            },
        },

        data() {
            return {
                chart: null,
                options: null,
                table: null,
                mappings: {
                    bodyAngle: null,
                },
                series: {
                    bodyAngle: null,
                    quats: null,
                },
                bodyAngleAnnotation: [],
                riskAnnotation: null,
                dragging: false,
                disableChartInteraction: false,
                xTextMarker: null,
                selectedAnnotation: null,
            }
        },

        computed: {
            currentGraph () {
                let graph = false
                if (this.graph) {
                    graph = this.graph
                }
                return graph
            },
        },

        watch: {
            options: function (options) {
                if (!this.chart && options) {
                    this.init()
                } else {
                    this.chart.dispose()
                    this.chart = null
                    this.init()
                }
            },

            // if prop end/start times change, the annotators will be updated
            assessmentStart: function () {
                if (this.bodyAngleAnnotation[constants.EDITOR.START]) {
                    this.bodyAngleAnnotation[constants.EDITOR.START].xAnchor(this.assessmentStart)
                }
            },

            assessmentEnd: function () {
                if (this.bodyAngleAnnotation[constants.EDITOR.END]) {
                    this.bodyAngleAnnotation[constants.EDITOR.END].xAnchor(this.assessmentEnd)
                }
            },
        },

        mounted() {
            if (!this.chart) {
                this.init()
            }

            EventBus.$on('SET_ANNOTATION_POSITION_EDITOR', (params) => {
                this.selectedAnnotation = params.type
                this.setChartAnnotationsAnchors(this.normalizeTimezoneForChart(params.timestamp))
            })
        },


        beforeDestroy() {
            if (this.chart) {
                this.chart.dispose()
                this.chart = null
            }

            EventBus.$off('SET_ANNOTATION_POSITION_EDITOR', (params) => {
                this.selectedAnnotation = params.type
                this.setChartAnnotationsAnchors(this.normalizeTimezoneForChart(params.timestamp))
            })
        },

        methods: {
            delegateMethod(name, data) {
                if (!this.chart) {
                    warn(`Cannot call [$name] before the chart is initialized. Set prop [options] first.`, this)
                    return
                }
                return this.chart[name](data)
            },

            init() {
                if (!this.chart) {
                    Anychart.licenseKey(process.env.VUE_APP_ANYCHART_LICENSE)
                    // Timezone correction has already been performed in the main component, so we can set the offset to 0
                    // This is call ensures the initial page load displays the correct timestamps in the x-axis
                    Anychart.format.outputTimezone(0)
                    this.chart = Anychart.stock()
                    if (this.chart && this.currentGraph) {
                        this.addData()
                        this.addLicenseKey()
                        this.addMappings()
                        this.addSeries()
                        this.addGeneralSettings()
                        this.addTextMarkers()
                        this.addVerticalLine()
                        this.setZoomLevel()
                        this.addListeners()
                        this.addSeriesSettings()
                        this.chart.container(this.$el).draw()
                    }
                }
            },

            addData () {
                let table = Anychart.data.table(0)
                this.table = table.addData(this.currentGraph)
            },

            addLicenseKey () {
                let credits = this.chart.credits()
                credits.enabled(false)
            },

            addMappings () {
                this.mappings.bodyAngle = this.table.mapAs({
                    x: constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP,
                    value: constants.ASSESSMENT_GRAPH_INDICES.PRIMARY_VALUE})
                this.mappings.quats = this.table.mapAs({
                    x: constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP,
                    value: constants.ASSESSMENT_GRAPH_INDICES.QUATS})
            },

            addSeries () {
                this.series.bodyAngle = this.chart.plot().line(this.mappings.bodyAngle)

                this.series.bodyAngle.connectMissingPoints(true)
            },

            addGeneralSettings () {
                // it would be better to have all these settings in an object and then create the chart
                // using those settings as a JSON object instead of calling each individual method here, but unfortunately
                // AnyStock does not have support for this functionality at this point (v8.0.1).
                this.chart.width(generalSettings.chart.width)
                this.chart.height(generalSettings.chart.height)
                this.chart.padding(
                    generalSettings.chart.paddingTop,
                    generalSettings.chart.paddingRight,
                    generalSettings.chart.paddingBottom,
                    generalSettings.chart.paddingLeft)
                this.chart.background().fill(generalSettings.chart.background)
                this.chart.splitters().enabled(generalSettings.chart.splitters.enabled)
                this.chart.tooltip().enabled(generalSettings.chart.tooltip.enabled)
                this.chart.xScale().ticksCount(generalSettings.chart.xScale.ticks.length)

                const scroller = this.chart.scroller()
                scroller.enabled(generalSettings.chart.scroller.enabled)
                scroller.selectedFill(generalSettings.chart.scroller.selectedFill)
                scroller.fill(generalSettings.chart.scroller.fill)
                scroller.outlineStroke(generalSettings.chart.scroller.outlineStroke)
                scroller.xAxis(generalSettings.chart.scroller.xAxis.enabled)

                const anglePlot = this.chart.plot(0)

                anglePlot.dataArea().background().enabled(generalSettings.chart.dataArea.enabled)
                anglePlot.dataArea().background().fill(generalSettings.chart.dataArea.fill)

                this.chart.crosshair(false)

                anglePlot.height(generalSettings.chart.plots.anglePlot.height)

                let angleTitle = anglePlot.title()
                angleTitle.enabled(generalSettings.chart.plots.anglePlot.title.enabled)
                angleTitle.fontSize(generalSettings.chart.plots.anglePlot.title.fontSize)
                angleTitle.text(generalSettings.chart.plots.anglePlot.title.text)

                anglePlot.legend().enabled(true)
                anglePlot.legend().title().useHtml(true)
                anglePlot.legend().titleFormat(
                  "<span></span>"
                )
                anglePlot.legend().itemsFormat(
                  "{%seriesName}"
                )
                anglePlot.legend().align('right')
                anglePlot.legend().positionMode('inside')
                anglePlot.legend().itemsLayout('vertical-expandable')
                anglePlot.legend().tooltip(true)
                anglePlot.legend().textOverflow('...')
                anglePlot.legend().fontColor(generalSettings.chart.textMarker.fontColor)

                let angleXAxis = anglePlot.xAxis()
                let angleYAxis = anglePlot.yAxis()

                angleXAxis.labels().format(generalSettings.chart.crosshair.xLabel.format)
                angleXAxis.minorLabels().format(generalSettings.chart.crosshair.xLabel.format)
                angleXAxis.showHelperLabel(false)
                angleYAxis.labels().fontColor(generalSettings.chart.plots.anglePlot.yAxes[0].labels.fontColor)
                angleYAxis.labels().fontFamily(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontFamily)
                angleYAxis.labels().fontSize(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontSize)
                angleYAxis.labels().fontWeight(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontWeight)
                angleYAxis.stroke(generalSettings.chart.background)
                angleYAxis.ticks().stroke(generalSettings.chart.plots.anglePlot.xScale.fill)

                let angleYAxisTitle = angleYAxis.title()
                angleYAxis.title(this.stringConverter(generalSettings.chart.plots.anglePlot.yAxes[0].title.label).toUpperCase())
                angleYAxisTitle.fontSize(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontSize)
                angleYAxisTitle.fontWeight(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontWeight)
                angleYAxisTitle.fontFamily(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontFamily)
                angleYAxisTitle.fontColor(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontColor)
            },

            addVerticalLine () {
                this.bodyAngleAnnotation[constants.EDITOR.START] = this.chart.plot(0).annotations().verticalLine({
                    xAnchor: this.assessmentStart,
                })
                this.bodyAngleAnnotation[constants.EDITOR.END] = this.chart.plot(0).annotations().verticalLine({
                    xAnchor: this.assessmentEnd,
                })

                this.bodyAngleAnnotation[constants.EDITOR.START].allowEdit(generalSettings.chart.annotation.allowEdit)
                this.bodyAngleAnnotation[constants.EDITOR.END].allowEdit(generalSettings.chart.annotation.allowEdit)
                this.bodyAngleAnnotation[constants.EDITOR.START].stroke(generalSettings.chart.annotation.strokeColor, generalSettings.chart.annotation.strokeThicknessBold)
                this.bodyAngleAnnotation[constants.EDITOR.END].stroke(generalSettings.chart.annotation.strokeColor, generalSettings.chart.annotation.strokeThicknessBold)

                this.annotationPosition
                    ? this.setChartAnnotationsAnchors(this.annotationPosition)
                    : this.setChartAnnotationsAnchors(this.currentGraph[0].x)
            },

            addTextMarkers () {
                let zoomMarker = this.chart.plot(0).textMarker(1)
                zoomMarker.text(this.stringConverter(generalSettings.chart.textMarker.zoomMarker.label).toUpperCase())
                zoomMarker.rotation(generalSettings.chart.textMarker.zoomMarker.rotation)
                zoomMarker.offsetY(generalSettings.chart.textMarker.zoomMarker.offsetY)
                zoomMarker.offsetX(generalSettings.chart.textMarker.zoomMarker.offsetX)
                zoomMarker.anchor(generalSettings.chart.textMarker.zoomMarker.anchor)
                zoomMarker.align(generalSettings.chart.textMarker.zoomMarker.align)
                zoomMarker.fontSize(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontSize)
                zoomMarker.fontWeight(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontWeight)
                zoomMarker.fontColor(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontColor)
                zoomMarker.fontFamily(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontFamily)

                this.xTextMarker = this.chart.plot(0).textMarker(0)
                this.xTextMarker.axis(this.chart.plot(0).xAxis())
                this.xTextMarker.rotation(generalSettings.chart.textMarker.xMarker.rotation)
                this.xTextMarker.fontWeight(generalSettings.chart.textMarker.fontWeight)
                this.xTextMarker.fontFamily(generalSettings.chart.textMarker.fontFamily)
                this.xTextMarker.fontSize(generalSettings.chart.textMarker.xMarker.fontSize)
                this.xTextMarker.fontColor(generalSettings.chart.textMarker.fontColor)
                this.xTextMarker.hAlign(generalSettings.chart.textMarker.xMarker.hAlign)
                this.xTextMarker.anchor(generalSettings.chart.textMarker.xMarker.anchor)
                this.xTextMarker.offsetY(generalSettings.chart.textMarker.xMarker.offsetY)
                this.xTextMarker.background().enabled(generalSettings.chart.textMarker.xMarker.background.enabled)
                this.xTextMarker.background().fill(generalSettings.chart.textMarker.xMarker.background.fill, generalSettings.chart.textMarker.xMarker.background.opacity)
            },

            getNearestTimestamp (timestamp) {
                const selectable = this.mappings.bodyAngle.createSelectable()

                selectable.selectAll()
                const select = selectable.search(timestamp, "nearest")
                // return point date (in milliseconds)
                return select.getKey()
            },

            setZoomLevel () {
                if (this.zoomStart && this.zoomEnd) {
                    this.chart.selectRange(this.zoomStart, this.zoomEnd)
                }
            },

            applyChartAnnotationChanges (pointTimestamp) {
                this.bodyAngleAnnotation[this.selectedAnnotation].xAnchor(pointTimestamp)

                this.swapAnnotationsOrder()

                this.bodyAngleAnnotation[this.selectedAnnotation].stroke(generalSettings.chart.annotation.selectedAnnotationColor, generalSettings.chart.annotation.strokeThicknessBold)
                this.bodyAngleAnnotation[this.getOtherSelectedAnnotation()].stroke(generalSettings.chart.annotation.strokeColor, generalSettings.chart.annotation.strokeThicknessBold)
            },

            swapAnnotationsOrder () {
                // if end annotation is moved ahead of start annotation, the timestamps are reversed
                if (this.bodyAngleAnnotation[constants.EDITOR.END].xAnchor() < this.bodyAngleAnnotation[constants.EDITOR.START].xAnchor()) {
                    const temp = this.bodyAngleAnnotation[constants.EDITOR.END]
                    this.bodyAngleAnnotation[constants.EDITOR.END] = this.bodyAngleAnnotation[constants.EDITOR.START]
                    this.bodyAngleAnnotation[constants.EDITOR.START] = temp

                    this.selectedAnnotation = this.getOtherSelectedAnnotation()
                }
            },

            setChartAnnotationsAnchors (pointTimestamp) {
                if (this.chart) {
                    if (this.selectedAnnotation === constants.EDITOR.END || this.selectedAnnotation === constants.EDITOR.START) {
                        this.applyChartAnnotationChanges(pointTimestamp)
                        this.emitTime(pointTimestamp)
                         let searchedIndex = bSearch(
                        this.currentGraph, (point) => {
                            return point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP] < pointTimestamp}
                        )
                        let pointValues = this.currentGraph[searchedIndex]
                        pointValues[constants.ASSESSMENT_GRAPH_INDICES.RISK_TYPE_VALUES] = [] //disables risk colours at the character viewer
                        this.emitQuats(pointValues)
                        EventBus.$emit('UPDATE_ANIMATION_START_TIME', pointTimestamp)
                        EventBus.$emit('UPDATE_CURRENT_ANGLE_EDITOR', {
                            angle: pointValues[constants.ASSESSMENT_GRAPH_INDICES.PRIMARY_VALUE],
                            type: this.selectedAnnotation,
                        })
                    }
                }
            },

            addSeriesSettings () {
                this.series.bodyAngle.normal().stroke({
                    color: generalSettings.chart.plots.anglePlot.series.color,
                    thickness: generalSettings.chart.plots.anglePlot.series.thickness,
                })

                this.series.bodyAngle.name(this.$t(`distributionGraphLabels.riskType.${this.selectedRisks[0]}`))
            },

            getXValueFromEvent (event) {
                const x = event.offsetX
                // plot bounds related to chart container
                const plotWidth = this.chart.plot(0).getPixelBounds().width
                // plot left margin
                const plotLeftOffset = this.chart.plot(0).getPixelBounds().left
                // is click on data area
                if (x < plotLeftOffset || x > plotLeftOffset + plotWidth) {
                    return
                }
                // get date of click related to xScale
                const ratio = (x - plotLeftOffset) / plotWidth
                const xDate = this.chart.xScale().inverseTransform(ratio)
                // get data from clicked point

                const xValue = this.getNearestTimestamp(xDate)
                return xValue
            },

            isInYBounds (event) {
                const y = event.offsetY
                const plotTopOffset = this.chart.plot(0).getPixelBounds().top
                const plotBottomHeight = this.chart.plot(0).getPixelBounds().height
                const plotBottomOffset = this.chart.plot(0).getPixelBounds().top

                const plotHeight = plotBottomHeight + plotBottomOffset
                return y >= plotTopOffset && y <= plotHeight
            },


            addListeners () {
                let that = this
                this.chart.listen('mouseMove', function (event) {
                    that.mouseMoveHandler(event)
                })

                this.chart.listen('mouseDown', function (event) {
                    that.mouseDownHandler(event)
                })

                this.chart.listen('mouseUp', function () {
                    that.mouseUpHandler()
                })

                this.chart.listen('selectedrangechange', function (event) {
                    that.rangeChangeHandler(event)
                })
            },

            mouseMoveHandler (event) {
                if (!this.disableChartInteraction) {
                    let pointIndex = this.getXValueFromEvent(event)

                    if (pointIndex === undefined || !this.isInYBounds(event)) {
                        this.stopDragging()
                    }

                    if (this.dragging) {
                        this.setChartAnnotationsAnchors(pointIndex)
                        this.emitQuats(pointIndex)
                    }
                }
            },

            mouseDownHandler (event) {

                if (!this.disableChartInteraction && this.isInYBounds(event)) {
                    let pointIndex = this.getXValueFromEvent(event)

                    if (pointIndex) {
                        this.getSelectedAnnotation(pointIndex)
                        this.setChartAnnotationsAnchors(pointIndex)
                        this.startDragging()
                    }
                }

                if (!this.isInYBounds(event)) {
                    this.unselectAnnotation()
                }
            },

            mouseUpHandler () {
                if (this.dragging && !this.disableChartInteraction) {
                    this.stopDragging()
                }
            },

            roundTimeToSec (time) {
                return Math.round(time / 100000)
            },

            getOtherSelectedAnnotation () {
                if (this.selectedAnnotation === null) return

                return this.selectedAnnotation ? 0 : 1
            },

            getSelectedAnnotation (time) {
                // round to closest second to be able to easily select the annotation
                let roundedTime = this.roundTimeToSec(time)

                // select other annotation
                if (this.selectedAnnotation !== null
                    && roundedTime === this.roundTimeToSec(this.bodyAngleAnnotation[this.getOtherSelectedAnnotation()].xAnchor())) {
                        this.selectedAnnotation = this.getOtherSelectedAnnotation()
                    }

                if (roundedTime === this.roundTimeToSec(this.bodyAngleAnnotation[constants.EDITOR.START].xAnchor())) {
                    this.selectedAnnotation = constants.EDITOR.START
                    return
                }

                if (roundedTime === this.roundTimeToSec(this.bodyAngleAnnotation[constants.EDITOR.END].xAnchor())) {
                    this.selectedAnnotation = constants.EDITOR.END
                    return
                }
            },

            unselectAnnotation () {
                if (this.selectedAnnotation != null) {
                    this.bodyAngleAnnotation[this.selectedAnnotation].stroke(generalSettings.chart.annotation.strokeColor, generalSettings.chart.annotation.strokeThicknessBold)
                    this.selectedAnnotation = null
                }
            },

            rangeChangeHandler (event) {
                EventBus.$emit('UPDATE_ZOOM_STATUS', {
                    start: event.firstSelected,
                    end: event.lastSelected,
                })
            },

            startDragging () {
                this.dragging = true
            },

            stopDragging () {
                this.dragging = false
            },

            emitQuats (pointInfo) {
                if (pointInfo) {
                    EventBus.$emit('UPDATE_CHARACTER_VIEWER', pointInfo)
                }
            },

            emitTime (pointInfo) {
                if (pointInfo && this.selectedAnnotation >= 0 ) {
                    EventBus.$emit('UPDATE_ANNOTATION_POSITION_EDITOR', {
                        type: this.selectedAnnotation,
                        timestamp: this.normalizeTimezoneFromChart(pointInfo),
                    })
                }
            },
        },
    }
</script>

<style lang="scss">
    .sz-Chart {
        height: 100%;
        width: 100%;
    }

    .anychart-plot-controls {
        visibility: hidden;
    }

    .fade {
        &-enter-active,
        &-enter-to,
        &-leave-active {
            transition: opacity .2s
        };
        &-enter,
        &-leave-to {
            opacity: 0
        };
    }

</style>
