<template lang="pug">
    div(class="sz-ModuleExtraJointInfo")
        div(class="sz-ModuleExtraJointInfo-columns")
            div(
                v-for="column in columns",
                :key="column.header",
                v-tooltip="tooltipMessage(column)"
                :class="`sz-ModuleExtraJointInfo-columns-column sz-ModuleExtraJointInfo-columns-column-${column.header}`")
                div(class="sz-ModuleExtraJointInfo-columns-column-title") {{ $t(`motionAssessment.moduleJointDetail.header.${column.header}`) }}
                div(class="sz-ModuleExtraJointInfo-columns-column-value") {{  column.value }}
</template>

<script>
import constants from 'helpers/constants'
import { mapGetters } from 'vuex'
import { secondsToMinutes } from 'helpers/utilities'
import { tooltip } from 'directives/tooltip'

export default {
    name: 'ModuleExtraJointInfo',

    directives: {
        tooltip,
    },

    props: {
        jointData: {
            required: true,
            type: Object,
        },
    },

    computed: {
        ...mapGetters([
            'selectedModule',
        ]),

        title () {
            return this.$t(`motionAssessment.moduleJointDetail.title.${this.selectedModule}`)
        },

        isModuleThermal () {
            return this.selectedModule === constants.ASSESSMENT_PAGE.THERMAL
        },

        columns () {
            let data = (this.isModuleThermal) ? this.jointData.workLoad : this.jointData.A8

            return Object.keys(data).map(headerName => {
                return {
                    header: headerName,
                    value: secondsToMinutes(data[headerName]),
                }
            })
        },
    },

    methods: {
        tooltipMessage (info) {
            return {
                tooltipBodySettings: {
                    view: {
                        color: 'dark',
                        textAlign: 'center',
                        padding: 'extra-padding',
                        margin: 'extra-margin-left',
                    },
                    title: this.$t(`${this.selectedModule}Assessment.extraJointInfoMessage.${info.header}`, {count: info.value}),
                },

                body: {
                    placement: 'bottom-right',
                    offset: '0, -100',
                },
                allowHover: true,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~design";
    .sz-ModuleExtraJointInfo {
        font-size: 0.75rem;

        &-columns{
            color: $color-white;
            display: flex;
            flex-direction: row;

            &-column {
                margin-right: 0.7rem;
                border-radius: 2px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                &-title {
                    text-transform: uppercase;
                    width: 100%;
                    white-space: nowrap;
                    margin-bottom: 0.25rem;

                }

                &-value {
                    background-color: $color-table-dark-background;
                    border: 2px solid $color-table-dark-background;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 2px;
                    width: 2.6rem;
                    height: 2.375rem;
                }

                &-heavy, &-veryHeavy {
                    margin-right: 0.3rem;
                }
            }
        }
    }

</style>
