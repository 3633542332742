<template lang="pug">
    div(v-bind:class="isAssessmentEditor ? 'sz-AssessmentInfo-editor' : 'sz-AssessmentInfo'")
        div(class="sz-AssessmentInfo-container")
            component(
                v-if="!loadingGraph",
                :is="currentAssessmentType",
                :selectedRisks="selectedRisks",
                :selectedAssessment="selectedAssessment",
                :captureStart="captureStart",
                :captureEnd="captureEnd",
                :assessmentStart="assessmentStart",
                :assessmentEnd="assessmentEnd",
                :assessmentTimezone="assessmentTimezone")
</template>

<script>
    import MotionAssessmentInfo from 'components/Assessment/MotionAssessment/MotionAssessmentInfo'
    import ThermalAssessmentInfo from 'components/Assessment/ThermalAssessment/ThermalAssessmentInfo'
    import VibrationAssessmentInfo from 'components/Assessment/VibrationAssessment/VibrationAssessmentInfo'
    import PostureAssessmentInfo from 'components/Assessment/PostureAssessment/PostureAssessmentInfo'
    import EditorAssessmentInfo from 'components/Assessment/Editor/EditorAssessmentInfo'

    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'

    export default {
        name: "AssessmentInfo",

        components: {
            MotionAssessmentInfo,
            ThermalAssessmentInfo,
            VibrationAssessmentInfo,
            PostureAssessmentInfo,
            EditorAssessmentInfo,
        },

        props: {
            loadingGraph: {
                type: Boolean,
                required: true,
            },

            selectedRisks: {
                type: Array,
                required: true,
            },

            selectedAssessment: {
                type: Object,
                required: true,
            },

            assessmentStart: {
                required: true,
                type: Number,
            },

            assessmentEnd: {
                required: true,
                type: Number,
            },

            assessmentTimezone: {
                required: true,
                type: String,
            },

            captureStart: {
                required: true,
                type: Number,
            },

            captureEnd: {
                required: true,
                type: Number,
            },
        },

        computed: {
            ...mapGetters([
                'selectedModule',
            ]),

            currentAssessmentType() {
                if (!this.selectedModule in constants.RISK_MODULES) return
                return `${this.selectedModule}${constants.ASSESSMENT_INFO_COMPONENTS}`
            },

            isAssessmentEditor () {
                return this.selectedModule === constants.ASSESSMENT_PAGE.EDITOR
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";
    .sz-AssessmentInfo {
        padding: 0 2rem 0 5.75rem;

        &-editor {
            padding-left: 3.8rem;
        }

        &-container {
            @include scrollbar-widget;
            background-color: #454545;
            width: 100%;
            overflow-x: scroll;
        }
    }
</style>
