<template lang="pug">
    div(class="sz-TimeInput")
        div(class="sz-TimeInput-timePicker")
            div(class="sz-TimeInput-timePicker-editTime") {{ currentTime }}
            div(class="sz-TimeInput-timePicker-times")
                div(class="sz-TimeInput-timePicker-times-time")
                    div(class="sz-TimeInput-timePicker-times-time-option",
                        :class="'sz-TimeInput-timePicker-times-time-option-' +  isHourSelected(index + 1,selectedHour)",
                        v-for="(hour, index) in hours",
                        key=index,
                        @click.stop="selectHour(index + 1)") {{ index + 1}}
                div(class="sz-TimeInput-timePicker-times-time")
                    div(class="sz-TimeInput-timePicker-times-time-option",
                        :class="'sz-TimeInput-timePicker-times-time-option-' +  isSelected(index,selectedMinute)"
                        v-for="(minutes, index) in minutes",
                        key=index,
                        @click.stop="selectMinutes(index)") {{ index }}
                div(class="sz-TimeInput-timePicker-times-time")
                    div(class="sz-TimeInput-timePicker-times-time-option",
                        :class="'sz-TimeInput-timePicker-times-time-option-' +  isSelected(index,selectedSeconds)"
                        v-for="(seconds, index) in seconds",
                        key=index,
                        @click.stop="selectSeconds(index)") {{ index }}
                div(class="sz-TimeInput-timePicker-times-time")
                    div(class="sz-TimeInput-timePicker-times-time-option",
                        :class="'sz-TimeInput-timePicker-times-time-option-' +  isSelected(AM,selectedAmPm)",
                        @click.stop="selectAmPm(AM)") {{ $t('timePicker.am')}}
                    div(class="sz-TimeInput-timePicker-times-time-option",
                        :class="'sz-TimeInput-timePicker-times-time-option-' +  isSelected(PM,selectedAmPm)",
                        @click.stop="selectAmPm(PM)") {{ $t('timePicker.pm')}}
            div(class="sz-TimeInput-timePicker-button",
                :class="'sz-TimeInput-timePicker-button-' + (isNewTimeValid  ? 'valid' : 'invalid')",
                @click.stop="setTime()") {{ $t('timePicker.selectTime')}}
</template>

<script>
import moment from 'moment-timezone'
import { ClickAway } from 'directives/clickAway'
import constants from 'helpers/constants'
import { cloneDeep } from 'lodash'

export default {
    name: 'TimeInput',

    directives: {
        ClickAway,
    },

    props: {
        defaultTime: {
            required: false,
            type: String,
            default: null,
        },

        maxTime: {
            required: false,
            type: String,
            default: null,
        },

        minTime: {
            required: false,
            type: String,
            default: null,
        },

    },

    data () {
        return {
            updatedTime: null,
            hours: new Array(12),
            minutes: new Array(60),
            seconds: new Array(60),
            selectedHour: null,
            selectedMinute: null,
            selectedSeconds: null,
            selectedAmPm: null,
        }
    },

    computed: {
        currentTime () {
            return moment(this.updatedTime).format('LTS')
        },

        isCurrentTimeDay () {
            return moment(this.updatedTime).format('A') === constants.FORM_TIME.AM
        },

        isNewTimeValid () {
            if (this.maxTime && this.minTime) {
                return this.isBeforeMaxTime && this.isAfterMinTime
            }
            if (this.minTime) return this.isBeforeMaxTime
            if (this.maxTime) return this.isAfterMinTime
            return true
        },

        isBeforeMaxTime () {
            return moment(this.updatedTime).isBefore(moment(this.maxTime, constants.MOMENT_TIME_FORMAT.DATE_TIME))
        },

        isAfterMinTime () {
            return moment(this.updatedTime).isAfter(moment(this.minTime, constants.MOMENT_TIME_FORMAT.DATE_TIME))
        },

        PM () {
            return constants.FORM_TIME.PM
        },

        AM () {
            return constants.FORM_TIME.AM
        },

        currentDate () {
            return moment(this.defaultTime).format('MM-DD-YYYY')
        },

    },

    watch: {
        defaultTime: function(val) {
            this.updatedTime =  moment(val, constants.MOMENT_TIME_FORMAT.DATE_TIME)
        },
    },

    mounted () {
        if (this.defaultTime) {
            this.updatedTime = moment(this.defaultTime, constants.MOMENT_TIME_FORMAT.DATE_TIME)
        }
    },

    methods: {
        selectSeconds (time) {
            this.updatedTime = moment(this.updatedTime).set('second', time)
            this.selectedSeconds = time
        },

        selectMinutes (time) {
            this.updatedTime = moment(this.updatedTime).set('minute', time)
            this.selectedMinute = time
        },

        selectHour (time) {
            this.updatedTime = moment(this.updatedTime).set('hour', this.adjustTime(time))
            // moment js will change the date if the current time is AM and user chooses 12 am
            // to fix this, you decrement the day by 1
            if (time === 12 && this.isCurrentTimeDay) this.updatedTime.subtract(1, 'd')

            this.selectedHour = time
        },

        selectAmPm (amPm) {
            this.selectedAmPm = amPm
            let hourToSet = (this.selectedHour) ? this.selectedHour : moment(this.updatedTime).get('hour')

            this.adjustDay(hourToSet)

            if (hourToSet >= 12) {
                hourToSet -= 12
            }

            this.selectHour(hourToSet)
        },

        isSelected (selectedVal,val) {
            return (selectedVal === val) ? 'active' : 'inactive'
        },

        isHourSelected (selectedVal,val) {
            if ((selectedVal === 0 && val === 12) || (selectedVal === 12 && val === 0)) return 'active'
            return this.isSelected (selectedVal,val)
        },

        adjustTime (time) {
            // Converts 24 -> 12
            if ((this.selectedAmPm === constants.FORM_TIME.PM || !this.isCurrentTimeDay) && time === 12) return 12
            if ((this.selectedAmPm === constants.FORM_TIME.AM || this.isCurrentTimeDay) && time === 12) return 24

            if (!this.selectedAmPm) {
                return this.isCurrentTimeDay ? time: time + 12
            }

            return (this.selectedAmPm === constants.FORM_TIME.PM) ? time + 12 : time
        },

        adjustDay(hour) {
            // Covers an edge case where a capture extends into the next day
            // Switching from AM to PM requires the day to be decremented into the previous day
            if (Math.sign(hour - 12) === -1 && this.updatedTimeIsOneDayAheadOfMinTime()) {
                this.updatedTime.subtract(1, 'd')
            }
        },

        updatedTimeIsOneDayAheadOfMinTime() {
            let updatedTime = cloneDeep(this.updatedTime)
            updatedTime.subtract(1, 'd')
            let minTime = moment(this.minTime, constants.MOMENT_TIME_FORMAT.DATE_TIME)

            return moment(updatedTime).get('year') >= minTime.get('year') &&
                   moment(updatedTime).get('month') >= minTime.get('month') &&
                   moment(updatedTime).get('date') >= minTime.get('date')
        },

        setTime () {
            if (this.isNewTimeValid) {
                this.$emit('selectedInput', moment(this.updatedTime).format(constants.MOMENT_TIME_FORMAT.DATE_TIME))
                this.close()
            }
        },

        close () {
            this.$emit('closeTimeInput')
        },
    },

}
</script>

<style lang="scss" scoped>
    @import "~design";
    .sz-TimeInput {
        &-timePicker {
            position: absolute;
            z-index: 10;
            background-color: $color-dropdown-background;
            padding: 0.25rem;
            font-size: 0.9rem;
            &-button {
                padding: 0.25rem 0;
                &:hover{
                    background-color: $color-unselected-text;
                    cursor: not-allowed;
                }
                &-valid {
                    &:hover{
                        background-color: $color-lifebooster-light-green;
                        cursor: pointer;
                    }
                }
            }
            &-times {
                display: flex;
                flex-direction: row;
                height: 12rem;
                &-time {
                    @include scrollbar-widget;
                    overflow-y: auto;
                    background-color: lighten($color-dropdown-background, 5%);
                    margin: 0.25rem;
                    &-option {
                        @extend %font-content;
                        cursor: pointer;
                        padding: 0.25rem 1rem;
                        &:hover, &-active {
                            background-color: $color-lifebooster-light-green;
                        }
                    }
                }
            }
        }
    }

</style>
