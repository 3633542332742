<template lang="pug">
    div(class="sz-MotionAssessmentInfo")
      table()
          div(class="sz-MotionAssessmentInfo-headers")
              div(class="sz-MotionAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.riskTypes`)}` }}
          div(
            v-for="row of currentlySelectedRisks",
            class="sz-MotionAssessmentInfo-row")
                div(class="sz-MotionAssessmentInfo-cell") {{ row }}
      table()
          div(class="sz-MotionAssessmentInfo-headers")
              div(class="sz-MotionAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.currentAngle`)}` }}
          div(
            v-for="angle of currentAngles",
            class="sz-MotionAssessmentInfo-row")
                div(
                    class="sz-MotionAssessmentInfo-cell",
                    v-bind:class= "isNaN(angle) ? 'sz-MotionAssessmentInfo-cell-empty' : 'sz-MotionAssessmentInfo-cell'") {{ angle }}
      table()
          div(class="sz-MotionAssessmentInfo-headers")
              div(class="sz-MotionAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.durationWithin`)}` }}
          div(
            v-for="row of riskTypePayloads",
            class="sz-MotionAssessmentInfo-row")
                div(class="sz-MotionAssessmentInfo-cell") {{ row.withinDuration }}
      table()
          div(class="sz-MotionAssessmentInfo-headers")
              div(class="sz-MotionAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.positiveThreshold`)}` }}
              div(class="sz-MotionAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.positiveDuration`)}` }}
          div(
            v-for="row of riskTypePayloads",
            class="sz-MotionAssessmentInfo-row")
                div(class="sz-MotionAssessmentInfo-cell") {{ row.positiveThreshold }}
                div(class="sz-MotionAssessmentInfo-cell") {{ row.positiveDuration }}
      table()
          div(class="sz-MotionAssessmentInfo-headers")
              <th colspan="3"> {{ `${this.$t(`motionAssessment.assessmentInfo.positiveRiskyEvents`)}` }} </th>
          div(
            v-for="row of riskTypePayloads",
            class="sz-MotionAssessmentInfo-row")
                div(class="sz-MotionAssessmentInfo-cell-yellowRiskCount") {{ row.positiveYellowRiskCount }}
                div(class="sz-MotionAssessmentInfo-cell-orangeRiskCount") {{ row.positiveOrangeRiskCount }}
                div(class="sz-MotionAssessmentInfo-cell-redRiskCount") {{ row.positiveRedRiskCount }}
      table()
          div(class="sz-MotionAssessmentInfo-headers")
              div(class="sz-MotionAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.negativeThreshold`)}` }}
              div(class="sz-MotionAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.negativeDuration`)}` }}
          div(
            v-for="row of riskTypePayloads",
            class="sz-MotionAssessmentInfo-row")
                div(class="sz-MotionAssessmentInfo-cell") {{ row.negativeThreshold }}
                div(class="sz-MotionAssessmentInfo-cell") {{ row.negativeDuration }}
      table()
          div(class="sz-MotionAssessmentInfo-headers")
              <th colspan="3"> {{ `${this.$t(`motionAssessment.assessmentInfo.negativeRiskyEvents`)}` }} </th>
          div(
            v-for="row of riskTypePayloads",
            class="sz-MotionAssessmentInfo-row")
                div(class="sz-MotionAssessmentInfo-cell-yellowRiskCount") {{ row.negativeYellowRiskCount }}
                div(class="sz-MotionAssessmentInfo-cell-orangeRiskCount") {{ row.negativeOrangeRiskCount }}
                div(class="sz-MotionAssessmentInfo-cell-redRiskCount") {{ row.negativeRedRiskCount }}
</template>

<script>
    import { tooltip } from 'directives/tooltip'
    import EventBus from 'src/eventBus'
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'

    export default {
        name: "MotionAssessmentInfo",

        directives: {
            tooltip,
        },

        components: {
        },

        props: {
            selectedRisks: {
                type: Array,
                required: true,
            },

            selectedAssessment: {
                type: Object,
                required: true,
            },
        },

        data () {
            return {
                currentAngles: null,
            }
        },

        computed: {
            ...mapGetters([
                'selectedModule',
            ]),

            currentlySelectedRisks() {
                return this.selectedRisks.map(risk => {
                    return this.$t(`riskTypes.${risk}`)
                })
            },

            riskTypePayloads() {
                return this.selectedRisks.map(risk => {
                    // There is no risk associated with Pelvis Flex/Ext
                    if (risk === constants.ERGO_RISK_TYPES.PELVIS_FLEX) {
                        return {
                            positiveThreshold: `${this.$t('naUppercase')}`,
                            positiveDuration: `${this.$t('naUppercase')}`,
                            positiveYellowRiskCount: `${this.$t('naUppercase')}`,
                            positiveOrangeRiskCount: `${this.$t('naUppercase')}`,
                            positiveRedRiskCount: `${this.$t('naUppercase')}`,
                            withinDuration: `${this.$t('naUppercase')}`,
                            negativeThreshold: `${this.$t('naUppercase')}`,
                            negativeDuration: `${this.$t('naUppercase')}`,
                            negativeYellowRiskCount: `${this.$t('naUppercase')}`,
                            negativeOrangeRiskCount: `${this.$t('naUppercase')}`,
                            negativeRedRiskCount: `${this.$t('naUppercase')}`,
                        }
                    }

                    let payload = this.selectedAssessment.RiskTypes.find(r => r.name === risk).payload
                    return {
                        positiveThreshold: payload.positiveThreshold,
                        positiveDuration: this.formatDuration(payload.positiveDuration),
                        positiveYellowRiskCount: payload.positiveYellowRiskCount,
                        positiveOrangeRiskCount: payload.positiveOrangeRiskCount,
                        positiveRedRiskCount: payload.positiveRedRiskCount,
                        withinDuration: this.formatDuration(payload.withinDuration),
                        negativeThreshold: !isNaN(payload.negativeThreshold) ? payload.negativeThreshold : 'N/A',
                        negativeDuration: !isNaN(payload.negativeDuration) ? this.formatDuration(payload.negativeDuration) : 'N/A',
                        negativeYellowRiskCount: !isNaN(payload.negativeYellowRiskCount) ? payload.negativeYellowRiskCount : 'N/A',
                        negativeOrangeRiskCount: !isNaN(payload.negativeOrangeRiskCount) ? payload.negativeOrangeRiskCount : 'N/A',
                        negativeRedRiskCount:  !isNaN(payload.negativeRedRiskCount) ? payload.negativeRedRiskCount : 'N/A',
                    }
                })
            },
        },

        mounted() {
            EventBus.$on('UPDATE_CURRENT_ANGLES', (angles) => {
                this.currentAngles = angles
            })
        },

        beforeDestroy() {
            EventBus.$off('UPDATE_CURRENT_ANGLES')
        },

        methods: {
            formatDuration(duration) {
                return new Date(duration * 1000).toISOString().substr(11, 8).replace(':', 'h').replace(':', 'm') + 's'
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";

    th {
        @extend %font-heading;
        padding: 0.25rem;
        font-size: 0.75rem;
        text-align: center;
        vertical-align: middle;
        border: 0.3rem solid $color-table-dark-background;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    table {
        width: 100%;
        table-layout: auto;
        border-collapse: collapse;
    }

    .sz-MotionAssessmentInfo {
        display: flex;
        flex-direction: row;
        padding: 0.25rem 0.75rem 0.75rem 0.75rem;

        &-headers {
            background-color: $color-table-dark-background;
            display: table-header-group;
            margin-bottom: 0.3rem;
        }

        &-header {
            @extend %font-heading;
            padding: 0.25rem;
            font-size: 0.75rem;
            display: table-cell;
            text-align: center;
            vertical-align: middle;
            border: 0.3rem solid $color-table-dark-background;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &-row {
            display: table-row-group;
            padding: 0.5rem;
            background-color: $color-lifebooster-dark-green;
        }

        &-cell {
            border: 0.3rem solid $color-table-dark-background;
            padding: 0.5rem;
            display: table-cell;
            font-size: 0.8rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &-empty {
                color: $color-lifebooster-dark-green;
            }

            &-redRiskCount {
                border: 0.3rem solid $color-table-dark-background;
                background-color: $color-body-bg;
                display: table-cell;
                padding: 0.5rem;
                font-size: 0.8rem;
                outline: 0.2rem solid $color-risk-red;
                outline-offset: -0.35rem;
            }

            &-orangeRiskCount {
                border: 0.3rem solid $color-table-dark-background;
                background-color: $color-body-bg;
                display: table-cell;
                padding: 0.5rem;
                font-size: 0.8rem;
                outline: 0.2rem solid $color-risk-orange;
                outline-offset: -0.35rem;
            }

            &-yellowRiskCount {
                border: 0.3rem solid $color-table-dark-background;
                background-color: $color-body-bg;
                display: table-cell;
                padding: 0.5rem;
                font-size: 0.8rem;
                outline: 0.2rem solid $color-risk-yellow;
                outline-offset: -0.35rem;
            }
        }

        &-label {
            @extend %font-heading;
            font-size: 0.75rem;
            display: table-cell;
            border: 0.3rem solid $color-table-dark-background;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
</style>
