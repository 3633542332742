import { render, staticRenderFns } from "./HeaderJointInfo.vue?vue&type=template&id=0489e652&scoped=true&lang=pug"
import script from "./HeaderJointInfo.vue?vue&type=script&lang=js"
export * from "./HeaderJointInfo.vue?vue&type=script&lang=js"
import style0 from "./HeaderJointInfo.vue?vue&type=style&index=0&id=0489e652&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0489e652",
  null
  
)

export default component.exports