<template lang="pug">
    div(class="sz-AssessmentChartsPanel-container")
        transition(name="fade")
            component(
                v-if="!loadingGraph",
                :is="currentGraphType",
                :annotationPosition="annotationPosition",
                :graph="parsedGraphData",
                :loadingGraph="loadingGraph",
                :assessmentTimezoneOffset="assessmentTimezoneOffset",
                :assessmentStart="correctStartEndTime.start",
                :assessmentEnd="correctStartEndTime.end",
                :jointKeys="jointKeys",
                :currentCustomer="currentCustomer",
                :currentCompany="currentCompany",
                :workerId="workerId",
                :captureIdx="captureIdx",
                :metadataID="metadataID",
                :assessmentIdx="assessmentIdx",
                :motion="motion",
                :assessmentTimezone="assessmentTimezone",
                :selectedRisks="selectedRisks",
                :selectedAssessment="selectedAssessment")
            div(
                v-else,
                class="sz-AssessmentChartsPanel-chartsPanel-loading")
                LoadingSpinner(color="light")
</template>

<script>
    import MotionChartGroup from 'components/Assessment/MotionAssessment/MotionChartGroup'
    import PostureChartGroup from 'components/Assessment/PostureAssessment/PostureChartGroup'
    import ThermalChartGroup from 'components/Assessment/ThermalAssessment/ThermalChartGroup'
    import VibrationChartGroup from 'components/Assessment/VibrationAssessment/VibrationChartGroup'
    import EditorChartGroup from 'components/Assessment/Editor/EditorChartGroup'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'
    import { mapGetters } from 'vuex'
    import motionAssessmentUtils from 'mixins/motionAssessment'
    import EventBus from 'src/eventBus'
    import constants from 'helpers/constants'

    export default {
        name: "AssessmentChartsPanel",

        components: {
            MotionChartGroup,
            PostureChartGroup,
            EditorChartGroup,
            ThermalChartGroup,
            VibrationChartGroup,
            LoadingSpinner,
        },

        mixins: [
            motionAssessmentUtils,
        ],

        props: {
            loadingGraph: {
                type: Boolean,
                required: true,
            },

            graphData: {
                type: Object,
                required: true,
            },

            selectedRisks: {
                type: Array,
                required: true,
            },

            selectedAssessment: {
                type: Object,
                required: true,
            },

            assessmentTimezoneOffset: {
                required: true,
                type: Number,
            },

            jointKeys: {
                required: true,
                type: Object,
            },

            assessmentStart: {
                required: true,
                type: Number,
            },

            assessmentEnd: {
                required: true,
                type: Number,
            },
            currentCustomer: {
                required: true,
                type: String,
            },

            currentCompany: {
                required: true,
                type: String,
            },

            workerId: {
                required: true,
                type: String,
            },

            captureIdx: {
                required: true,
                type: String,
            },

            metadataID: {
                required: false,
                type: String,
            },

            assessmentIdx: {
                required: true,
                type: String,
            },

            motion: {
                required: true,
                type: String,
            },

            assessmentTimezone: {
                required: true,
                type: String,
            },
        },

        data () {
            return {
                annotationPosition: 0,
                zoomStart: 0,
                zoomEnd: 0,
            }
        },

        computed: {
            ...mapGetters([
                'selectedModule',
            ]),

            isAssessmentEditor() {
                return this.selectedModule === constants.ASSESSMENT_PAGE.EDITOR
            },

            parsedGraphData () {
                let graph = {}
                if (Object.keys(this.graphData).length > 0) {
                    Object.keys(this.graphData).map(key => {
                        graph[key] = this.parseRiskEvents(this.graphData[key], this.jointKeys[key], this.selectedRisks.length, this.selectedRisks.indexOf(key), key)
                            .map((point) => {
                                const correctedTimestamp = this.convertPosixToLocalTime(
                                    this.assessmentTimezoneOffset,
                                    point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP])
                                point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP] = correctedTimestamp
                                return point
                            })
                    })
                }

                // Assessment editor was not a candidate for multi-joint support
                // To maintain functionality, assessment editor can only select a single joint and receives the graph as an array
                return this.isAssessmentEditor ? graph[Object.keys(this.graphData)[0]] : graph
            },

            correctStartEndTime () {
                return {
                    start: this.convertPosixToLocalTime(this.assessmentTimezoneOffset, this.assessmentStart),
                    end: this.convertPosixToLocalTime(this.assessmentTimezoneOffset, this.assessmentEnd),
                }
            },

            currentGraphType () {
                if (!this.selectedModule in constants.RISK_MODULES) return
                return `${this.selectedModule}${constants.ASSESSMENT_CHART_PANEL_COMPONENTS}`
            },
        },

        mounted () {
            EventBus.$on('UPDATE_ANIMATION_START_TIME', (timestamp) => {
                this.setAnnotationPosition(timestamp)
            })

            EventBus.$on('UPDATE_ZOOM_STATUS', (zoomInfo) => {
                this.setZoomStatus(zoomInfo)
            })

            EventBus.$emit('SET_DISABLE_CHART_INTERACTION', false)
        },

        updated () {
            EventBus.$on('UPDATE_ANIMATION_START_TIME', (timestamp) => {
                this.setAnnotationPosition(timestamp)
            })

            EventBus.$on('UPDATE_ZOOM_STATUS', (zoomInfo) => {
                this.setZoomStatus(zoomInfo)
            })
        },

        beforeDestroy () {
            EventBus.$off('UPDATE_ANIMATION_START_TIME')

            EventBus.$off('UPDATE_ZOOM_STATUS')
        },

        methods: {
            setAnnotationPosition (time) {
                this.annotationPosition = time
            },

            setZoomStatus (zoomInfo) {
                this.zoomStart = zoomInfo.start
                this.zoomEnd = zoomInfo.end
            },
        },
    }
</script>

<style lang="scss" scoped>
    .sz-AssessmentChartsPanel {
        display: flex;
        flex-grow: 1;
        &-container {
            padding: 0.5rem;
            width: 100%;
            height: 100%;
        }
    }
</style>
