<template lang="pug">
    Layout
      AssessmentDashboard(
            :workerId="workerId",
            :captureIdx="captureIdx",
            :assessmentIdx="assessmentIdx",
            :riskType="riskType",
            :assessmentDate="assessmentDate")
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '../layouts/main'
import AssessmentDashboard from 'components/Dashboards/AssessmentDashboard'

export default {
  name: 'AssessmentView',

  components: {
    Layout,
    AssessmentDashboard,
  },

  props: {
    workerId: {
      type: String,
      required: true,
    },
    captureIdx: {
      type: [Number, String],
      required: true,
    },
    assessmentIdx: {
      type: [Number, String],
      required: true,
    },
    riskType: {
      type: String,
      required: true,
    },
    assessmentDate: {
      type: [Number, String],
      required: true,
    },
    assessmentType: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    ...mapGetters(['userID', 'userHasAssessorRole']),
  },

  mounted() {
    if (this.isViewRestrictedToUser()) {
      this.$router.push({ name: '404' })
    }

    this.$store.dispatch('updateWorkerId', this.workerId)
    this.$store.dispatch('updateWorkerCard', { workerId: this.workerId })
  },

  methods: {
    isViewRestrictedToUser() {
      return !this.userHasAssessorRole && this.workerId !== this.userID
    },
  },
}
</script>

<style lang="scss" scoped></style>
