<template lang="pug">
    div(class="sz-OffBodyTimeSelector")
        div(class="sz-OffBodyTimeSelector-buttons")
            ZoomButtons(
                v-if="!loadingGraph",
                @updateZoom="updateZoomLevel")
            div(v-if="isZoomed")
                span(
                    v-tooltip="zoomStartTooltip",
                    id="zoom-left-info",
                    class="sz-OffBodyTimeSelector-zoomInfo") {{ zoomStartTimezoneCorrected }}
                span() -
                span(
                    v-tooltip="zoomEndTooltip",
                    id="zoom-right-info",
                    class="sz-OffBodyTimeSelector-zoomInfo") {{ zoomEndTimezoneCorrected }}
        div(class="sz-OffBodyTimeSelector-chart")
            div(
                @click="panLeft"
                class="sz-OffBodyTimeSelector-chart-panController")
                SvgIcon(
                    icon="arrow",
                    width="35",
                    height="35",
                    id="panLeft"
                    v-tooltip="panLeftTooltip"
                    class="sz-OffBodyTimeSelector-chart-panControllerIcon left",
                    :class=`{"enabled": canPanLeft}`)
            div(class="sz-OffBodyTimeSelector-chart-middle")
                div(
                    v-if="isRequestingZoomedData"
                    class="sz-OffBodyTimeSelector-chart-loader")
                    LoadingSpinner(class="sz-OffBodyTimeSelector-chart-loader-spinner")
                div(
                    ref="chart",
                    class="sz-Chart",
                    v-click-away="unselectAnnotation")
            div(
                @click="panRight"
                class="sz-OffBodyTimeSelector-chart-panController panRight")
                SvgIcon(
                    icon="arrow",
                    width="35",
                    height="35",
                    id="panRight"
                    v-tooltip="panRightTooltip"
                    class="sz-OffBodyTimeSelector-chart-panControllerIcon right",
                    :class=`{"enabled": canPanRight}`)
</template>

<script>
    import Anychart from 'anychart'
    import { generalSettings, thresholdSettings, riskSettings } from 'helpers/assessmentGraph'
    import { bSearch } from 'helpers/utilities'
    import { tooltip } from 'directives/tooltip'
    import EventBus from 'src/eventBus'
    import motionAssessmentUtils from 'mixins/motionAssessment'
    import constants from 'helpers/constants'
    import SvgIcon from 'components/Shared/SvgIcon'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'
    import ZoomButtons from 'components/Assessment/ZoomButtons'
    import { ClickAway } from 'directives/clickAway'

    export default {
        name: 'MotionChartGroup',

        components: {
            SvgIcon,
            LoadingSpinner,
            ZoomButtons,
        },

        directives: {
            tooltip,
            ClickAway,
        },

        mixins: [
            motionAssessmentUtils,
        ],

        props: {
            graph: {
                required: true,
                type: Array,
            },

            loadingGraph: {
                required: true,
                type: Boolean,
            },

            assessmentTimezoneOffset: {
                required: true,
                type: Number,
            },

            jointKeys: {
                required: true,
                type: Object,
            },

            currentCustomer: {
                required: true,
                type: String,
            },

            currentCompany: {
                required: true,
                type: String,
            },

            workerId: {
                required: true,
                type: String,
            },

            captureIdx: {
                required: true,
                type: String,
            },

            metadataID: {
                required: false,
                type: String,
            },

            assessmentIdx: {
                required: true,
                type: String,
            },

            assessmentStart: {
                required: true,
                type: Number,
            },

            assessmentEnd: {
                required: true,
                type: Number,
            },

            assessmentTimezone: {
                required: true,
                type: String,
            },

            selectedRiskType: {
                required: true,
                type: String,
            },

            selectedPeriods: {
                required: true,
                type: Array,
            },

            selectedRotations: {
                required: false,
                type: Array,
                default: [],
            },
        },

        data() {
            return {
                chart: undefined,
                options: null,
                table: {},
                mappings: {
                    bodyAngle: {},
                },
                series: {
                    bodyAngle: {},
                },
                bodyAngleAnnotation: [],
                periodAnnotations: {},
                rotationAnnotations: {},
                riskAnnotation: null,
                dragging: false,
                annotationLocation: null,
                annotationIndex: null,
                disableChartInteraction: false,
                xTextMarker: null,
                isZoomed: false,
                isInZoomingMode: false,
                isRequestingZoomedData: false,
                zoomStart: null,
                zoomEnd: null,
                zoomedData: [],
                currentTime: 0,
                currentTimestamp: 0,
            }
        },

        computed: {
            currentGraph () {
                if (this.isZoomed){
                    return this.zoomedData
                } else {
                    return this.graph
                }
            },

            canPanLeft () {
                return this.isZoomed
                    && this.zoomStart > this.assessmentStart
                    && !this.isRequestingZoomedData
                    && !this.disableChartInteraction
            },

            canPanRight () {
                return this.isZoomed
                    && this.zoomEnd < this.assessmentEnd
                    && !this.isRequestingZoomedData
                    && !this.disableChartInteraction
            },

            canZoom () {
                let firstTimestamp = this.assessmentStart
                let lastTimestamp = this.assessmentEnd

                if (this.isZoomed) {
                    firstTimestamp = this.zoomStart
                    lastTimestamp = this.zoomEnd
                }
                const currentRange = lastTimestamp - firstTimestamp
                const currentRangeIsWideEnough = currentRange >= (constants.MINIMUM_ZOOM_RANGE)
                return currentRangeIsWideEnough && !this.disableChartInteraction
            },

            canZoomOut () {
                return this.isZoomed && !this.disableChartInteraction
            },

            currentDisplayedTimeRange () {
                if (this.zoomEnd && this.zoomStart) {
                    return this.zoomEnd - this.zoomStart
                }
                return this.asssessmentStart - this.assessmentEnd
            },

            zoomStartTimezoneCorrected () {
                return this.timestampFormatted(this.normalizeTimezoneFromChart(this.zoomStart))
            },

            zoomEndTimezoneCorrected () {
                return this.timestampFormatted(this.normalizeTimezoneFromChart(this.zoomEnd))
            },

            zoomStartTooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'center',
                            padding: 'extra-padding',
                        },
                        title: this.$t('motionAssessment.graphLabel.zoomStart'),
                    },

                    body: {
                        placement: 'bottom-right',
                        offset: '100, -58',
                    },
                    allowHover: true,
                }
            },

            zoomEndTooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'center',
                            padding: 'extra-padding',
                        },
                        title: this.$t('motionAssessment.graphLabel.zoomEnd'),
                    },

                    body: {
                        placement: 'bottom-right',
                        offset: '100, -58',
                    },
                    allowHover: true,
                }
            },

            panRightTooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'center',
                            padding: 'extra-padding',
                        },
                        title: this.$t('motionAssessment.graphLabel.panRight'),
                    },

                    body: {
                        placement: 'bottom-right',
                        offset: '80, -60',
                    },
                    allowHover: false,
                    disabled: !this.canPanRight,
                }
            },

            panLeftTooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'center',
                            padding: 'extra-padding',
                        },
                        title: this.$t('motionAssessment.graphLabel.panLeft'),
                    },

                    body: {
                        placement: 'bottom-right',
                        offset: '80, -60',
                    },
                    allowHover: false,
                    disabled: !this.canPanLeft,
                }
            },
        },

        watch: {
            options: function (options) {
                if (!this.chart && options) {
                    this.init()
                } else {
                    this.chart.dispose()
                    this.chart = null
                    this.init()
                }
            },

            selectedPeriods: function () {
                // Remove existing period markers or else duplicates will be created
                Object.keys(this.periodAnnotations).map(idx => {
                    this.periodAnnotations[idx].dispose()
                })

                //Re-apply period markers
                this.addPeriodMarkers()
            },

            selectedRotations: function () {
                // Remove existing rotation markers or else duplicates will be created
                Object.keys(this.rotationAnnotations).map(idx => {
                    this.rotationAnnotations[idx].dispose()
                })

                //Re-apply rotation markers
                this.addRotationMarkers()
            },
        },

        mounted() {
            if (!this.chart) {
                this.init()
            }

            EventBus.$on('SET_DISABLE_CHART_INTERACTION', (disabled) => {
                this.disableChartInteraction = disabled
            })
        },

        beforeDestroy() {
            if (this.chart) {
                this.chart.dispose()
                this.chart = null
            }

            EventBus.$off('SET_DISABLE_CHART_INTERACTION', () => {
                this.disableChartInteraction = true
            })
        },

        methods: {
            delegateMethod(name, data) {
                if (!this.chart) {
                    warn(`Cannot call [$name] before the chart is initialized. Set prop [options] first.`, this)
                    return
                }
                return this.chart[name](data)
            },

            init() {
                if (!this.chart) {
                    Anychart.licenseKey(process.env.VUE_APP_ANYCHART_LICENSE)
                    // Timezone correction has already been performed in the main component, so we can set the offset to 0
                    // This is call ensures the initial page load displays the correct timestamps in the x-axis
                    Anychart.format.outputTimezone(0)
                    this.chart = Anychart.stock()
                    if (this.chart !== undefined && this.currentGraph !== undefined && this.currentGraph.length > 0) {
                        this.addLicenseKey()
                        this.addData()
                        this.addMappings()
                        this.addSeries()
                        this.addGeneralSettings()
                        this.addVerticalLine()
                        this.addPeriodMarkers()
                        this.addRotationMarkers()
                        this.addListeners()
                        this.addSeriesSettings()
                        this.chart.container(this.$refs.chart).draw()
                        this.setDefaultStartAndEndTimestamp()
                    }
                }
            },

            addData () {
                let table = Anychart.data.table(0)
                this.table = table.addData(this.currentGraph)
            },

            addLicenseKey () {
                let credits = this.chart.credits()
                credits.enabled(false)
            },

            addMappings () {
                this.mappings.bodyAngle = this.table.mapAs({
                    x: constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP,
                    value: constants.ASSESSMENT_GRAPH_INDICES.PRIMARY_VALUE})
            },

            addSeries () {
                this.series.bodyAngle = this.chart.plot().line(this.mappings.bodyAngle)

                this.series.bodyAngle.connectMissingPoints(true)
            },

            addGeneralSettings () {
                // it would be better to have all these settings in an object and then create the chart
                // using those settings as a JSON object instead of calling each individual method here, but unfortunately
                // AnyStock does not have support for this functionality at this point (v8.0.1).
                this.chart.width(generalSettings.chart.width)
                this.chart.height(generalSettings.chart.height)
                this.chart.padding(
                    generalSettings.chart.paddingTop,
                    generalSettings.chart.paddingRight,
                    generalSettings.chart.paddingBottom,
                    generalSettings.chart.paddingLeft)
                this.chart.background().fill(generalSettings.chart.background)
                this.chart.splitters().enabled(generalSettings.chart.splitters.enabled)
                this.chart.tooltip().enabled(generalSettings.chart.tooltip.enabled)
                this.chart.xScale().ticksCount(generalSettings.chart.xScale.ticks.length)

                const scroller = this.chart.scroller()
                scroller.enabled(false)

                const anglePlot = this.chart.plot(0)

                anglePlot.dataArea().background().enabled(generalSettings.chart.dataArea.enabled)
                anglePlot.dataArea().background().fill(generalSettings.chart.dataArea.fill)

                this.chart.crosshair(false)

                anglePlot.height(generalSettings.chart.height)

                let angleTitle = anglePlot.title()
                angleTitle.enabled(generalSettings.chart.plots.anglePlot.title.enabled)
                angleTitle.fontSize(generalSettings.chart.plots.anglePlot.title.fontSize)
                angleTitle.text(generalSettings.chart.plots.anglePlot.title.text)

                anglePlot.legend().enabled(true)
                anglePlot.legend().title().useHtml(true)
                anglePlot.legend().titleFormat(
                  "<span></span>"
                )
                anglePlot.legend().itemsFormat(
                  "{%seriesName}"
                )
                anglePlot.legend().align('right')
                anglePlot.legend().positionMode('inside')
                anglePlot.legend().itemsLayout('vertical-expandable')
                anglePlot.legend().tooltip(true)
                anglePlot.legend().textOverflow('...')
                anglePlot.legend().fontColor(generalSettings.chart.textMarker.fontColor)

                let angleXAxis = anglePlot.xAxis()
                let angleYAxis = anglePlot.yAxis()

                angleXAxis.labels().format(generalSettings.chart.crosshair.xLabel.format)
                angleXAxis.minorLabels().format(generalSettings.chart.crosshair.xLabel.format)
                angleXAxis.showHelperLabel(false)
                angleYAxis.labels().fontColor(generalSettings.chart.plots.anglePlot.yAxes[0].labels.fontColor)
                angleYAxis.labels().fontFamily(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontFamily)
                angleYAxis.labels().fontSize(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontSize)
                angleYAxis.labels().fontWeight(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontWeight)
                angleYAxis.stroke(generalSettings.chart.background)
                angleYAxis.ticks().stroke(generalSettings.chart.plots.anglePlot.xScale.fill)

                let angleYAxisTitle = angleYAxis.title()
                angleYAxis.title(this.stringConverter(generalSettings.chart.plots.anglePlot.yAxes[0].title.label).toUpperCase())
                angleYAxisTitle.fontSize(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontSize)
                angleYAxisTitle.fontWeight(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontWeight)
                angleYAxisTitle.fontFamily(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontFamily)
                angleYAxisTitle.fontColor(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontColor)
            },

            addVerticalLine () {
                this.bodyAngleAnnotation[constants.EDITOR.START] = this.chart.plot(0).annotations().verticalLine({
                    xAnchor: this.currentGraph[0][constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP],
                })
                this.bodyAngleAnnotation[constants.EDITOR.END] = this.chart.plot(0).annotations().verticalLine({
                    xAnchor: this.currentGraph[this.currentGraph.length - 1][constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP],
                })

                this.bodyAngleAnnotation[constants.EDITOR.START].allowEdit(generalSettings.chart.annotation.allowEdit)
                this.bodyAngleAnnotation[constants.EDITOR.END].allowEdit(generalSettings.chart.annotation.allowEdit)
                this.bodyAngleAnnotation[constants.EDITOR.START].stroke(generalSettings.chart.annotation.strokeColor, generalSettings.chart.annotation.strokeThicknessBold)
                this.bodyAngleAnnotation[constants.EDITOR.END].stroke(generalSettings.chart.annotation.strokeColor, generalSettings.chart.annotation.strokeThicknessBold)

                this.setChartAnnotationsAnchors(this.currentGraph[0][constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP])
            },

            addPeriodMarkers () {
                const anglePlot = this.chart.plot(0)

                if (this.selectedPeriods.length > 0) {
                    this.selectedPeriods.map(period => {
                        this.periodAnnotations[period.idx] = anglePlot.annotations().verticalRange({
                            xAnchor: period.start,
                            secondXAnchor: period.end,
                        })

                        this.periodAnnotations[period.idx].allowEdit(false)
                        this.periodAnnotations[period.idx].color(generalSettings.chart.annotation.strokeColor)
                    })
                }
            },

            addRotationMarkers () {
                const anglePlot = this.chart.plot(0)

                if (this.selectedRotations.length > 0) {
                    this.selectedRotations.map(rotation => {
                        this.rotationAnnotations[rotation.idx] = anglePlot.annotations().verticalLine({
                            xAnchor: rotation.timestamp,
                        })

                        this.rotationAnnotations[rotation.idx].allowEdit(false)
                        this.rotationAnnotations[rotation.idx].stroke(generalSettings.chart.annotation.rotationAnnotationColor, generalSettings.chart.annotation.strokeThicknessBold)
                    })
                }
            },

            getNearestTimestamp (timestamp) {
                const selectable = this.mappings.bodyAngle.createSelectable()

                selectable.selectAll()
                const select = selectable.search(timestamp, "nearest")
                // return point date (in milliseconds)
                return select.getKey()
            },

            applyChartAnnotationChanges (pointTimestamp) {
                this.bodyAngleAnnotation[this.selectedAnnotation].xAnchor(pointTimestamp)

                this.swapAnnotationsOrder()

                this.bodyAngleAnnotation[this.selectedAnnotation].stroke(generalSettings.chart.annotation.selectedAnnotationColor, generalSettings.chart.annotation.strokeThicknessBold)
                this.bodyAngleAnnotation[this.getOtherSelectedAnnotation()].stroke(generalSettings.chart.annotation.strokeColor, generalSettings.chart.annotation.strokeThicknessBold)
            },

            swapAnnotationsOrder () {
                // if end annotation is moved ahead of start annotation, the timestamps are reversed
                if (this.bodyAngleAnnotation[constants.EDITOR.END].xAnchor() < this.bodyAngleAnnotation[constants.EDITOR.START].xAnchor()) {
                    const temp = this.bodyAngleAnnotation[constants.EDITOR.END]
                    this.bodyAngleAnnotation[constants.EDITOR.END] = this.bodyAngleAnnotation[constants.EDITOR.START]
                    this.bodyAngleAnnotation[constants.EDITOR.START] = temp

                    this.selectedAnnotation = this.getOtherSelectedAnnotation()
                }
            },

            setChartAnnotationsAnchors (pointTimestamp) {
                if (this.chart) {
                    if (this.selectedAnnotation === constants.EDITOR.END || this.selectedAnnotation === constants.EDITOR.START) {
                        this.applyChartAnnotationChanges(pointTimestamp)
                        this.currentTimestamp = pointTimestamp
                        this.updateTimestamps(this.selectedAnnotation, pointTimestamp)
                    } else {
                        this.currentTimestamp = pointTimestamp
                    }
                }
            },

            updateTimestamps (selectedAnnotation, pointTimestamp) {
                if (selectedAnnotation === constants.EDITOR.START) {
                    this.$emit('updateStartTimestamp', pointTimestamp)
                } else if (selectedAnnotation === constants.EDITOR.END) {
                    this.$emit('updateEndTimestamp', pointTimestamp)
                }
            },

            addSeriesSettings () {
                this.series.bodyAngle.normal().stroke({
                    color: generalSettings.chart.plots.anglePlot.series.color,
                    thickness: generalSettings.chart.plots.anglePlot.series.thickness,
                })

                this.series.bodyAngle.name(this.$t(`distributionGraphLabels.riskType.${this.selectedRiskType}`))
            },

            setDefaultStartAndEndTimestamp () {
                this.$emit('updateStartTimestamp', this.currentGraph[0][constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP])
                this.$emit('updateEndTimestamp', this.currentGraph[this.currentGraph.length - 1][constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP])
            },

            getXValueFromEvent (event) {
                const x = event.offsetX
                // plot bounds related to chart container
                const plotWidth = this.chart.plot(0).getPixelBounds().width
                // plot left margin
                const plotLeftOffset = this.chart.plot(0).getPixelBounds().left
                // is click on data area
                if (x < plotLeftOffset || x > plotLeftOffset + plotWidth) {
                    return
                }
                // get date of click related to xScale
                const ratio = (x - plotLeftOffset) / plotWidth
                const xDate = this.chart.xScale().inverseTransform(ratio)
                // get data from clicked point

                const xValue = this.getNearestTimestamp(xDate)
                return xValue
            },

            isInYBounds (event) {
                const y = event.offsetY
                const plotTopOffset = this.chart.plot(0).getPixelBounds().top
                const plotBottomHeight = this.chart.plot(0).getPixelBounds().height
                const plotBottomOffset = this.chart.plot(0).getPixelBounds().top

                const plotHeight = plotBottomHeight + plotBottomOffset
                return y >= plotTopOffset && y <= plotHeight
            },

            addListeners () {
                let that = this
                this.chart.listen('mouseMove', function (event) {
                    that.mouseMoveHandler(event)
                })

                this.chart.listen('mouseDown', function (event) {
                    that.mouseDownHandler(event)
                })

                this.chart.listen('mouseUp', function () {
                    that.mouseUpHandler()
                })

                this.chart.listen('selectedRangeChangeFinish', function (event) {
                    that.rangeChangeHandler(event)
                })

                this.chart.listen('selectedRangeChangeStart', function (event) {
                    if (event.source !== 'marquee')
                        return false
                })
            },

            mouseMoveHandler (event) {
                if (!this.disableChartInteraction) {
                    let pointIndex = this.getXValueFromEvent(event)

                    if (pointIndex === undefined || !this.isInYBounds(event)) {
                        this.stopDragging()
                    }

                    if (this.dragging) {
                        this.setChartAnnotationsAnchors(pointIndex)
                    }
                }
            },

            mouseDownHandler (event) {
                if (!this.disableChartInteraction && this.isInYBounds(event)) {
                    let pointIndex = this.getXValueFromEvent(event)

                    if (pointIndex) {
                        this.getSelectedAnnotation(pointIndex)
                        this.setChartAnnotationsAnchors(pointIndex)
                        this.startDragging()
                    }
                }

                if (!this.isInYBounds(event)) {
                    this.unselectAnnotation()
                }
            },

            mouseUpHandler () {
                if (this.dragging && !this.disableChartInteraction) {
                    this.stopDragging()
                }
            },

            roundTimeToSec (time) {
                return Math.round(time / 100000)
            },

            getOtherSelectedAnnotation () {
                if (this.selectedAnnotation === null) return

                return this.selectedAnnotation ? 0 : 1
            },

            getSelectedAnnotation (time) {
                // round to closest second to be able to easily select the annotation
                let roundedTime = this.roundTimeToSec(time)

                // select other annotation
                if (this.selectedAnnotation !== null
                    && roundedTime === this.roundTimeToSec(this.bodyAngleAnnotation[this.getOtherSelectedAnnotation()].xAnchor())) {
                        this.selectedAnnotation = this.getOtherSelectedAnnotation()
                    }

                if (roundedTime === this.roundTimeToSec(this.bodyAngleAnnotation[constants.EDITOR.START].xAnchor())) {
                    this.selectedAnnotation = constants.EDITOR.START
                    return
                }

                if (roundedTime === this.roundTimeToSec(this.bodyAngleAnnotation[constants.EDITOR.END].xAnchor())) {
                    this.selectedAnnotation = constants.EDITOR.END
                    return
                }
            },

            unselectAnnotation () {
                if (this.selectedAnnotation != null) {
                    this.bodyAngleAnnotation[this.selectedAnnotation].stroke(generalSettings.chart.annotation.strokeColor, generalSettings.chart.annotation.strokeThicknessBold)
                    this.selectedAnnotation = null
                }
            },

            rangeChangeHandler (event) {
                if (event.source === "marquee" && !this.disableChartInteraction) {
                    this.isZoomed = true
                    this.isInZoomingMode = false
                    this.zoomStart = event.firstSelected
                    this.zoomEnd = event.lastSelected

                    if (this.zoomStart < this.assessmentStart) {
                        this.zoomStart = this.assessmentStart
                    }

                    if (this.zoomEnd > this.assessmentEnd) {
                        this.zoomEnd = this.assessmentEnd
                    }
                    const range = this.zoomEnd - this.zoomStart
                    if (range <= constants.MINIMUM_ZOOM_RANGE) {
                        let rangeDifference = constants.MINIMUM_ZOOM_RANGE - range
                        this.zoomStart = this.zoomStart - (rangeDifference / 2)
                        this.zoomEnd = this.zoomEnd + (rangeDifference / 2)
                    }
                    this.requestZoomedData()
                }
            },

            turnOffZoomingMode () {
                this.isInZoomingMode = false
            },

            startDragging () {
                this.dragging = true
            },

            stopDragging () {
                this.dragging = false
            },

            async requestZoomedData () {
                this.isRequestingZoomedData = true
                EventBus.$emit('SET_DISABLE_CHART_INTERACTION', true)
                this.zoomedData = []
                try {
                    this.zoomedData = await this.$store.dispatch(
                        'getAssessmentGraphData',
                        {
                            currentCustomer: this.currentCustomer,
                            currentCompany: this.currentCompany,
                            workerId: this.workerId,
                            captureIdx: this.captureIdx,
                            metadataID: this.metadataID,
                            assessmentIdx: this.assessmentIdx,
                            motion: this.selectedRiskType,
                            cursorTime: this.normalizeTimezoneFromChart(this.zoomStart) / constants.UNIX_MILLISECONDS,
                            startTime: this.normalizeTimezoneFromChart(this.zoomStart) / constants.UNIX_MILLISECONDS,
                            endTime: this.normalizeTimezoneFromChart(this.zoomEnd) / constants.UNIX_MILLISECONDS,
                        }
                    )

                    this.zoomedData = this.zoomedData.map(point => {
                        const correctedTimestamp = this.convertPosixToLocalTime(
                            this.assessmentTimezoneOffset,
                            point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP])
                        point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP] = correctedTimestamp
                        return point
                    })

                    this.integrateZoomedData()
                } catch (error) {
                    throw new Error(error.message)
                }
                EventBus.$emit('SET_DISABLE_CHART_INTERACTION', false)
                this.isRequestingZoomedData = false
            },

            integrateZoomedData () {
                if (this.chart) {
                    this.table.remove()
                    this.table.addData(this.currentGraph)
                    this.chart.selectRange('max')
                    this.chart.draw()
                }
            },

            toggleZoomMode () {
                if (this.chart && !this.disableChartInteraction) {
                    if (this.isInZoomingMode) {
                        this.isInZoomingMode = false
                        this.chart.cancelMarquee()
                    } else {
                        this.chart.startZoomMarquee(false)
                        this.isInZoomingMode = true
                    }
                }
            },

            resetZoom () {
                if (this.isZoomed) {
                    this.isZoomed = false
                    this.zoomedData = {}
                    this.zoomStart = null
                    this.zoomEnd = null
                    this.table.remove()
                    this.table.addData(this.currentGraph)
                    this.chart.selectRange('max')
                    this.chart.draw()
                }
            },

            panRight () {
                if (this.canPanRight) {
                    this.isZoomed = true
                    this.isInZoomingMode = false
                    let canRequestFullRange = (this.currentDisplayedTimeRange + this.zoomEnd) < this.assessmentEnd
                    let nextEndTimestamp = canRequestFullRange ? this.zoomEnd + this.currentDisplayedTimeRange : this.assessmentEnd
                    this.zoomStart = this.zoomEnd
                    this.zoomEnd = nextEndTimestamp
                    this.requestZoomedData()
                }
            },

            panLeft () {
                if (this.canPanLeft) {
                    this.isZoomed = true
                    this.isInZoomingMode = false
                    let canRequestFullRange = (this.zoomStart - this.currentDisplayedTimeRange) > this.assessmentStart
                    let nextStartTimestamp = canRequestFullRange ? this.zoomStart - this.currentDisplayedTimeRange : this.assessmentStart
                    this.zoomEnd = this.zoomStart
                    this.zoomStart = nextStartTimestamp
                    this.requestZoomedData()
                }
            },

            async panUntilInsideZoomWindow (centerTimestamp) {
                const halfZoomRange = Math.round(this.currentDisplayedTimeRange / 2)

                let isWithinAssessmentStart = (centerTimestamp - halfZoomRange) > this.assessmentStart
                let isWithinAssessmentEnd = (centerTimestamp + halfZoomRange) < this.assessmentEnd

                this.zoomStart = isWithinAssessmentStart ? centerTimestamp - halfZoomRange : this.assessmentStart
                this.zoomEnd = isWithinAssessmentEnd ? centerTimestamp + halfZoomRange : this.assessmentEnd

                await this.requestZoomedData()
            },

            updateZoomLevel(df) {
                let cursorWindowOffset = (((df * constants.TIME_PERIODS.MOTION * constants.UNIX_MILLISECONDS * constants.DEFAULT_MAX_SAMPLE) + (2 * this.currentTimestamp))/2) - this.currentTimestamp

                if (df !== Math.pow(4, constants.MAX_ZOOM_FACTOR)) {
                    this.isZoomed = true
                    this.isInZoomingMode = false
                    this.zoomStart = this.currentTimestamp - cursorWindowOffset
                    this.zoomEnd = this.currentTimestamp + cursorWindowOffset

                    if (this.zoomStart < this.assessmentStart) {
                        this.zoomStart = this.assessmentStart
                    }

                    if (this.zoomEnd > this.assessmentEnd) {
                        this.zoomEnd = this.assessmentEnd
                    }

                    const range = this.zoomEnd - this.zoomStart
                    if (range <= constants.MINIMUM_ZOOM_RANGE) {
                        let rangeDifference = constants.MINIMUM_ZOOM_RANGE - range
                        this.zoomStart = this.zoomStart - (rangeDifference / 2)
                        this.zoomEnd = this.zoomEnd + (rangeDifference / 2)
                    }

                    this.requestZoomedData()
                } else {
                    this.resetZoom()
                }
            },
        },
    }
</script>

<style lang="scss">
    @import '~design';
    .sz-OffBodyTimeSelector {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        &-buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 2.5rem;
            padding-left: 1.45rem;
        }

        &-zoomInfo {
            @extend %font-topbar-heading-unselectable;
            font-size: 0.8rem;
            margin: 0.5rem;
            padding: 0.2rem;
            color: $color-white;
            background-color: $color-lifebooster-dark-green;
            opacity: 1;
        }

        &-currentTime {
            @extend %font-topbar-heading-unselectable;
            font-size: 0.8rem;
            margin: 0.5rem 0 0.5rem 0;
            padding: 0.2rem;
            padding-left: 0.5rem;
            color: $color-white;
            background-color: $color-table-dark-background;
            opacity: 1;

            &-cursor {
                @extend %font-topbar-heading-unselectable;
                font-size: 0.8rem;
                margin: 0.5rem 0.5rem 0.5rem 0;
                padding: 0.2rem;
                color: $color-white;
                background-color: $color-table-dark-background;
                opacity: 1;
                min-width: 3.5rem;
            }
        }

        &-chart {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            position: relative;

            &-middle {
                height: 100%;
                width: 100%;
            }

            &-loader {
                position: absolute;
                width: calc(100% - 8rem);
                right: 2.6rem;
                height: calc(100% - 1.79rem);
                z-index: 10;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: lighten($color-body-bg, 10%);
                opacity: 0.7;

                &-spinner {
                    z-index: 15;
                }
            }

            &-panController {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            &-panControllerIcon {
                color: darken($color-white, 80%);
                cursor: default;

                &.left {
                    transform: rotate(90deg)
                }

                &.right {
                    transform: rotate(-90deg)
                }

                &.enabled {
                    color: $color-white;
                    cursor: pointer;
                }
            }
        }
    }
    .sz-Chart {
        height: 100%;
        width: 100%;
    }

    .anychart-plot-controls {
        visibility: hidden
    }
</style>
