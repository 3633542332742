<template lang="pug">
    div(class="sz-RightPanelThermal") 
        div(
            class="sz-RightPanelThermal-button",
            @click="changeTempScale") {{ tempScaleButton }}
</template>

<script>
    import EventBus from 'src/eventBus'
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'

    export default {
        name: 'RightPanelThermal',
        
        data () {
            return {
                curTempScale: constants.THERMAL_TEMPERATURE_TYPES.SCALE.CELSIUS,
            }
        },
        
        computed: {
            ...mapGetters([
                'selectedAssessment',
            ]),

            tempScaleButton () {
                return (this.curTempScale === constants.THERMAL_TEMPERATURE_TYPES.SCALE.CELSIUS) 
                    ? this.$t('thermalAssessment.showFahrenheit')
                    : this.$t('thermalAssessment.showCelsius')
            },
        },

        methods: {
            changeTempScale () {
                this.curTempScale = (this.curTempScale === constants.THERMAL_TEMPERATURE_TYPES.SCALE.CELSIUS) 
                    ? constants.THERMAL_TEMPERATURE_TYPES.SCALE.FAHRENHEIT
                    : constants.THERMAL_TEMPERATURE_TYPES.SCALE.CELSIUS
                EventBus.$emit('SET_THERMAL_SCALE', this.curTempScale)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-RightPanelThermal {
        width: 100%;
        font-size: 0.75rem;
        &-button {
            @extend %button-layout;
            @extend %font-heading;
            background-color: $color-lifebooster-light-green;
            padding: 0.5rem 1rem;
            margin-bottom: 0.5rem;
            border: 0;
            border-radius: 0;
            color: white;
            display: flex;
            justify-content: center;
        }
    }

</style>