<template lang="pug">
    div(class="sz-SensorSelector")
        div(class="sz-SensorSelector-container")
            div(class="sz-SensorSelector-container-title") {{ $t('motionAssessment.editor.repair.sensorPos') }}
            div(
              v-for="sensor in sensors",
              :key="sensor",
              @click.stop="selectSensor(sensor)",
              class="sz-SensorSelector-container-sensors",
              :class="`sz-SensorSelector-container-sensors` + (isSelected(sensor) ? '-selected' : '')") {{ sensor }}
</template>

<script>
    import constants from 'helpers/constants'

    export default {
        name: 'SensorSelector',

        data () {
            return {
                selectedSensor: constants.DEVICE_STATUS_CHARACTERISTICS.RFA,
            }
        },

        computed: {
            sensors () {
                return Object.keys(constants.SENSOR_RISK_TYPE_MAP)
            },
        },

        methods: {
            isSelected(sensor) {
                return this.selectedSensor === sensor
            },

            selectSensor(sensor) {
                this.selectedSensor = sensor
                this.$emit('updateSelectedSensor', this.selectedSensor)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";

    .sz-SensorSelector {
        &-container {
            display: flex;
            flex-direction: column;

            &-title {
                @extend %font-heading;
            }

            &-sensors {
                @extend %font-heading;
                font-weight: 100;
                font-size: 15px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1.5px solid $color-white;
                height: 2.2rem;
                margin: 0.5rem 0rem;
                color: $color-white;
                cursor: pointer;

                &-selected, &:hover {
                    background-color: $color-lifebooster-medium-green;
                    border: 1.5px solid $color-lifebooster-medium-green;
                }
            }
        }
    }
</style>
