<template lang="pug">
    div(class="sz-DailyNote")
        div(class="sz-DailyNote-box")
            div(class="sz-DailyNote-title") {{ $t('motionAssessment.notes') }}
            div(class="sz-DailyNote-content")
                div(class="sz-DailyNote-content-text") Worker was driving a truck during this capture

</template>
<script>
    // TODO: actually create a notes section when this is supported by the BE
    export default {
        name: "DailyNote",
    }
</script>
<style lang="scss" scoped>
    @import '~design';
    .sz-DailyNote {
        display: flex;

        &-box {
            padding: 0.5rem 0.7rem;
            background-color: $color-box-header;
            height: 100%;
            width: 100%;
        }

        &-title {
            @extend %font-topbar-heading-unselectable;
            font-size: 12px;
            text-align: left;
        }

        &-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            max-height: 100%;
            min-height: 0;
            overflow-y: auto;
            @include scrollbar-widget;

            &-text {
                @extend %font-content;
                text-align: left;
                font-size: 12px;
            }
        }
    }
</style>
