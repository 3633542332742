<template lang="pug">
    transition(name="fade")
        div(class="sz-AssessmentChartsGroup")
            div(class="sz-AssessmentChartsGroup-buttons")
                div(class="sz-AssessmentChartsGroup-currentTime") {{ "Cursor Time:" }}
                div(class="sz-AssessmentChartsGroup-currentTime-cursor") {{ currentTime }}
                ZoomButtons(
                    v-if="!loadingGraph",
                    @updateZoom="updateZoomLevel")
                div(v-if="isZoomed")
                    span(
                        v-tooltip="zoomStartTooltip",
                        id="zoom-left-info",
                        class="sz-AssessmentChartsGroup-zoomInfo") {{ zoomStartTimezoneCorrected }}
                    span() -
                    span(
                        v-tooltip="zoomEndTooltip",
                        id="zoom-right-info",
                        class="sz-AssessmentChartsGroup-zoomInfo") {{ zoomEndTimezoneCorrected }}
            div(class="sz-AssessmentChartsGroup-chart")
                div(
                    @click="panLeft"
                    class="sz-AssessmentChartsGroup-chart-panController")
                    SvgIcon(
                        icon="arrow",
                        width="35",
                        height="35",
                        id="panLeft"
                        v-tooltip="panLeftTooltip"
                        class="sz-AssessmentChartsGroup-chart-panControllerIcon left",
                        :class=`{"enabled": canPanLeft}`)
                div(class="sz-AssessmentChartsGroup-chart-middle")
                    div(
                        v-if="isRequestingZoomedData"
                        class="sz-AssessmentChartsGroup-chart-loader")
                        LoadingSpinner(class="sz-AssessmentChartsGroup-chart-loader-spinner")
                    div(
                        ref="chart",
                        class="sz-Chart")
                div(
                    @click="panRight"
                    class="sz-AssessmentChartsGroup-chart-panController panRight")
                    SvgIcon(
                        icon="arrow",
                        width="35",
                        height="35",
                        id="panRight"
                        v-tooltip="panRightTooltip"
                        class="sz-AssessmentChartsGroup-chart-panControllerIcon right",
                        :class=`{"enabled": canPanRight}`)
</template>

<script>
    import Anychart from 'anychart'
    import { generalSettings,
        thresholdSettings,
        riskSettings,
        vibrationSettings } from 'helpers/assessmentGraph'
    import { bSearch } from 'helpers/utilities'
    import EventBus from 'src/eventBus'
    import motionAssessmentUtils from 'mixins/motionAssessment'
    import constants from 'helpers/constants'
    import { tooltip } from 'directives/tooltip'
    import SvgIcon from 'components/Shared/SvgIcon'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'
    import ZoomButtons from 'components/Assessment/ZoomButtons'

    export default {
        name: 'VibrationChartGroup',

        components: {
            SvgIcon,
            LoadingSpinner,
            ZoomButtons,
        },

        directives: {
            tooltip,
        },

        mixins: [
            motionAssessmentUtils,
        ],

        props: {
            annotationPosition: {
                required: false,
                type: Number,
                default: undefined,
            },

            graph: {
                required: true,
                type: Object,
            },

            loadingGraph: {
                required: true,
                type: Boolean,
            },

            assessmentTimezoneOffset: {
                required: true,
                type: Number,
            },

            jointKeys: {
                required: true,
                type: Object,
            },

            currentCustomer: {
                required: true,
                type: String,
            },

            currentCompany: {
                required: true,
                type: String,
            },

            workerId: {
                required: true,
                type: String,
            },

            captureIdx: {
                required: true,
                type: String,
            },

            metadataID: {
                required: false,
                type: String,
            },

            assessmentIdx: {
                required: true,
                type: String,
            },

            assessmentStart: {
                required: true,
                type: Number,
            },

            assessmentEnd: {
                required: true,
                type: Number,
            },

            assessmentTimezone: {
                required: true,
                type: String,
            },

            selectedRisks: {
                required: true,
                type: Array,
            },

            selectedAssessment: {
                required: true,
                type: Object,
            },
        },

        data() {
            return {
                chart: null,
                options: null,
                table: {},
                mappings: {
                    a8: {},
                    riskEvents: {},
                },
                series: {
                    a8: {},
                    riskEvents: {},
                },
                a8Annotation: null,
                riskAnnotation: null,
                dragging: false,
                annotationLocation: null,
                annotationIndex: null,
                disableChartInteraction: false,
                xTextMarker: null,
                isZoomed: false,
                isInZoomingMode: false,
                isRequestingZoomedData: false,
                zoomStart: null,
                zoomEnd: null,
                zoomedData: {},
                currentTime: 0,
                currentTimestamp: 0,
            }
        },

        computed: {
            currentGraph () {
                let graph = false
                if (this.graph) {
                    graph = this.graph
                }
                if (this.isZoomed) {
                    graph = this.zoomedData
                }
                return graph
            },

            canPanLeft () {
                return this.isZoomed
                    && this.zoomStart > this.assessmentStart
                    && !this.isRequestingZoomedData
                    && !this.disableChartInteraction
            },

            canPanRight () {
                return this.isZoomed
                    && this.zoomEnd < this.assessmentEnd
                    && !this.isRequestingZoomedData
                    && !this.disableChartInteraction
            },

            canZoom () {
                let canZoom = true
                let firstTimestamp = this.assessmentStart
                let lastTimestamp = this.assessmentEnd

                if (this.isZoomed) {
                    firstTimestamp = this.zoomStart
                    lastTimestamp = this.zoomEnd
                }
                const currentRange = lastTimestamp - firstTimestamp
                const currentRangeIsWideEnough = currentRange >= (constants.MINIMUM_ZOOM_RANGE)
                return currentRangeIsWideEnough && !this.disableChartInteraction
            },

            canZoomOut () {
                return this.isZoomed && !this.disableChartInteraction
            },

            currentDisplayedTimeRange () {
                if (this.zoomEnd && this.zoomStart) {
                    return this.zoomEnd - this.zoomStart
                }
                return this.asssessmentStart - this.assessmentEnd
            },

            zoomStartTimezoneCorrected () {
                return this.timestampFormatted(this.normalizeTimezoneFromChart(this.zoomStart))
            },

            zoomEndTimezoneCorrected () {
                return this.timestampFormatted(this.normalizeTimezoneFromChart(this.zoomEnd))
            },

            zoomStartTooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'center',
                            padding: 'extra-padding',
                        },
                        title: this.$t('motionAssessment.graphLabel.zoomStart'),
                    },

                    body: {
                        placement: 'bottom-right',
                        offset: '100, -58',
                    },
                    allowHover: true,
                }
            },

            zoomEndTooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'center',
                            padding: 'extra-padding',
                        },
                        title: this.$t('motionAssessment.graphLabel.zoomEnd'),
                    },

                    body: {
                        placement: 'bottom-right',
                        offset: '100, -58',
                    },
                    allowHover: true,
                }
            },

            panRightTooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'center',
                            padding: 'extra-padding',
                        },
                        title: this.$t('motionAssessment.graphLabel.panRight'),
                    },

                    body: {
                        placement: 'bottom-right',
                        offset: '80, -60',
                    },
                    allowHover: false,
                    disabled: !this.canPanRight,
                }
            },

            panLeftTooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'center',
                            padding: 'extra-padding',
                        },
                        title: this.$t('motionAssessment.graphLabel.panLeft'),
                    },

                    body: {
                        placement: 'bottom-right',
                        offset: '80, -60',
                    },
                    allowHover: false,
                    disabled: !this.canPanLeft,
                }
            },
        },

        watch: {
            options: function (options) {
                if (!this.chart && options) {
                    this.init()
                } else {
                    this.chart.dispose()
                    this.chart = null
                    this.init()
                }
            },
        },

        mounted() {
            if (!this.chart) {
                this.init()
            }

            if (this.annotationPosition && this.selectedRisks.length > 0) {
                this.setChartAnnotationsAnchors(this.annotationPosition, true)
            }

            EventBus.$on('UPDATE_ANNOTATION_POSITION', (timestamp) => {
                this.setChartAnnotationsAnchors(timestamp, true)
            })

            EventBus.$on('SET_ANNOTATION_TO_NEXT_EVENT', () => {
                this.setChartAnnotationToNextEvent()
            })

            EventBus.$on('SET_ANNOTATION_TO_PREVIOUS_EVENT', () => {
                this.setChartAnnotationToPreviousEvent()
            })

            EventBus.$on('SET_DISABLE_CHART_INTERACTION', (disabled) => {
                this.disableChartInteraction = disabled
            })
        },


        beforeDestroy() {
            EventBus.$emit('CLEAR_GRAPH_DATA')

            if (this.chart) {
                this.chart.dispose()
                this.chart = null
            }

            EventBus.$off('UPDATE_ANNOTATION_POSITION')

            EventBus.$off('SET_ANNOTATION_TO_NEXT_EVENT')

            EventBus.$off('SET_ANNOTATION_TO_PREVIOUS_EVENT')

            EventBus.$off('SET_DISABLE_CHART_INTERACTION', () => {
                this.disableChartInteraction = true
            })
        },

        methods: {
            delegateMethod(name, data) {
                if (!this.chart) {
                    warn(`Cannot call [$name] before the chart is initialized. Set prop [options] first.`, this)
                    return
                }
                return this.chart[name](data)
            },

            init() {
                if (!this.chart) {
                    Anychart.licenseKey(process.env.VUE_APP_ANYCHART_LICENSE)
                    // Timezone correction has already been performed in the main component, so we can set the offset to 0
                    // This is call ensures the initial page load displays the correct timestamps in the x-axis
                    Anychart.format.outputTimezone(0)
                    this.chart = Anychart.stock()
                    if (this.chart && Object.keys(this.currentGraph).length > 0) {
                        this.addData()
                        this.addLicenseKey()
                        this.addMappings()
                        this.addSeries()
                        this.addGeneralSettings()
                        this.addThresholdLines()
                        this.addVerticalLine()
                        this.addListeners()
                        this.addSeriesSettings()
                        this.chart.container(this.$refs.chart).draw()
                    }
                }
            },

            addData () {
                this.selectedRisks.map(risk => {
                    let table = Anychart.data.table()
                    this.table[risk] = table.addData(this.currentGraph[risk])
                })
            },

            addLicenseKey () {
                let credits = this.chart.credits()
                credits.enabled(false)
            },

            addMappings () {
                this.selectedRisks.map(risk => {
                    this.mappings.a8[risk] = this.table[risk].mapAs({
                        x: constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP,
                        value: constants.ASSESSMENT_GRAPH_INDICES.PRIMARY_VALUE})
                    this.mappings.riskEvents[risk] = this.table[risk].mapAs({ value: 5, status: 6 })
                })
            },

            addSeries () {
                this.selectedRisks.map(risk => {
                    this.series.a8[risk] = this.chart.plot(1).line(this.mappings.a8[risk])
                    this.series.a8[risk].connectMissingPoints(true)
                    this.series.riskEvents[risk] = this.chart.plot(0).line(this.mappings.riskEvents[risk])
                    this.series.riskEvents[risk].connectMissingPoints(true)
                })
            },

            addGeneralSettings () {
                // it would be better to have all these settings in an object and then create the chart
                // using those settings as a JSON object instead of calling each individual method here, but unfortunately
                // AnyStock does not have support for this functionality at this point (v8.0.1).
                this.chart.width(generalSettings.chart.width)
                this.chart.height(generalSettings.chart.height)
                this.chart.padding(
                    generalSettings.chart.paddingTop,
                    generalSettings.chart.paddingRight,
                    generalSettings.chart.paddingBottom,
                    generalSettings.chart.paddingLeft)
                this.chart.background().fill(generalSettings.chart.background)
                this.chart.splitters().enabled(generalSettings.chart.splitters.enabled)
                this.chart.tooltip().enabled(generalSettings.chart.tooltip.enabled)
                this.chart.xScale().ticksCount(generalSettings.chart.xScale.ticks.length)

                this.chart.grouping().enabled(false)

                const scroller = this.chart.scroller()
                scroller.enabled(false)

                const riskPlot = this.chart.plot(0)
                const anglePlot = this.chart.plot(1)

                anglePlot.dataArea().background().enabled(generalSettings.chart.dataArea.enabled)
                anglePlot.dataArea().background().fill(generalSettings.chart.dataArea.fill)
                riskPlot.dataArea().background().enabled(generalSettings.chart.dataArea.enabled)
                riskPlot.dataArea().background().fill(generalSettings.chart.dataArea.fill)

                this.chart.crosshair(false)

                riskPlot.height(generalSettings.chart.plots.riskPlot.height)
                anglePlot.height(generalSettings.chart.plots.anglePlot.height)

                let angleTitle = anglePlot.title()
                angleTitle.enabled(generalSettings.chart.plots.anglePlot.title.enabled)
                angleTitle.fontSize(generalSettings.chart.plots.anglePlot.title.fontSize)
                angleTitle.text(generalSettings.chart.plots.anglePlot.title.text)

                anglePlot.legend().enabled(true)
                anglePlot.legend().title().useHtml(true)
                anglePlot.legend().titleFormat(
                  "<span></span>"
                )
                anglePlot.legend().itemsFormat(
                  "{%seriesName}"
                )
                anglePlot.legend().align('right')
                anglePlot.legend().positionMode('inside')
                anglePlot.legend().itemsLayout('vertical-expandable')
                anglePlot.legend().tooltip(true)
                anglePlot.legend().textOverflow('...')
                anglePlot.legend().fontColor(generalSettings.chart.textMarker.fontColor)
                riskPlot.legend().enabled(generalSettings.chart.plots.anglePlot.legend.enabled)

                let angleXAxis = anglePlot.xAxis()
                let angleYAxis = anglePlot.yAxis()

                angleXAxis.labels().format(generalSettings.chart.crosshair.xLabel.format)
                angleXAxis.minorLabels().format(generalSettings.chart.crosshair.xLabel.format)
                angleXAxis.showHelperLabel(false)
                angleYAxis.labels().fontColor(generalSettings.chart.plots.anglePlot.yAxes[0].labels.fontColor)
                angleYAxis.labels().fontFamily(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontFamily)
                angleYAxis.labels().fontSize(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontSize)
                angleYAxis.labels().fontWeight(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontWeight)
                angleYAxis.stroke(generalSettings.chart.background)
                angleYAxis.ticks().stroke(generalSettings.chart.plots.anglePlot.xScale.fill)

                let angleYAxisTitle = angleYAxis.title()
                angleYAxis.title(this.stringConverter(vibrationSettings.plot.yAxis.title, constants.RISK_MODULES.VIBRATION))
                angleYAxisTitle.fontSize(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontSize)
                angleYAxisTitle.fontWeight(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontWeight)
                angleYAxisTitle.fontFamily(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontFamily)
                angleYAxisTitle.fontColor(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontColor)

                let riskXAxis = riskPlot.xAxis()
                let riskYAxis = riskPlot.yAxis()
                riskXAxis.enabled(generalSettings.chart.plots.riskPlot.xAxis.enabled)
                riskYAxis.labels().enabled(generalSettings.chart.plots.riskPlot.yAxis.labels.enabled)
                riskYAxis.ticks().enabled(generalSettings.chart.plots.riskPlot.yAxis.ticks.enabled)

                let riskYAxisTitle = riskYAxis.title()
                riskYAxis.title(this.stringConverter(generalSettings.chart.plots.riskPlot.yAxis.title).toUpperCase())
                riskYAxisTitle.fontSize(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontSize)
                riskYAxisTitle.fontWeight(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontWeight)
                riskYAxisTitle.fontFamily(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontFamily)
                riskYAxisTitle.fontColor(generalSettings.chart.plots.anglePlot.yAxes[0].title.fontColor)

                riskPlot.yScale().minimum(generalSettings.chart.plots.riskPlot.yScale.minimum)
                riskPlot.yScale().maximum(generalSettings.chart.plots.riskPlot.yScale.maximum)
                anglePlot.yScale().minimum(0)
            },

            addVerticalLine () {
                this.riskAnnotation = this.chart.plot(0).annotations().verticalLine()
                this.a8Annotation = this.chart.plot(1).annotations().verticalLine()

                this.riskAnnotation.allowEdit(generalSettings.chart.annotation.allowEdit)
                this.a8Annotation.allowEdit(generalSettings.chart.annotation.allowEdit)
                this.riskAnnotation.stroke(generalSettings.chart.annotation.strokeColor, generalSettings.chart.annotation.strokeThickness)
                this.a8Annotation.stroke(generalSettings.chart.annotation.strokeColor, generalSettings.chart.annotation.strokeThickness)

                this.annotationIndex
                    ? this.setChartAnnotationsAnchors(this.annotationIndex)
                    : this.setChartAnnotationsAnchors(0)
            },

            addThresholdLines () {
                let yellowThresholdLine = this.chart.plot(1).lineMarker(0)
                let orangeThresholdLine = this.chart.plot(1).lineMarker(1)
                let redThresholdLine = this.chart.plot(1).lineMarker(2)

                yellowThresholdLine
                    .value(constants.VIBRATION_THRESHOLD_VALUES.YELLOW)
                    .scaleRangeMode(thresholdSettings.scaleRangeMode)
                    .stroke({
                        color: thresholdSettings.thermalColors.yellow,
                        thickness: thresholdSettings.thermalThickness.thickness,
                    })

                orangeThresholdLine
                    .value(constants.VIBRATION_THRESHOLD_VALUES.ORANGE)
                    .scaleRangeMode(thresholdSettings.scaleRangeMode)
                    .stroke({
                        color: thresholdSettings.thermalColors.orange,
                        thickness: thresholdSettings.thermalThickness.thickness,
                    })

                redThresholdLine
                    .value(constants.VIBRATION_THRESHOLD_VALUES.RED)
                    .scaleRangeMode(thresholdSettings.scaleRangeMode)
                    .stroke({
                        color: thresholdSettings.thermalColors.red,
                        thickness: thresholdSettings.thermalThickness.thickness,
                    })
            },

            setChartAnnotationsAnchors (pointIndex, useTimestamp = false, risk = null) {
                if ((pointIndex !== undefined || pointIndex !== null) && !this.isRequestingZoomedData) {
                    let pointTimestamp
                    let pointValues = {}
                    let pointDialog = {}
                    if (useTimestamp) {
                        pointTimestamp = pointIndex
                        if (this.isZoomed && pointTimestamp > this.zoomEnd) {
                            this.panUntilInsideZoomWindow(pointTimestamp)
                            return
                        }
                        if (this.isZoomed && pointTimestamp < this.zoomStart) {
                            this.panUntilInsideZoomWindow(pointTimestamp)
                            return
                        }

                        if (risk) {
                            let riskIndex = bSearch(
                                this.currentGraph[risk], (point) => {
                                    return point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP] < pointTimestamp
                                }
                            )
                            this.annotationIndex = riskIndex
                            pointValues = this.currentGraph[risk][riskIndex]
                            pointDialog = this.selectedRisks.map(risk => {
                                if (this.currentGraph[risk][riskIndex][constants.ASSESSMENT_GRAPH_INDICES.EVENT_DESCRIPTIONS]) {
                                    return this.currentGraph[risk][riskIndex][constants.ASSESSMENT_GRAPH_INDICES.EVENT_DESCRIPTIONS]
                                }
                            })
                        } else {
                            const searchedIndex = bSearch(
                                this.currentGraph[this.selectedRisks[0]], (point) => {
                                    return point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP] < pointTimestamp}
                                )
                            this.annotationIndex = searchedIndex
                            pointValues = this.currentGraph[this.selectedRisks[0]][searchedIndex]
                            pointDialog = this.selectedRisks.map(risk => {
                                if (this.currentGraph[risk][searchedIndex][constants.ASSESSMENT_GRAPH_INDICES.EVENT_DESCRIPTIONS]) {
                                    return this.currentGraph[risk][searchedIndex][constants.ASSESSMENT_GRAPH_INDICES.EVENT_DESCRIPTIONS]
                                }
                            })
                        }
                        if (!this.disableChartInteraction) {
                            this.emitDialog(pointDialog)
                            EventBus.$emit('UPDATE_RISK_SELECTOR', pointValues[constants.ASSESSMENT_GRAPH_INDICES.RISK_TYPE_VALUES])
                            EventBus.$emit('UPDATE_ANIMATION_START_TIME', pointTimestamp)
                            EventBus.$emit('UPDATE_CURRENT_ANGLES',
                                this.selectedRisks.map(selectedRisk => {
                                    if (selectedRisk === risk) {
                                        return pointValues[constants.ASSESSMENT_GRAPH_INDICES.PRIMARY_VALUE]
                                    } else if (this.currentGraph[selectedRisk][this.annotationIndex][constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP] === pointTimestamp) {
                                        return this.currentGraph[selectedRisk][this.annotationIndex][constants.ASSESSMENT_GRAPH_INDICES.PRIMARY_VALUE]
                                    } else {
                                        return 'N/A'
                                    }
                                }))
                        }

                        this.annotationLocation = pointTimestamp
                        if (this.chart) {
                            this.a8Annotation.xAnchor(pointTimestamp)
                            this.riskAnnotation.xAnchor(pointTimestamp)
                        }

                        this.setCurrentTime(pointTimestamp)
                        this.currentTimestamp = pointTimestamp

                        return
                    } else {
                        this.selectedRisks.map(risk => {
                          pointValues[risk] = this.currentGraph[risk][pointIndex]
                        })
                        pointTimestamp = pointValues[this.selectedRisks[0]][constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP]
                        this.annotationIndex = pointIndex
                        pointDialog = this.selectedRisks.map(risk => {
                            if (pointValues[risk][constants.ASSESSMENT_GRAPH_INDICES.EVENT_DESCRIPTIONS]) {
                                return pointValues[risk][constants.ASSESSMENT_GRAPH_INDICES.EVENT_DESCRIPTIONS]
                            }
                        })
                    }

                    this.annotationLocation = pointTimestamp
                    if (this.chart) {
                        this.a8Annotation.xAnchor(pointTimestamp)
                        this.riskAnnotation.xAnchor(pointTimestamp)
                    }

                    this.setCurrentTime(pointTimestamp)
                    this.currentTimestamp = pointTimestamp

                    if (!this.disableChartInteraction) {
                        this.emitDialog(pointDialog)
                        EventBus.$emit('UPDATE_RISK_SELECTOR', pointValues[this.selectedRisks[0]][constants.ASSESSMENT_GRAPH_INDICES.RISK_TYPE_VALUES])
                        EventBus.$emit('UPDATE_ANIMATION_START_TIME', pointTimestamp)
                        EventBus.$emit('UPDATE_CURRENT_ANGLES',
                            this.selectedRisks.map(risk => {
                                if (pointValues[risk][constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP] === pointTimestamp) {
                                    return pointValues[risk][constants.ASSESSMENT_GRAPH_INDICES.PRIMARY_VALUE]
                                } else {
                                    return 'N/A'
                                }
                            }))
                    }
                }
            },

            addSeriesSettings () {
                this.selectedRisks.map(risk => {
                    this.series.riskEvents[risk].stroke(function () {
                        const status = this.iterator.get('status')
                        switch (status) {
                            case 0:
                                return `${riskSettings.thickness} ${riskSettings.greenRiskEvent.normal.fill.color}`
                            case 1:
                                return `${riskSettings.thickness} ${riskSettings.yellowRiskEvent.normal.fill.color}`
                            case 2:
                                return `${riskSettings.thickness} ${riskSettings.orangeRiskEvent.normal.fill.color}`
                            case 3:
                                return `${riskSettings.thickness} ${riskSettings.redRiskEvent.normal.fill.color}`
                        }
                    })

                    this.series.a8[risk].name(this.$t(`distributionGraphLabels.riskType.${risk}`))
                })
            },

            getXValueFromEvent (event) {
                // x hover coordinate on plot
                const x = event.offsetX
                // plot bounds related to chart container
                const plotWidth = this.chart.plot(0).getPixelBounds().width
                // plot left margin
                const plotLeftOffset = this.chart.plot(0).getPixelBounds().left
                // is click on data area
                if (x < plotLeftOffset || x > plotLeftOffset + plotWidth) {
                    return
                }

                // get date of click related to xScale
                const ratio = (x - plotLeftOffset) / plotWidth
                const xDate = this.chart.xScale().inverseTransform(ratio)

                // get data from clicked point
                const selectable = this.mappings.a8[this.selectedRisks[0]].createSelectable()

                selectable.selectAll()
                const select = selectable.search(xDate, "nearest")
                // return point date (in milliseconds)
                return select.getIndex()
            },

            setTextLabelsFromEvent (angle, time) {
                const formattedTime = this.timestampFormatted(this.normalizeTimezoneFromChart(time))
                this.xTextMarker.value(time)
                this.xTextMarker.text(`${formattedTime}\n${angle}\u00B0`)
            },

            isInYBounds (event) {
                const y = event.offsetY
                const plotTopOffset = this.chart.plot(0).getPixelBounds().top
                const plotBottomHeight = this.chart.plot(1).getPixelBounds().height
                const plotBottomOffset = this.chart.plot(1).getPixelBounds().top

                const plotHeight = plotBottomHeight + plotBottomOffset

                return y >= plotTopOffset && y <= plotHeight
            },


            addListeners () {
                let that = this
                this.chart.listen('mouseMove', function (event) {
                    that.mouseMoveHandler(event)
                })

                this.chart.listen('mouseDown', function (event) {
                    that.mouseDownHandler(event)
                })

                this.chart.listen('mouseUp', function () {
                    that.mouseUpHandler()
                })

                this.chart.listen('selectedrangechange', function (event) {
                    that.rangeChangeHandler(event)
                })
            },

            mouseMoveHandler (event) {
                if (!this.disableChartInteraction) {
                    let pointIndex = this.getXValueFromEvent(event)

                    if (this.dragging) {
                        this.setChartAnnotationsAnchors(pointIndex)
                    }

                    if (pointIndex === undefined || !this.isInYBounds(event)) {
                        this.stopDragging()
                    }
                }
            },

            mouseDownHandler (event) {
                if (!this.disableChartInteraction) {
                    let pointIndex = this.getXValueFromEvent(event)
                    if (pointIndex) {
                        this.setChartAnnotationsAnchors(pointIndex)
                        this.startDragging()
                    }
                }
            },

            mouseUpHandler () {
                if (this.dragging && !this.disableChartInteraction) {
                    this.stopDragging()
                }
            },

            rangeChangeHandler (event) {
                if (event.source === "marquee" && !this.disableChartInteraction) {
                    this.isZoomed = true
                    this.isInZoomingMode = false
                    this.zoomStart = event.firstSelected
                    this.zoomEnd = event.lastSelected

                    if (this.zoomStart < this.assessmentStart) {
                        this.zoomStart = this.assessmentStart
                    }

                    if (this.zoomEnd > this.assessmentEnd) {
                        this.zoomEnd = this.assessmentEnd
                    }
                    const range = this.zoomEnd - this.zoomStart
                    if (range <= constants.MINIMUM_ZOOM_RANGE) {
                        let rangeDifference = constants.MINIMUM_ZOOM_RANGE - range
                        this.zoomStart = this.zoomStart - (rangeDifference / 2)
                        this.zoomEnd = this.zoomEnd + (rangeDifference / 2)
                    }
                    this.requestZoomedData()
                }
            },

            turnOffZoomingMode () {
                this.isInZoomingMode = false
            },

            startDragging () {
                this.dragging = true
            },

            stopDragging () {
                this.dragging = false
            },

            async requestZoomedData () {
                this.isRequestingZoomedData = true
                EventBus.$emit('SET_DISABLE_CHART_INTERACTION', true)
                this.zoomedData = {}
                try {
                    await Promise.all(
                        this.selectedRisks.map(async(risk) => {
                            let data = await this.$store.dispatch(
                                'getAssessmentGraphData',
                                {
                                    currentCustomer: this.currentCustomer,
                                    currentCompany: this.currentCompany,
                                    workerId: this.workerId,
                                    captureIdx: this.captureIdx,
                                    metadataID: this.metadataID,
                                    assessmentIdx: this.assessmentIdx,
                                    motion: risk,
                                    cursorTime: this.normalizeTimezoneFromChart(this.zoomStart) / constants.UNIX_MILLISECONDS,
                                    startTime: this.normalizeTimezoneFromChart(this.zoomStart) / constants.UNIX_MILLISECONDS,
                                    endTime: this.normalizeTimezoneFromChart(this.zoomEnd) / constants.UNIX_MILLISECONDS,
                                }
                            )

                            this.zoomedData[risk] = this.parseRiskEvents(data, this.jointKeys[risk], this.selectedRisks.length, this.selectedRisks.indexOf(risk))
                                .map((point) => {
                                    const correctedTimestamp = this.convertPosixToLocalTime(
                                        this.assessmentTimezoneOffset,
                                        point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP])
                                    point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP] = correctedTimestamp
                                    return point
                                })
                        })
                    )
                    this.integrateZoomedData()
                } catch (error) {
                    throw new Error(error.message)
                }
                EventBus.$emit('SET_DISABLE_CHART_INTERACTION', false)
                this.isRequestingZoomedData = false
            },

            integrateZoomedData () {
                if (this.chart) {
                    this.selectedRisks.map(risk => {
                      this.table[risk].remove()
                      this.table[risk].addData(this.currentGraph[risk])
                    })
                    this.chart.selectRange('max')
                    this.chart.draw()
                }
            },

            toggleZoomMode () {
                if (this.chart && !this.disableChartInteraction) {
                    if (this.isInZoomingMode) {
                        this.isInZoomingMode = false
                        this.chart.cancelMarquee()
                    } else {
                        this.chart.startZoomMarquee(false)
                        this.isInZoomingMode = true
                    }
                }
            },

            resetZoom () {
                if (this.isZoomed) {
                    this.isZoomed = false
                    this.zoomedData = {}
                    this.zoomStart = null
                    this.zoomEnd = null
                    this.selectedRisks.map(risk => {
                        this.table[risk].remove()
                        this.table[risk].addData(this.currentGraph[risk])
                    })
                    this.chart.selectRange('max')
                    this.chart.draw()
                }
            },

            panRight () {
                if (this.canPanRight) {
                    this.isZoomed = true
                    this.isInZoomingMode = false
                    let canRequestFullRange = (this.currentDisplayedTimeRange + this.zoomEnd) < this.assessmentEnd
                    let nextEndTimestamp = canRequestFullRange ? this.zoomEnd + this.currentDisplayedTimeRange : this.assessmentEnd
                    this.zoomStart = this.zoomEnd
                    this.zoomEnd = nextEndTimestamp
                    this.requestZoomedData()
                }
            },

            panLeft () {
                if (this.canPanLeft) {
                    this.isZoomed = true
                    this.isInZoomingMode = false
                    let canRequestFullRange = (this.zoomStart - this.currentDisplayedTimeRange) > this.assessmentStart
                    let nextStartTimestamp = canRequestFullRange ? this.zoomStart - this.currentDisplayedTimeRange : this.assessmentStart
                    this.zoomEnd = this.zoomStart
                    this.zoomStart = nextStartTimestamp
                    this.requestZoomedData()
                }
            },

            emitDialog (pointInfo) {
                if (pointInfo) {
                    EventBus.$emit('UPDATE_DIALOG_BOX', pointInfo)
                }
            },

            async setChartAnnotationToNextEvent () {
                // get a shallow copy of all of the timestamps from the currently selected risk types of the capture
                // to the end of the assessment
                let remainingTimestamps = {}
                this.selectedRisks.map(risk => {
                    remainingTimestamps[risk] = this.currentGraph[risk].slice(this.annotationIndex + 1, this.currentGraph.length)
                })

                const initialRemainingTimestampsLength = remainingTimestamps[this.selectedRisks[0]].length

                if (this.isZoomed) {
                    this.selectedRisks.map(risk => {
                        remainingTimestamps[risk].push(...this.graph[risk].slice(this.findIndexOfZoomEndInGraph(risk), this.graph[risk].length))
                    })
                }

                // loop through those remaining indices until you find the next risk event, return from the function
                // if a next risk event is located. If none are located, do not move the annotation.
                // Note: The first selected risk type will always take precident if multiple risk types are selected

                // There are a set of remaining timestamps for each selected risk type.
                // For a DF larger than 1, these timestamps are not guaranteed to be the same at each index.
                // So for each risk type, we need to iterate through the timestamps independently and determine at each index
                // whether there is a risky event that needs to be panned to and annotated
                for (let i = 0; i<remainingTimestamps[this.selectedRisks[0]].length - 1; i++) {
                    for (let j = 0; j<this.selectedRisks.length; j++) {
                        if (remainingTimestamps[this.selectedRisks[j]][i][6] > 0) {
                            if (i >= initialRemainingTimestampsLength) {
                                const riskEventTimestamp = remainingTimestamps[this.selectedRisks[j]][i][constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP]
                                await this.panUntilInsideZoomWindow(riskEventTimestamp)
                                const eventIndex = this.findTimestampIndexInCurrentGraph(riskEventTimestamp, this.selectedRisks[j])
                                this.setChartAnnotationsAnchors(riskEventTimestamp, true, this.selectedRisks[j])
                                return
                            }
                            this.setChartAnnotationsAnchors(remainingTimestamps[this.selectedRisks[j]][i][constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP], true, this.selectedRisks[j])
                            return
                        }
                    }
                }
            },

            async setChartAnnotationToPreviousEvent () {
                // get a shallow copy of all of the timestamps from the currently selected risk types from the beginning of the capture
                // to the currently annotated risk event
                let remainingTimestamps = {}
                this.selectedRisks.map(risk => {
                    remainingTimestamps[risk] = this.currentGraph[risk].slice(0, this.annotationIndex)
                })

                const initialRemainingTimestampsLength = remainingTimestamps[this.selectedRisks[0]].length

                if (this.isZoomed) {
                    this.selectedRisks.map(risk => {
                        remainingTimestamps[risk].unshift(...this.graph[risk].slice(0, this.findIndexOfZoomStartInGraph(risk)))
                    })
                }

                const indexOfZoomedPointsStart = remainingTimestamps[this.selectedRisks[0]].length - initialRemainingTimestampsLength

                // Loop through those remaining indices until you find the previous risk event from the currently selected risk types.
                // Return from the function if a previous risk event is located. If none are located, do not move the annotation.
                // Note: The first selected risk type will always take precident if multiple risk types are selected

                // There are a set of remaining timestamps for each selected risk type.
                // For a DF larger than 1, these timestamps are not guaranteed to be the same at each index.
                // So for each risk type, we need to iterate through the timestamps independently and determine at each index
                // whether there is a risky event that needs to be panned to and annotated
                for (let i = remainingTimestamps[this.selectedRisks[0]].length-1; i>=0 - 1; i--) {
                    for (let j = 0; j<this.selectedRisks.length; j++) {
                        if (remainingTimestamps[this.selectedRisks[j]][i] && remainingTimestamps[this.selectedRisks[j]][i][6] > 0) {
                            if (i <= indexOfZoomedPointsStart && this.zoomStart !== this.assessmentStart) {
                                const riskEventTimestamp = remainingTimestamps[this.selectedRisks[j]][i][constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP]
                                await this.panUntilInsideZoomWindow(riskEventTimestamp)
                                const eventIndex = this.findTimestampIndexInCurrentGraph(riskEventTimestamp, this.selectedRisks[j])
                                this.setChartAnnotationsAnchors(riskEventTimestamp, true, this.selectedRisks[j])
                                return
                            }
                            this.setChartAnnotationsAnchors(remainingTimestamps[this.selectedRisks[j]][i][constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP], true, this.selectedRisks[j])
                            return
                        }
                    }
                }
            },

            async panUntilInsideZoomWindow (centerTimestamp) {
                const halfZoomRange = Math.round(this.currentDisplayedTimeRange / 2)

                let isWithinAssessmentStart = (centerTimestamp - halfZoomRange) > this.assessmentStart
                let isWithinAssessmentEnd = (centerTimestamp + halfZoomRange) < this.assessmentEnd

                this.zoomStart = isWithinAssessmentStart ? centerTimestamp - halfZoomRange : this.assessmentStart
                this.zoomEnd = isWithinAssessmentEnd ? centerTimestamp + halfZoomRange : this.assessmentEnd

                await this.requestZoomedData()
            },

            findTimestampIndexInCurrentGraph (timestamp, riskType) {
                return bSearch(
                    this.currentGraph[riskType], (point) => {
                        return point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP] < timestamp}
                    )
            },

            findIndexOfZoomStartInGraph (riskType) {
                return bSearch(
                    this.graph[riskType], (point) => {
                        return point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP] < this.zoomStart}
                    )
            },

            findIndexOfZoomEndInGraph (riskType) {
                return bSearch(
                    this.graph[riskType], (point) => {
                        return point[constants.ASSESSMENT_GRAPH_INDICES.TIMESTAMP] < this.zoomEnd}
                    )
            },

            setCurrentTime (timestamp) {
                this.currentTime = this.timestampFormatted(this.normalizeTimezoneFromChart(timestamp))
            },

            updateZoomLevel(df) {
                let cursorWindowOffset = (((df * constants.TIME_PERIODS.VIBRATION * constants.UNIX_MILLISECONDS * constants.DEFAULT_MAX_SAMPLE) + (2 * this.currentTimestamp))/2) - this.currentTimestamp

                if (df !== Math.pow(4, constants.MAX_ZOOM_FACTOR)) {
                    this.isZoomed = true
                    this.isInZoomingMode = false
                    this.zoomStart = this.currentTimestamp - cursorWindowOffset
                    this.zoomEnd = this.currentTimestamp + cursorWindowOffset

                    if (this.zoomStart < this.assessmentStart) {
                        this.zoomStart = this.assessmentStart
                    }

                    if (this.zoomEnd > this.assessmentEnd) {
                        this.zoomEnd = this.assessmentEnd
                    }

                    const range = this.zoomEnd - this.zoomStart
                    if (range <= constants.MINIMUM_ZOOM_RANGE) {
                        let rangeDifference = constants.MINIMUM_ZOOM_RANGE - range
                        this.zoomStart = this.zoomStart - (rangeDifference / 2)
                        this.zoomEnd = this.zoomEnd + (rangeDifference / 2)
                    }

                    this.requestZoomedData()
                } else {
                    this.resetZoom()
                }
            },
        },
    }
</script>

<style lang="scss">
    @import '~design';
    .sz-AssessmentChartsGroup {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        &-buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 5rem;
            height: 2.5rem;
            padding-left: 0.3rem;
        }

        &-zoomInfo {
            @extend %font-topbar-heading-unselectable;
            font-size: 0.8rem;
            margin: 0.5rem;
            padding: 0.2rem;
            color: $color-white;
            background-color: $color-lifebooster-dark-green;
            opacity: 1;
        }

        &-currentTime {
            @extend %font-topbar-heading-unselectable;
            font-size: 0.8rem;
            margin: 0.5rem 0 0.5rem 0;
            padding: 0.2rem;
            padding-left: 0.5rem;
            color: $color-white;
            background-color: $color-table-dark-background;
            opacity: 1;

            &-cursor {
                @extend %font-topbar-heading-unselectable;
                font-size: 0.8rem;
                margin: 0.5rem 0.5rem 0.5rem 0;
                padding: 0.2rem;
                color: $color-white;
                background-color: $color-table-dark-background;
                opacity: 1;
                width: 4rem;
            }
        }

        &-chart {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            position: relative;

            &-middle {
                height: 100%;
                width: 100%;
            }

            &-loader {
                position: absolute;
                width: calc(100% - 8rem);
                right: 2.6rem;
                height: calc(100% - 1.79rem);
                z-index: 10;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: lighten($color-body-bg, 10%);
                opacity: 0.7;

                &-spinner {
                    z-index: 15;
                }
            }

            &-panController {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }

            &-panControllerIcon {
                color: darken($color-white, 80%);
                cursor: default;

                &.left {
                    transform: rotate(90deg)
                }

                &.right {
                    transform: rotate(-90deg)
                }

                &.enabled {
                    color: $color-white;
                    cursor: pointer;
                }
            }
        }
    }
    .sz-Chart {
        height: 100%;
        width: 100%;
    }

    .anychart-plot-controls {
        visibility: hidden
    };

    .fade {
        &-enter-active,
        &-enter-to,
        &-leave-active {
            transition: opacity .2s
        }
        &-enter,
        &-leave-to {
            opacity: 0
        }
    }
</style>
