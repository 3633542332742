<template lang="pug">
    div(class="sz-PostureRiskTypeSelector")
        div(class="sz-PostureRiskTypeSelector-table")
            div(class="sz-PostureRiskTypeSelector-table-rows")
                div(
                    class= "sz-PostureRiskTypeSelector-table-rows-row",
                    :class="['sz-PostureRiskTypeSelector-table-rows-row-joint', {'selected': isSelected(jointRow.key)}]",
                    @click="selectRiskType(jointRow.key)",
                    v-for="jointRow in jointRows",
                    :key="jointRow.name")
                    div(class="sz-PostureRiskTypeSelector-table-rows-row-joint-name") {{ jointRow.name }}
                    div(class="sz-PostureRiskTypeSelector-table-riskCount")
                        div(class="sz-PostureRiskTypeSelector-table-rows-row-joint-count") {{ jointRow.yellowRiskCount }}
                        div(class="sz-PostureRiskTypeSelector-table-rows-row-joint-count") {{ jointRow.orangeRiskCount }}
                        div(class="sz-PostureRiskTypeSelector-table-rows-row-joint-count") {{ jointRow.redRiskCount }}
                        div(class="sz-PostureRiskTypeSelector-table-rows-row-joint-count-latestRisk") {{ $t('motionAssessment.8hr') }}
                        div(class="sz-PostureRiskTypeSelector-table-rows-row-colors")
                            div(
                                class= "sz-PostureRiskTypeSelector-table-rows-row-colors-risk motion"
                                :class="`sz-PostureRiskTypeSelector-table-rows-row-colors-risk-${jointRow.roy} motion`")
                            div(class= "sz-PostureRiskTypeSelector-table-rows-row-colors-risk yellowRiskCount")
                            div(class= "sz-PostureRiskTypeSelector-table-rows-row-colors-risk orangeRiskCount")
                            div(class= "sz-PostureRiskTypeSelector-table-rows-row-colors-risk redRiskCount")
                            div(
                                class= "sz-PostureRiskTypeSelector-table-rows-row-colors-risk latestRisk",
                                :class="`sz-PostureRiskTypeSelector-table-rows-row-colors-risk-${jointRow.normalizedRisk}`")
</template>

<script>
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'
    import { cloneDeep } from 'lodash'
    import EventBus from 'src/eventBus'

    export default {
        name: 'PostureRiskTypeSelector',

        props: {
            assessment: {
                required: true,
                type: Object,
            },

            selectedRisks: {
                required: true,
                type: Array,
            },
        },

        data () {
            return {
                cursorRisk: [],
            }
        },

        computed: {
            ...mapGetters([
                'selectedModule',
            ]),

            jointRows () {
                if (!this.loadingAssessment) {
                    let riskTypes = cloneDeep(this.assessment.RiskTypes)
                    return riskTypes.map(risk => {
                        return {
                            name: this.$t(`riskTypesShortened.${risk.name}`),
                            key: risk.name,
                            normalizedRisk: this.generateRoy(risk),
                            roy: this.cursorRisk[0], // change when event risk is provided in graph data for vibration
                            yellowRiskCount: risk.yellowRiskCount || 0,
                            orangeRiskCount: risk.orangeRiskCount || 0,
                            redRiskCount: risk.redRiskCount || 0,
                        }
                    })
                }
                return null
            },
        },

        mounted () {
            EventBus.$on('UPDATE_RISK_SELECTOR', this.updateRisk)
        },

        beforeDestroy () {
            EventBus.$emit('CLEAR_RISK_TYPES')
            EventBus.$off('UPDATE_RISK_SELECTOR')
        },

        methods: {
            selectRiskType (riskType) {
                this.$emit('updateSelectedRiskTypes', riskType)
            },

            updateRisk (risk) {
                this.cursorRisk = risk
            },

            generateRoy (assessmentRisk) {

                if (assessmentRisk === undefined) return undefined

                if (assessmentRisk.R || assessmentRisk.r) return constants.RISK_SCORES.RED
                if (assessmentRisk.o) return constants.RISK_SCORES.ORANGE
                if (assessmentRisk.y) return constants.RISK_SCORES.YELLOW
                return constants.RISK_SCORES.GREEN
            },

            isSelected(riskType) {
                return !!this.selectedRisks.find(r => r === riskType)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";
    .sz-PostureRiskTypeSelector {
        @include assessment-risk-panel;
    }
</style>
