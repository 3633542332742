<template lang="pug">
    div(class="sz-ZoomButtons")
        div(
            class="sz-ZoomButtons-showAll",
            :class="{'disabled': !canZoomIn}",
            @click.stop="showAllAngles") {{ "Show All Angles"}}
        div(
            class="sz-ZoomButtons-buttons",
            :class="{'disabled': !canZoomOut}",
            @click.stop="zoomOut")
            SvgIcon(
                icon="zoomOut",
                width="1.5rem",
                height="1.5rem",
                class="sz-ZoomButtons-buttons-zoomIcon")
        div(
            class="sz-ZoomButtons-buttons",
            :class="{'disabled': !canZoomIn}",
            @click.stop="zoomIn")
            SvgIcon(
                icon="zoomIn",
                width="1.5rem",
                height="1.5rem",
                class="sz-ZoomButtons-buttons-zoomIcon")
        div(
            class="sz-ZoomButtons-buttons",
            :class="{'disabled': !canZoomOut}",
            @click.stop="resetZoom")
            SvgIcon(
                icon="resetZoom",
                width="1.5rem",
                height="1.5rem",
                class="sz-ZoomButtons-buttons-zoomIcon")
</template>

<script>
    import { cloneDeep } from 'lodash'
    import EventBus from 'src/eventBus'
    import constants from 'helpers/constants'

    import SvgIcon from 'components/Shared/SvgIcon'
    import Vue from 'vue'

    export default {
        name: "ZoomButtons",

        components: {
            SvgIcon,
        },

        data () {
            return {
                zoom: 5,
            }
        },

        computed: {
            currentZoom() {
                return Math.pow(4, this.zoom)
            },

            maxZoomOut() {
                return Math.pow(4, constants.MAX_ZOOM_FACTOR)
            },

            maxZoomIn() {
                return Math.pow(4, constants.MIN_ZOOM_FACTOR)
            },

            canZoomOut() {
                return !!(this.currentZoom < this.maxZoomOut)
            },

            canZoomIn() {
                return !!(this.currentZoom > this.maxZoomIn)
            },
        },

        methods: {
            zoomOut() {
                if (this.canZoomOut) {
                    this.zoom = this.zoom + 1
                    this.$emit('updateZoom', Math.pow(4, this.zoom))
                }
            },

            zoomIn() {
                if (this.canZoomIn) {
                    this.zoom = this.zoom - 1
                    this.$emit('updateZoom', Math.pow(4, this.zoom))
                }
            },

            resetZoom() {
                if (this.canZoomOut) {
                    this.zoom = constants.MAX_ZOOM_FACTOR
                    this.$emit('updateZoom', Math.pow(4, this.zoom))
                }
            },

            showAllAngles() {
                if (this.canZoomIn) {
                    this.zoom = constants.MIN_ZOOM_FACTOR
                    this.$emit('updateZoom', Math.pow(4, this.zoom))
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-ZoomButtons {
        display: flex;
        flex-direction: row;
        position: relative;
        margin: 0.5rem;
        align-items: center;

        &-buttons {
            @extend %font-topbar-heading-unselectable;
            background-color: $color-lifebooster-dark-green;
            font-size: 0.8rem;
            margin: 0.25rem;
            padding-top: .35rem;
            cursor: pointer;

            &:hover {
                background-color: $color-lifebooster-light-green;
            }

            &.disabled {
                color: $color-unfocused-grey;
                cursor: default;
                opacity: 0.8;
            }
        }

        &-showAll {
            @extend %font-topbar-heading-unselectable;
            background-color: $color-lifebooster-dark-green;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 0.8rem;
            margin: 0.25rem;
            padding: 0.2rem;
            cursor: pointer;

            &:hover {
                background-color: $color-lifebooster-light-green;
            }

            &.disabled {
                color: $color-unfocused-grey;
                cursor: default;
                opacity: 0.8;
            }
        }
    }
</style>
