<template lang="pug">
    div(class="sz-PostureAssessmentInfo")
      table()
          div(class="sz-PostureAssessmentInfo-headers")
              div(class="sz-PostureAssessmentInfo-header-empty") {{ `${this.$t(`motionAssessment.assessmentInfo.riskTypes`)}` }}
          div(class="sz-PostureAssessmentInfo-headers")
              div(class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.riskTypes`)}` }}
          div(
            v-for="row of currentlySelectedRisks",
            class="sz-PostureAssessmentInfo-row")
                div(class="sz-PostureAssessmentInfo-cell") {{ row }}
      table()
          div(class="sz-PostureAssessmentInfo-headers")
              div(class="sz-PostureAssessmentInfo-header-empty") {{ `${this.$t(`motionAssessment.assessmentInfo.currentAngle`)}` }}
          div(class="sz-PostureAssessmentInfo-headers")
              div(class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.currentAngle`)}` }}
          div(
            v-for="angle of currentAngles",
            class="sz-PostureAssessmentInfo-row")
                div(
                    class="sz-PostureAssessmentInfo-cell",
                    v-bind:class= "isNaN(angle) ? 'sz-PostureAssessmentInfo-cell-empty' : 'sz-PostureAssessmentInfo-cell'") {{ angle }}
      table()
          div(class="sz-PostureAssessmentInfo-headers")
              div(class="sz-PostureAssessmentInfo-header-empty") {{ `${this.$t(`motionAssessment.assessmentInfo.currentMVCRatio`)}` }}
          div(class="sz-PostureAssessmentInfo-headers")
              div(class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.currentMVCRatio`)}` }}
          div(
            v-for="ratio of currentRatios",
            class="sz-PostureAssessmentInfo-row")
                div(
                    class="sz-PostureAssessmentInfo-cell",
                    v-bind:class= "isNaN(ratio) ? 'sz-PostureAssessmentInfo-cell-empty' : 'sz-PostureAssessmentInfo-cell'") {{ ratio }}
      table()
          div(class="sz-PostureAssessmentInfo-headers")
              <th colspan="4"> {{ `${this.$t(`motionAssessment.assessmentInfo.windowDuration`)}` }} </th>
          div(class="sz-PostureAssessmentInfo-headers")
              div(v-tooltip="P4Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P4`)}` }}
              div(v-tooltip="P6Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P6`)}` }}
              div(v-tooltip="P8Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P8`)}` }}
              div(v-tooltip="P9Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P9`)}` }}
          div(
            v-for="row of windowDurations",
            class="sz-PostureAssessmentInfo-row")
                div(class="sz-PostureAssessmentInfo-cell") {{ row.P4 }}
                div(class="sz-PostureAssessmentInfo-cell") {{ row.P6 }}
                div(class="sz-PostureAssessmentInfo-cell") {{ row.P8 }}
                div(class="sz-PostureAssessmentInfo-cell") {{ row.P9 }}
      table()
          div(class="sz-PostureAssessmentInfo-headers")
              <th colspan="4"> {{ `${this.$t(`motionAssessment.assessmentInfo.windowContribution`)}` }} </th>
          div(class="sz-PostureAssessmentInfo-headers")
              div(v-tooltip="P4Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P4`)}` }}
              div(v-tooltip="P6Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P6`)}` }}
              div(v-tooltip="P8Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P8`)}` }}
              div(v-tooltip="P9Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P9`)}` }}
          div(
            v-for="row of windowContributions",
            class="sz-PostureAssessmentInfo-row")
                div(class="sz-PostureAssessmentInfo-cell") {{ row.P4 }}
                div(class="sz-PostureAssessmentInfo-cell") {{ row.P6 }}
                div(class="sz-PostureAssessmentInfo-cell") {{ row.P8 }}
                div(class="sz-PostureAssessmentInfo-cell") {{ row.P9 }}
      table()
          div(class="sz-PostureAssessmentInfo-headers")
              <th colspan="4"> {{ `${this.$t(`motionAssessment.assessmentInfo.totalDuration`)}` }} </th>
          div(class="sz-PostureAssessmentInfo-headers")
              div(v-tooltip="P4Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P4`)}` }}
              div(v-tooltip="P6Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P6`)}` }}
              div(v-tooltip="P8Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P8`)}` }}
              div(v-tooltip="P9Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P9`)}` }}
          div(
            v-for="row of riskTypePayloads",
            class="sz-PostureAssessmentInfo-row")
                div(class="sz-PostureAssessmentInfo-cell") {{ row.totalDurP4 }}
                div(class="sz-PostureAssessmentInfo-cell") {{ row.totalDurP6 }}
                div(class="sz-PostureAssessmentInfo-cell") {{ row.totalDurP8 }}
                div(class="sz-PostureAssessmentInfo-cell") {{ row.totalDurP9 }}
      table()
          div(class="sz-PostureAssessmentInfo-headers")
              <th colspan="4"> {{ `${this.$t(`motionAssessment.assessmentInfo.totalContribution`)}` }} </th>
          div(class="sz-PostureAssessmentInfo-headers")
              div(v-tooltip="P4Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P4`)}` }}
              div(v-tooltip="P6Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P6`)}` }}
              div(v-tooltip="P8Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P8`)}` }}
              div(v-tooltip="P9Tooltip",
                  class="sz-PostureAssessmentInfo-header") {{ `${this.$t(`motionAssessment.assessmentInfo.P9`)}` }}
          div(
            v-for="row of riskTypePayloads",
            class="sz-PostureAssessmentInfo-row")
                div(class="sz-PostureAssessmentInfo-cell") {{ row.totalContribP4 }}
                div(class="sz-PostureAssessmentInfo-cell") {{ row.totalContribP6 }}
                div(class="sz-PostureAssessmentInfo-cell") {{ row.totalContribP8 }}
                div(class="sz-PostureAssessmentInfo-cell") {{ row.totalContribP9 }}
</template>

<script>
    import { tooltip } from 'directives/tooltip'
    import EventBus from 'src/eventBus'
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'

    export default {
        name: "PostureAssessmentInfo",

        directives: {
            tooltip,
        },

        components: {
        },

        props: {
            selectedRisks: {
                type: Array,
                required: true,
            },

            selectedAssessment: {
                type: Object,
                required: true,
            },
        },

        data () {
            return {
                currentAngles: null,
                currentRatios: null,
                windowDurations: null,
                windowContributions: null,
            }
        },

        computed: {
            ...mapGetters([
                'selectedModule',
            ]),

            currentlySelectedRisks() {
                return this.selectedRisks.map(risk => {
                    return this.$t(`riskTypes.${risk}`)
                })
            },

            riskTypePayloads() {
                return this.selectedRisks.map(risk => {
                    let payload = this.selectedAssessment.RiskTypes.find(r => r.name === risk).payload
                    return {
                        totalDurP4: this.formatTotalDuration(payload.P4.duration),
                        totalDurP6: this.formatTotalDuration(payload.P6.duration),
                        totalDurP8: this.formatTotalDuration(payload.P8.duration),
                        totalDurP9: this.formatTotalDuration(payload.P9.duration),
                        totalContribP4: this.formatContribution(payload.P4.contribution),
                        totalContribP6: this.formatContribution(payload.P6.contribution),
                        totalContribP8: this.formatContribution(payload.P8.contribution),
                        totalContribP9: this.formatContribution(payload.P9.contribution),
                    }
                })
            },

            P4Tooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'left',
                            padding: 'extra-padding',
                        },
                        title: this.$t(`motionAssessment.assessmentInfo.headers.P4`),
                    },

                    body: {
                        placement: 'bottom-left',
                        offset: '0, -5',
                    },
                    allowHover: true,
                }
            },

            P6Tooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'left',
                            padding: 'extra-padding',
                        },
                        title: this.$t(`motionAssessment.assessmentInfo.headers.P6`),
                    },

                    body: {
                        placement: 'bottom-left',
                        offset: '0, -5',
                    },
                    allowHover: true,
                }
            },

            P8Tooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'left',
                            padding: 'extra-padding',
                        },
                        title: this.$t(`motionAssessment.assessmentInfo.headers.P8`),
                    },

                    body: {
                        placement: 'bottom-left',
                        offset: '0, -5',
                    },
                    allowHover: true,
                }
            },

            P9Tooltip () {
                return {
                    tooltipBodySettings: {
                        view: {
                            color: 'dark',
                            textAlign: 'left',
                            padding: 'extra-padding',
                        },
                        title: this.$t(`motionAssessment.assessmentInfo.headers.P9`),
                    },

                    body: {
                        placement: 'bottom-left',
                        offset: '0, -5',
                    },
                    allowHover: true,
                }
            },
        },

        mounted() {
            EventBus.$on('UPDATE_CURRENT_ANGLES', (angles) => {
                this.currentAngles = angles
            })

            EventBus.$on('UPDATE_CURRENT_MVC_RATIOS', (ratios) => {
                this.currentRatios = this.formatMVCRatios(ratios)
            })

            EventBus.$on('UPDATE_WINDOW_DURATIONS', (durations) => {
                this.windowDurations = this.formatWindowDurations(durations)
            })

            EventBus.$on('UPDATE_WINDOW_CONTRIBUTIONS', (contributions) => {
                this.windowContributions = this.formatWindowContributions(contributions)
            })
        },

        beforeDestroy() {
            EventBus.$off('UPDATE_CURRENT_ANGLES')

            EventBus.$off('UPDATE_CURRENT_MVC_RATIOS')

            EventBus.$off('UPDATE_WINDOW_DURATIONS')

            EventBus.$off('UPDATE_WINDOW_CONTRIBUTIONS')
        },

        methods: {
            formatTotalDuration(duration) {
                return duration.replace(':', 'h').replace(':', 'm') + 's'
            },

            formatWindowDuration(duration) {
                return new Date(duration * 1000).toISOString().substr(11, 8).replace(':', 'h').replace(':', 'm') + 's'
            },

            formatContribution(contribution) {
                return Math.round(contribution)
            },

            formatMVCRatios(ratios) {
                return ratios.map(ratio => {
                    return !isNaN(ratio[0]) ? ratio[0].toFixed(2) : 'N/A'
                })
            },

            formatWindowDurations(durations) {
                return durations.map(duration => {
                    if (!isNaN(duration[0])) {
                        return {
                            P4: this.formatWindowDuration(duration[0]),
                            P6: this.formatWindowDuration(duration[1]),
                            P8: this.formatWindowDuration(duration[2]),
                            P9: this.formatWindowDuration(duration[3]),
                        }
                    } else {
                        return {
                            P4: "-",
                            P6: "-",
                            P8: "-",
                            P9: "-",
                        }
                    }
                })
            },

            formatWindowContributions(contributions) {
                return contributions.map(contrib => {
                    if (!isNaN(contrib[0])) {
                        return {
                            P4: this.formatContribution(contrib[0]),
                            P6: this.formatContribution(contrib[1]),
                            P8: this.formatContribution(contrib[2]),
                            P9: this.formatContribution(contrib[3]),
                        }
                    } else {
                        return {
                            P4: "-",
                            P6: "-",
                            P8: "-",
                            P9: "-",
                        }
                    }
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "~design";

    th {
        @extend %font-heading;
        padding: 0.25rem;
        font-size: 0.75rem;
        text-align: center;
        vertical-align: middle;
        border: 0.3rem solid $color-table-dark-background;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    table {
        table-layout: auto;
        border-collapse: collapse;
    }

    .sz-PostureAssessmentInfo {
        display: flex;
        flex-direction: row;
        padding: 0.25rem 0.75rem 0.75rem 0.75rem;
        max-width: 50rem;

        &-headers {
            background-color: $color-table-dark-background;
            display: table-header-group;
            margin-bottom: 0.3rem;
        }

        &-header {
            @extend %font-heading;
            padding: 0.25rem;
            font-size: 0.75rem;
            display: table-cell;
            text-align: center;
            vertical-align: middle;
            border: 0.3rem solid $color-table-dark-background;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

                &-empty {
                    @extend %font-heading;
                    padding: 0.25rem;
                    font-size: 0.75rem;
                    display: table-cell;
                    text-align: center;
                    vertical-align: middle;
                    border: 0.3rem solid $color-table-dark-background;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    color: $color-table-dark-background;
                }
        }

        &-row {
            display: table-row-group;
            padding: 0.5rem;
            background-color: $color-lifebooster-dark-green;
        }

        &-cell {
            border: 0.3rem solid $color-table-dark-background;
            padding: 0.5rem;
            display: table-cell;
            font-size: 0.8rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &-empty {
                color: $color-lifebooster-dark-green;
            }
        }

        &-label {
            @extend %font-heading;
            font-size: 0.75rem;
            display: table-cell;
            border: 0.3rem solid $color-table-dark-background;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
</style>
